// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

// export const VERSION = process.env.VERSION;
// export const DEBUG_INFO_ENABLED: boolean = !!process.env.DEBUG_INFO_ENABLED;
// export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;

export const VERSION = 1212;
export const DEBUG_INFO_ENABLED: boolean = false;
export const SERVER_API_URL='';
export const BUILD_TIMESTAMP = '1707721288106';

export const TIMEZONE_DEFAULT_DATE_FORMAT = 'D MMM, YYYY HH:mm:ss A';
export const TIMEZONE_DEFAULT_DATE_FORMAT_SHORT = 'D MMM, YYYY HH:mm A';
export const DEFAULT_DATE_FORMAT='d MMM, yyyy';
// export const REDIRECT_URL=process.env.REDIRECT_URL;
export const REDIRECT_URL='development';

export const MY_COMPLETE_DATE_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
  };
export const LMS_ADMIN = 'krishna.garg@gspan.com';
export const salt = 'urxs8ylnci8jxwqj';
export const encIV = 'poijh7654sdcxvbn';