import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { enviornmentAperture } from 'environments/environment';
let httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class MasterDatabaseService {
    constructor(private http: HttpClient) { }

    getAllPractice(): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + 'api/v1/practices/getAllPractices');
    }

    createPractice(data): Observable<any> {
        return this.http.post(enviornmentAperture.apiUrl + 'api/v1/practices/createPractice', data, httpOptions).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    updatePractice(data): Observable<any> {
        return this.http.put(enviornmentAperture.apiUrl + 'api/v1/practices/updatePractice/' + data.id, data, httpOptions).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    getAllOffices(): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + 'api/v1/locations/initAddLocation');
    }

    createOffice(data): Observable<any> {
        return this.http.post(enviornmentAperture.apiUrl + 'api/v1/locations/createLocation', data, httpOptions).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    updateOffice(data): Observable<any> {
        return this.http.patch(enviornmentAperture.apiUrl + 'api/v1/locations/updateLocation', data, httpOptions).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    getExistingLocation(): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + 'api/v1/locations/existingOfficeLocations');
    }

    getDesignations(): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + 'api/v1/designations');
    }

    getDepartments(): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + 'api/v1/departments');
    }

    createDesignations(data): Observable<any> {
        return this.http.post(enviornmentAperture.apiUrl + 'api/v1/designations', data, httpOptions).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    designationDelete(id): Observable<any> {
        return this.http.delete(enviornmentAperture.apiUrl + 'api/v1/designations/' + id, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    designationUpdate(data, id): Observable<HttpResponse<any>> {
        return this.http.put(enviornmentAperture.apiUrl + 'api/v1/designations/' + id, data, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    exportPractices(): Observable<any> {
        let construct_uri = '';
        //  construct_uri = 'resourceCode=' + managerCode;
        return this.http.get(enviornmentAperture.apiUrl + `api/v1/practices/export`, { responseType: 'blob' });
      }
    
    exportSkills(): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + `api/v1/skill`, { responseType: 'blob' });
    }

    exportPracticeMap(): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + `api/v1/resources/exportPracticeHierarchyDetails`, { responseType: 'blob' });
    }

    getAllCompany(): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + `api/v1/locations/GetAllCompanies`);
    }
    addCompany(data): Observable<any> {
        return this.http.post(enviornmentAperture.apiUrl + 'api/v1/locations/createCompany', data, httpOptions).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }
    companyUpdate(data): Observable<HttpResponse<any>> {
        return this.http.put(enviornmentAperture.apiUrl + 'api/v1/locations/updateCompany/'+data.companyId,  data, {}).pipe(map((response: HttpResponse<any>) => {
          return response;
        }));
      }
      createDepartment(data): Observable<any>{
        return this.http.post(enviornmentAperture.apiUrl + 'api/v1/departments/createDepartment', data, httpOptions).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
      }
    getAllDepartment(): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + `api/v1/departments/getDepartments`);
    }
    departmentUpdate(data): Observable<HttpResponse<any>> {
        return this.http.put(enviornmentAperture.apiUrl + 'api/v1/departments/updateDepartment', data, {}).pipe(map((response: HttpResponse<any>) => {
          return response;
        }));
      }
  
}
