<div class="view-account view-mode">
    <div class="row">
        <div class="col-sm-6 topContent">
            <span style="color: #0EB3F7">Account:</span>
            <strong> {{customerName}} ( {{accountCode}} ) </strong>
        </div>
        <div class="col-sm-3 topContent text-right">
        </div>
        <div class="col-sm-1">
        </div>
        <div class="col-sm-2">
            <div ngbDropdown class="d-inline-block float-right">
                <button mat-raised-button color="primary" id="dropdownBasic1" ngbDropdownToggle>Quick Links</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-quick-link">
                    <button class="dropdown-item" (click)="navigateToProjectPage()"
                    *jhiHasAnyAuthority="'PROJECTS_CREATE'">Add Project</button>
                    <button [hidden]="!ifSow" class="dropdown-item" skipLocationChange routerLink="/statement-of-work/add-sow/{clone:false}"
                        *jhiHasAnyAuthority="'SOW_CREATE'">Add SOW</button>
                    <button *ngIf="ifSow" class="dropdown-item" jhiHasAnyAuthorityConditional="SOW_EXTEND"
                        skipLocationChange routerLink="/statement-of-work/extend-sow">Extend SOW</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <mat-tab-group (selectedTabChange)="onTabChange($event)">
                <mat-tab label="Details">
                    <mat-card class="example-card">
                        <mat-card-header>
                            <div style="margin-left: auto;order: 2;">
                                <button *jhiHasAnyAuthority="'ACCOUNTS_UPDATE'" [hidden]="!isViewMode" mat-button
                                    (click)="enableEditMode();" color="primary">
                                    <span class="fa fa-pencil-square-o"></span> Edit</button>
                                <button *jhiHasAnyAuthority="'ACCOUNTS_UPDATE'" [hidden]="isViewMode" mat-button
                                    (click)="updateAccountDetails();"
                                    [disabled]="(msaForm.invalid && !checked && !checkedsow)||(customerEmailId1.errors?.pattern || customerEmailId1.errors?.required)
                                    || (customerContactNumber1.errors?.pattern || customerContactNumber1.errors?.required || customerContactNumber1.errors?.minlength)
                                    || (customerReportingManager1.errors?.required && customerReportingManager1.touched)
                                    || (customerName1.errors?.required && customerName1.touched)
									|| (gspanncompanyEntity.errors?.required)
									|| (selectedCountry.errors?.required && selectedCountry.touched)
                                    || (selectedAccountManagerName1.errors?.required && selectedAccountManagerName1.touched) 
                                    || (selectedAccountARManager1.errors?.required && selectedAccountARManager1.touched) || isClicked || isFileuploading"
                                    color="primary">
                                    <span class="fa fa-save"></span> Save</button>
                                <button mat-button (click)="getAccount();" [hidden]="isViewMode" color="primary">
                                    <span class="fa fa-refresh"></span> Reset</button>
                                <button mat-button (click)="cancelEdit();" [hidden]="isViewMode" color="primary">
                                    <span class="fa fa-close"></span> Cancel</button>
                                <button *jhiHasAnyAuthority="'ACCOUNTS_DELETE'" mat-button (click)="remove();"
                                    color="primary">
                                    <span class="fa fa-trash"></span> Remove</button>
                            </div>
                        </mat-card-header>
                        <mat-card-content>
                            <div *ngIf="mutualerror">
                                <ngb-alert class="success-message" type="success" [dismissible]="true" (close)="mutualerror=false;">
                                  <strong>{{errormessage}}</strong>
                                </ngb-alert>
                            </div>

                            <div class="row">
                                <div class="col-sm-4">
									<p>
										<mat-form-field>
											<mat-select placeholder="GSPANN Entity" [(ngModel)]="gspannEntity" (selectionChange)="entityChanged($event)" [formControl]="gspanncompanyEntity" required [disabled]="isViewMode">
												<mat-option *ngFor="let companyEntity of gspannEntityList" [value]="companyEntity.value">{{companyEntity.value}}</mat-option>
											</mat-select>
											<mat-error
                                                *ngIf="gspanncompanyEntity.errors?.required">
                                                GSPANN Entity is required
                                            </mat-error>
										</mat-form-field>
									</p>
                                    <p>
                                        <mat-form-field>
                                            <input matInput placeholder="Customer Group" [(ngModel)]="customerGroup"
                                                [disabled]="true">
                                        </mat-form-field>
                                    </p>
                                    <p>
                                        <mat-form-field>
                                            <input matInput placeholder="Customer Entity" [(ngModel)]="customerEntity"
                                                name="customerEntity1" #customerEntity1="ngModel" required
                                                [disabled]="isViewMode">
                                            <mat-error
                                                *ngIf="customerEntity1.errors?.required && customerEntity1.touched">
                                                Customer entity is required
                                            </mat-error>
                                        </mat-form-field>
                                    </p>
                                    <p>
                                        <mat-form-field class="example-full-width search-full-width">
                                            <span matPrefix *ngIf="!isViewMode">
                                                <mat-icon matSuffix>search</mat-icon>&nbsp;&nbsp;
                                            </span>
                                            <input matInput
                                                [placeholder]="isViewMode ? 'Manager':'Search Manager (eg. type resource id, resource name, email-id, technology etc.)'"
                                                aria-label="State" [matAutocomplete]="auto1"
                                                (input)="searchResource($event.target.value)"
                                                [(ngModel)]="selectedAccountManagerName" [disabled]="isViewMode"
                                                name="selectedAccountManagerName1"
                                                #selectedAccountManagerName1="ngModel" required>
                                            <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"
                                                (optionSelected)="onResourceSelection($event)">
                                                <mat-option matTooltip="{{resource.designation}}" class="resourceList" *ngFor="let resource of resourcesList"
                                                    [value]="resource">
                                                    <div *ngIf="resource.showResource" class="row">
                                                        <div class="col-sm-1">
                                                            <img class="example-option-img"
                                                                *ngIf="resource.image;else defaultImage1" aria-hidden
                                                                src="{{resource.image}}" height="25">
                                                            <ng-template #defaultImage1>
                                                                <img class="example-option-img" aria-hidden
                                                                    src="../../assets/User_Profile_Pic.png" height="25">
                                                            </ng-template>
                                                        </div>
                                                        <div class="col-sm-2">
                                                            <span>{{resource.code}}</span>
                                                        </div>
                                                        <div class="col-sm-5 res-name">
                                                            <span>{{resource.name}}</span>
                                                        </div>
                                                       
                                                    </div>
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error
                                                *ngIf="selectedAccountManagerName1.errors?.required && selectedAccountManagerName1.touched">
                                                Manager name is required
                                            </mat-error>
                                        </mat-form-field>
                                    </p>
                                    <p>
                                        <label class="time-tracking-label">Time Tracking System Available</label>
                                        <mat-radio-group [hidden]="isViewMode" [(ngModel)]="isTimeTrackingAvailable">
                                            <mat-radio-button value="selected">Yes</mat-radio-button>
                                            <mat-radio-button value="notselected">NO</mat-radio-button>
                                        </mat-radio-group>
                                        <label [hidden]="!isViewMode">
                                            <b>{{ isTimeTrackingAvailable === 'selected' ? 'Yes' : 'No' }} </b>
                                        </label>
                                    </p>
                                    <div class="col-md-12" id="upload_document" *ngIf="accountDocumentsView">
                                        <mat-card class="sow-documents">
                                            <mat-card-content>
                                                <mat-accordion class="account-documents" *ngIf="isViewMode">
                                                    <mat-expansion-panel (opened)="panelOpenState = true"
                                                        (closed)="panelOpenState = false">
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title class="title" >
                                                                NDA/MSA Documents:
                                                            </mat-panel-title>
                                                        </mat-expansion-panel-header>
                                                        <div class="upload_document_container" >
                                                            <section class="scroll">
                                                            <ol>
                                                                <li class="list-group-item d-flex justify-content-between align-items-center" 
                                                                    *ngFor="let documents of accountDocuments">
                                                                    <a (click)="downLoadDocument(documents)">{{documents.filename}}</a>
                                                                </li>
                                                            </ol>
                                                        </section>
                                                        </div>
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                    <div class="col-md-12" *ngIf="!isViewMode">
                                        <div id="upload_button">
                                            <label>
                                                <input type="file" #fileInput (click)="fileInput.value = null"
                                                     accept=".png,.jpeg,.jpg,.pdf,.xlsx,.xls,.zip,.docx,.doc"
                                                    (change)="changeListener($event)" multiple />
                                                <span class="btn btn-sm btn-outline-primary label-attach"
                                                    mat-raised-button>
                                                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                    Attach NDA/MSA documents
                                                </span>
                                            </label>
                                        </div>
                                        <mat-error *ngIf="resumeSizeFlag">File size is exeeded to 10MB. Please choose a
                                            file below 10MB</mat-error>
                                        <mat-error *ngIf="resumeLengthFlag">File name is too long. File name should be
                                            less
                                            than 100 characters</mat-error>
                                            <mat-error *ngIf="incorrectFileFormat">Please select file of .png,.jpeg,.jpg,.pdf,.xlsx,.xls,.zip,.docx type
                                            </mat-error>
                                        <mat-card class="sow-documents">
                                            <mat-card-content>
                                                <div class="col-md-12" id="upload_document">
                                                    <ul class="list-group list-group-vertical">
                                                        <li class="list-group-item d-flex justify-content-between align-items-center"
                                                            *ngFor="let name of resumeNameList; let i = index;">
                                                            {{name}}
                                                            <span class="badge badge-danger badge-pill "><i
                                                                    class="fas fa-times-circle"
                                                                    style="color:#ffffff; size:40px;"
                                                                    (click)="removeResume(i)"></i>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div></div>
                                            </mat-card-content>
                                            <div></div>
                                        </mat-card>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="row">
                                        <div class="col">
                                            <mat-form-field>
                                                <input matInput placeholder="Customer Name" name="customerName1"
                                                    #customerName1="ngModel" [(ngModel)]="customerName"
                                                    [disabled]="isViewMode" required>
                                                <mat-error
                                                    *ngIf="customerName1.errors?.required && customerName1.touched">
                                                    Customer name is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <p>
                                        <mat-form-field>
                                            <input matInput placeholder="Customer Contact Person"
                                                name="customerReportingManager1" #customerReportingManager1="ngModel"
                                                [(ngModel)]="customerReportingManager" [disabled]="isViewMode" required>
                                            <mat-error
                                                *ngIf="customerReportingManager1.errors?.required && customerReportingManager1.touched">
                                                Reporting manager is required
                                            </mat-error>
                                        </mat-form-field>
                                    </p>
                                    <p>
                                        <mat-form-field>
                                            <input matInput [disabled]="isViewMode" name="customerEmailId1"
                                                placeholder="Contact Person's Email" #customerEmailId1="ngModel"
                                                [(ngModel)]="customerEmailId"
                                                pattern="^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$" required>
                                            <mat-error
                                                *ngIf="customerEmailId1.errors?.required && customerEmailId1.touched">
                                                This
                                                is required field</mat-error>
                                            <mat-error
                                                *ngIf="customerEmailId1.errors?.pattern && customerEmailId1.touched">
                                                Please
                                                check the email format</mat-error>
                                        </mat-form-field>
                                    </p>
                                    <p>
                                        <mat-form-field>
                                            <input matInput id="phone" style="height:35px;top: 7px;"
                                                placeholder="Contact Person's Mobile No." name="customerContactNumber1"
                                                #customerContactNumber1="ngModel" [disabled]="isViewMode"
                                                [(ngModel)]="customerContactNumber" (keypress)="validateNumber($event)"
                                                [minlength]="10" [maxlength]="10" pattern="([0-9]+)" required>
                                            <mat-error
                                                *ngIf="customerContactNumber1.errors?.required && customerContactNumber1.touched">
                                                This
                                                is required field</mat-error>
                                            <mat-error
                                                *ngIf="customerContactNumber1.errors?.pattern && customerContactNumber1.touched">
                                                Mobile number format is wrong
                                            </mat-error>
                                            <mat-error
                                                *ngIf="customerContactNumber1.errors?.minlength && customerContactNumber1.touched">
                                                The length should be 10.</mat-error>
                                        </mat-form-field>
                                    </p>
                                    <p>
                                        <mat-form-field>
                                            <mat-select [(ngModel)]="selectedLocation" [disabled]="isViewMode"  required
                                                placeholder="Country" [formControl]="selectedCountry">
                                                <input class="myInput" #myInput matInput focused="'true'" type="text"
                                                    (keyup)="searchCountry($event.target.value)" autocomplete="off"
                                                    placeholder="search">
                                                <mat-divider></mat-divider>
                                                <mat-option *ngFor="let list of locationList" [value]="list.key">
                                                    {{list.value}}
                                                </mat-option>
                                            </mat-select>
											<mat-error
                                                *ngIf="selectedCountry.errors?.required && selectedCountry.touched">
                                                This
                                                is a required field.</mat-error>
                                        </mat-form-field>
                                    </p>
                                    <!-- <div class="col-sm-1">
                                    </div> -->
                                </div>
                                <div class="col-sm-4">
                                    <form [formGroup]="msaForm">
                                        <div class="col-sm-12 msa-check">
                                            <mat-checkbox class="example-margin text-color" [(ngModel)]="checked"
                                                [ngModelOptions]="{standalone:true}" [disabled]="isViewMode"
                                                (change)="checkstate($event)">Mutual Termination
                                            </mat-checkbox> &nbsp;&nbsp;
                                            <mat-checkbox class="example-margin text-color" [(ngModel)]="checkedsow"
                                                [ngModelOptions]="{standalone:true}"[disabled]="isViewMode"
                                                (change)="checkstatesow($event)">Last Sow
                                            </mat-checkbox>
                                        </div>
                                        <div class="col-sm-12 msa-fields">
                                            <mat-form-field>
                                                <input matInput [matDatepicker]="picker2" placeholder="MSA Start Date"
                                                    (dateChange)="startDateChange($event)" [max]="minStartDate"
                                                    formControlName="msaStartDate" required readonly="true"
                                                    [disabled]="isViewMode" [(ngModel)]="msaStartDate">
                                                <mat-datepicker-toggle matSuffix [for]="picker2">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker2>
                                                </mat-datepicker>
                                                <mat-error *ngIf="msaForm.get('msaStartDate').hasError('required')">This
                                                    is a required field</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-12 msa-fields">
                                            <mat-form-field>
                                                <input matInput [matDatepicker]="picker3" placeholder="MSA End Date" class="MSA-end-date"
                                                    formControlName="msaEndDate" [min]="minEndDate" [max]="maxEndsowdate"
                                                    (dateChange)="startDateChange($event)" [(ngModel)]="msaEndDate"
                                                    readonly="true" [disabled]="isViewMode">
                                                <mat-datepicker-toggle matSuffix [for]="picker3">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker3>
                                                </mat-datepicker>
                                                <mat-error *ngIf="msaForm.get('msaEndDate').hasError('required')">This
                                                    is a required field</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <p>
                                            <mat-form-field class="example-full-width search-full-width">
                                                <span matPrefix *ngIf="!isViewMode">
                                                    <mat-icon matSuffix>search</mat-icon>&nbsp;&nbsp;
                                                </span>
                                                <input matInput class="arowner"
                                                    [placeholder]="isViewMode ? 'AR Owner':'Search AR Owner (eg. type resource id, resource name, email-id.)'"
                                                    aria-label="State" [matAutocomplete]="auto2"
                                                    (keyup)="searchResourceARManager($event.target.value)"
                                                    [(ngModel)]="selectedARManager" [disabled]="isViewMode"
                                                    name="selectedAccountARManager1" [ngModelOptions]="{standalone: true}"
                                                    #selectedAccountARManager1="ngModel" required>
                                                <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete"
                                                    (optionSelected)="onResourceSelectionARManager($event)">
                                                    <mat-option matTooltip="{{resource.designation}}" class="resourceList" *ngFor="let resource of resourcesList"
                                                        [value]="resource">
                                                        <div *ngIf="resource.showResource" class="row">
                                                            <div class="col-sm-1">
                                                                <img class="example-option-img"
                                                                    *ngIf="resource.image;else defaultImage1" aria-hidden
                                                                    src="{{resource.image}}" height="25">
                                                                <ng-template #defaultImage1>
                                                                    <img class="example-option-img" aria-hidden
                                                                        src="../../assets/User_Profile_Pic.png" height="25">
                                                                </ng-template>
                                                            </div>
                                                            <div class="col-sm-2">
                                                                <span>{{resource.code}}</span>
                                                            </div>
                                                            <div class="col-sm-5 res-name">
                                                                <span>{{resource.name}}</span>
                                                            </div>
                                                            
                                                        </div>
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-error
                                                    *ngIf="selectedAccountARManager1.errors?.required && selectedAccountARManager1.touched">
                                                    Manager name is required
                                                </mat-error>
                                            </mat-form-field>
                                        </p>
                                        
                                    </form>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </mat-tab>
                <mat-tab *jhiHasAnyAuthority="'PROJECTS_NAVBAR'" label="Project">
                    <mat-card>
                        <div class="col-md-12" *ngIf="!dataSource">
                            <p>
                                <ngb-alert type="info" [dismissible]="false">
                                    <strong>Info!</strong> No records found.
                                </ngb-alert>
                            </p>
                        </div>
                        <div class="row quickLinkDiv" *ngIf="dataSource">
                            <div class="col-sm-5 ">
                                <mat-form-field style="width:65%;">
                                    <input (keyup)="search($event.target.value)" matInput
                                        placeholder="Search project (type project name, project code, account name)">
                                </mat-form-field>
                            </div>
                            <div class="col-sm-3">
                            </div>
                            <div class="col-sm-4 text-right">
                            </div>
                        </div>
                        <mat-table [dataSource]="dataSource" matSort *ngIf="dataSource">
                            <ng-container matColumnDef="projectCode">
                                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Code</mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <a (click)="goToProjectPage(element.projectCode)">{{element.projectCode}} </a>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="projectName">
                                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Name
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.projectName}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="offshoreManagerName">
                                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Owner
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.offshoreManagerName}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="country">
                                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Country
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.countryName}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="projectStatusDescription">
                                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Status
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.projectStatusDescription}}
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
                        </mat-paginator>
                    </mat-card>
                </mat-tab>
                <mat-tab label="SOW">
                    <mat-card>
                        <div class="row">
                            <div class="col-md-12" *ngIf="!searchTableData">
                                <p>
                                    <ngb-alert type="info" [dismissible]="false">
                                        <strong>Info!</strong> No records found.
                                    </ngb-alert>
                                </p>
                            </div>
                            <div class="col-md-12" *ngIf="searchTableData && searchTableData.length">
                                <table class="table listings-table view-account-sows">
                                    <thead>
                                        <tr>
                                            <th scope="col" rowspan="2">Account</th>
                                            <th scope="col" rowspan="2">SOW Name</th>
                                            <th scope="col" rowspan="2">Date Range</th>
                                            <th scope="col" rowspan="2">Total<br />WBS Hours</th>
                                            <th scope="colgroup" colspan="2">Billable WBS</th>
                                            <th scope="col" rowspan="2">Misc Bill</th>
                                            <th scope="col" rowspan="2">Total Bill</th>
                                        </tr>
                                        <tr>
                                            <th scope=col>Hours</th>
                                            <th scope=col>Bill</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let sow of searchTableData;">
                                            <td>
                                                {{sow.account.name}}
                                            </td>
                                            <td><a
                                                    skipLocationChange routerLink="/statement-of-work/details-sow/{{encode(sow['_links']['self']['href'])}}">{{sow.sowNumber}}</a>
                                            </td>
                                            <td>
                                                <span>{{sow.startDate | date: DEFAULT_DATE_FORMAT}}</span><br />
                                                <span *ngIf="sow.endDate">{{sow.endDate | date: DEFAULT_DATE_FORMAT}}</span>
                                                <span *ngIf="!sow.endDate">-</span>
                                            </td>
                                            <td
                                                [ngClass]="setMyClasses(sow.totalHoursSummary.contractedHours,sow.totalHoursSummary.consumedHours, 90)">
                                                {{sow.totalHoursSummary.contractedHours}}
                                                <ngb-progressbar type="info" mat-raised-button
                                                    matTooltip="Contracted Hours: {{ sow.totalHoursSummary.contractedHours }}, Consumed Hours: {{sow.totalHoursSummary.consumedHours }}, Consumed Hours Percentage: {{calPercentage(sow.totalHoursSummary.contractedHours,sow.totalHoursSummary.consumedHours)}}%"
                                                    [value]="calPercentage(sow.totalHoursSummary.contractedHours, sow.totalHoursSummary.consumedHours)">
                                                </ngb-progressbar>
                                            </td>
                                            <td
                                                [ngClass]="setMyClasses(sow.billableHoursSummary.contractedHours,sow.billableHoursSummary.consumedHours, 90)">
                                                {{sow.billableHoursSummary.contractedHours}}
                                                <ngb-progressbar type="info" mat-raised-button
                                                    matTooltip="Contracted Hours: {{ sow.billableHoursSummary.contractedHours }}, Consumed Hours: {{sow.billableHoursSummary.consumedHours }}, Consumed Hours Percentage: {{calPercentage(sow.billableHoursSummary.contractedHours,sow.billableHoursSummary.consumedHours)}}%"
                                                    [value]="calPercentage(sow.billableHoursSummary.contractedHours, sow.billableHoursSummary.consumedHours)">
                                                </ngb-progressbar>
                                            </td>
                                            <td
                                                [ngClass]="setMyClasses(sow.totalBillsSummary.contractedBill.amount,sow.totalBillsSummary.consumedBill.amount, 75)">
                                                {{formatNumber(sow.totalBillsSummary.contractedBill.amount,sow.currencyUnit.key)}}
                                                <ngb-progressbar type="info" mat-raised-button
                                                    matTooltip="Contracted Bill: {{ sow.totalBillsSummary.contractedBill.amount }}, Consumed Bill: {{sow.totalBillsSummary.consumedBill.amount }}, Consumed Bill Percentage {{calPercentage(sow.totalBillsSummary.contractedBill.amount,sow.totalBillsSummary.consumedBill.amount)}}%"
                                                    [value]="calPercentage(sow.totalBillsSummary.contractedBill.amount, sow.totalBillsSummary.consumedBill.amount)">
                                                </ngb-progressbar>
                                            </td>
                                            <td>{{formatNumber(sow.miscellaneousBill.amount,sow.currencyUnit.key)}}</td>
                                            <td
                                                [ngClass]="setMyClasses(sow.totalBillsSummary.contractedBill.amount + sow.miscellaneousBill.amount,sow.totalBillsSummary.consumedBill.amount, 75)">
                                                {{formatNumber(sow.totalBillsSummary.contractedBill.amount +
                                                        sow.miscellaneousBill.amount,sow.currencyUnit.key)}}
                                                <ngb-progressbar type="info" mat-raised-button
                                                    matTooltip="Contracted Bill: {{ sow.totalBillsSummary.contractedBill.amount + sow.miscellaneousBill.amount }}, Consumed Bill: {{sow.totalBillsSummary.consumedBill.amount }}, Consumed Bill Percentage: {{calPercentage(sow.totalBillsSummary.contractedBill.amount
                                                            + sow.miscellaneousBill.amount,sow.totalBillsSummary.consumedBill.amount)}}%"
                                                    [value]="calPercentage(sow.totalBillsSummary.contractedBill.amount + sow.miscellaneousBill.amount, sow.totalBillsSummary.consumedBill.amount)">
                                                </ngb-progressbar>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6"></div>
                            <div class="col-md-6 custom-pagination" *ngIf="searchTableDataPage">
                                <span class="text">Items per page:&nbsp;</span>
                                <mat-form-field>
                                    <mat-select [(ngModel)]="paginationSelection" [ngModelOptions]="{standalone: true}"
                                        (selectionChange)="paginationFilter()">
                                        <mat-option [value]="pagination[0]">2</mat-option>
                                        <mat-option [value]="pagination[1]" *ngIf="searchTableDataPage.totalRecords >= 2">4
                                        </mat-option>
                                        <mat-option [value]="pagination[2]" *ngIf="searchTableDataPage.totalRecords >= 4">6
                                        </mat-option>
                                        <mat-option [value]="pagination[3]" *ngIf="searchTableDataPage.totalRecords >= 6">8
                                        </mat-option>
                                        <mat-option [value]="pagination[4]" *ngIf="searchTableDataPage.totalRecords >= 8">10
                                        </mat-option>
                                        <mat-option [value]="pagination[5]" *ngIf="searchTableDataPage.totalRecords >= 10">12
                                        </mat-option>
                                        <mat-option [value]="pagination[6]" *ngIf="searchTableDataPage.totalRecords >= 12">14
                                        </mat-option>
                                        <mat-option [value]="pagination[7]" *ngIf="searchTableDataPage.totalRecords >= 14">16
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <span class="pageNumber-indicator">
                                    {{(searchTableDataPage.currentPage*paginationSelection) + 1}}-
                                    <span
                                        *ngIf="((searchTableDataPage.currentPage+1)*paginationSelection) >= searchTableDataPage.totalRecords">{{searchTableDataPage.totalRecords}}</span>
                                    <span
                                        *ngIf="((searchTableDataPage.currentPage+1)*paginationSelection) < searchTableDataPage.totalRecords">{{(searchTableDataPage.currentPage+1)*paginationSelection}}</span>
                                    of
                                    <span>{{searchTableDataPage.totalRecords}}</span>
                                </span>
                                <div class="next-prev">
                                    <span (click)="jumpPageTo(0)"
                                        [ngClass]="{'left-pipe': true,'disable-click': searchTableDataPage.currentPage === 0,'enable-click': searchTableDataPage.currentPage >= 1 }">
                                        <span class="pipe">|</span>
                                        <i class="fa fa-angle-left pipe-left" aria-hidden="true"></i>
                                    </span>
                                    <i (click)="jumpPageTo(searchTableDataPage.currentPage-1)"
                                        [ngClass]="{'fa': true, 'fa-angle-left': true, 'disable-click': !(searchTableDataPage._links && searchTableDataPage._links.prev),'enable-click': (searchTableDataPage._links && searchTableDataPage._links.prev) }"
                                        aria-hidden="true"></i>
                                    <span>&nbsp;</span>
                                    <i (click)="jumpPageTo(searchTableDataPage.currentPage+1)"
                                        [ngClass]="{'fa': true, 'fa-angle-right': true, 'disable-click': !(searchTableDataPage._links && searchTableDataPage._links.next),'enable-click': (searchTableDataPage._links && searchTableDataPage._links.next) }"
                                        aria-hidden="true"></i>
                                    <span (click)="jumpPageTo(searchTableDataPage.totalPages-1)"
                                        [ngClass]="{'right-pipe': true,'disable-click': !(searchTableDataPage._links && searchTableDataPage._links.last),'enable-click': (searchTableDataPage._links && searchTableDataPage._links.last) }">
                                        <i class="fa fa-angle-right pipe-right" aria-hidden="true"></i><span
                                            class="pipe">|</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </mat-tab>
<ng-template [jhiHasAnyAuthority]="'ACCOUNTS_HISTORY'">
    <mat-tab label="History">
        <jhi-audit [historyData]="historyData" [task]="'Account'"></jhi-audit>
    </mat-tab>
</ng-template>
</mat-tab-group>
</div>
</div>
</div>
<div class="dark-overlay" *ngIf="load">
    <mat-spinner class="loader" strokeWidth="3" [diameter]="50"></mat-spinner>
</div>
