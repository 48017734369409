<div class="list-account">
    <div class="row">
        <div class="col-sm-5">
            <h2 id='main-heading'>Account</h2>
            <!-- <mat-form-field class="account-search">
                <input (keyup)="search($event.target.value)" matInput
                    placeholder="Search account (type customer name, account code, etc...)">
            </mat-form-field> -->
        </div>
        <div class="col-sm-4"></div>
        <div *jhiHasAnyAuthority="'ACCOUNTS_CREATE'" class="col-sm-3" style="padding-top: 15px;">

            <button class="add-acount-button" mat-raised-button skipLocationChange routerLink="/addaccount" color="primary">
                <mat-icon>add</mat-icon>Add Account
            </button>
        </div>
    </div>

    <div class="row">
        <form [formGroup]="accountFilterForm" class="col-12">
            <p class="text-center font-weight-bold">Use the search parameter(s) to populate the account list.</p>
            <mat-card class="card-display" style="display: flex;">
                <!-- <div class="col-12"> -->
                <div class="col-lg-2 col-md-2 col-sm-12 float-left">
                    <mat-form-field class="facet row-full-width">
                        <mat-select placeholder="Customer Entity Name" formControlName="entityName" #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="customerEntitySelectSearchFilterCtrl"
                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option value="ALL">All</mat-option>
                            <mat-option *ngFor="let customerEntity of customerEntityListFiltered | async" [value]="customerEntity">{{customerEntity}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 float-left">
                    <mat-form-field class="facet row-full-width">
                        <mat-select placeholder="Customer Name" formControlName="customerName" #customerNameSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="customerNameSearchFilterCtrl"
                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option value="ALL">All</mat-option>
                            <mat-option *ngFor="let data of customerNameListFiltered | async" [value]="data">{{data}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 float-left">
                    <mat-form-field class="facet row-full-width">
                        <mat-select placeholder="Account Manager Name" formControlName="accountManagerName" #accountManagerSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="accountManagerNameSearchFilterCtrl"
                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option value="ALL">All </mat-option>
                            <mat-option *ngFor="let data of accountManagerListFiltered | async" [value]="data.code"> {{data.name}} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- </div> -->
                <!-- <div class="col-12"> -->

                <div class="col-lg-2 col-md-2 col-sm-12 float-left">
                    <mat-form-field class="facet row-full-width">
                        <mat-select placeholder="AR Owner Name" formControlName="AROwnerName" #arOwnerSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="arOwnerNameSearchFilterCtrl"
                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option value="ALL">All</mat-option>
                            <mat-option *ngFor="let owner of arOwnerListFiltered | async" [value]="owner.arOwnerCode"> {{owner.arOwnerName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 float-left mt-3">
                    <p class="mb-0">
                        <mat-checkbox formControlName="isTimeTrackingAvailable" (change)="checkstate($event)">Time Tracking Availability
                        </mat-checkbox>
                    </p>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 float-left mt-3">
                    <button mat-raised-button color="primary" (click)="searchFilters()">Search</button>
                    <button mat-raised-button class="bg-transparent" (click)="resetFilters()">Reset</button>
                </div>
                <!-- </div> -->
            </mat-card>
        </form>
        <div class="col-12">
            
        </div>
    </div>
</div>
<!-- <mat-spinner class="spinner__loading" diameter="40" *ngIf="isLoading"></mat-spinner> -->
<mat-table  [ngClass]="{'no-actions': isLoading}" [dataSource]="dataSource" matSort  [matSortActive]="sortActive" [matSortDirection]="sortDirection" class="mt-3 mat-elevation-z8" *ngIf="dataSource.data.length > 0">
   
    <ng-container matColumnDef="accountCode">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Code</mat-header-cell>
        <mat-cell *matCellDef="let element"><span class="mobile-label">Code :</span>
            <a (click)="goToAccountPage(element.accountCode)"> {{element.accountCode}} </a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerName">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </mat-header-cell>
        <mat-cell *matCellDef="let element"><span class="mobile-label">Customer Name :</span> {{element.customerName}}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerEntity">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Customer Entity </mat-header-cell>
        <mat-cell *matCellDef="let element"><span class="mobile-label">Customer Entity :</span>
            {{element.customerEntity}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countryName">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Country </mat-header-cell>
        <mat-cell *matCellDef="let element"><span class="mobile-label">Country :</span> {{element.countryName}}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerReportingManager">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Customer Contact Person</mat-header-cell>
        <mat-cell *matCellDef="let element"><span class="mobile-label">Customer Contact Person :</span>
            {{element.customerReportingManager}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="accountManagerName">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Manager</mat-header-cell>
        <mat-cell *matCellDef="let element"><span class="mobile-label">Manager :</span> {{element.accountManagerName}}
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<div class="text-center mt-0" *ngIf="dataSource.data.length === 0">
    <p>
        <ngb-alert type="info" [dismissible]="false">
            <strong>Info!</strong> No records found.
        </ngb-alert>
    </p>
</div>
 <div class="col-md-12 pagination-left" *ngIf="totalItems">
    <div class="pagination-controls">
        <span class="marigin-right">
            Items per page
            <select (change)="goToPage($event.target.value)" class="selectField">
               <option value="5"  [selected]="itemsPerPage==5">5</option>
               <option value="10" [selected]="itemsPerPage==10">10</option> 
               <option value="15" [selected]="itemsPerPage==15">15</option> 
               <option value="25" [selected]="itemsPerPage==25">25</option> 
               <option value="50" [selected]="itemsPerPage==50">50</option> 
          </select>
         </span> 
         <span class="marigin-right" >
              {{ currentPage === 0 ? 1: currentPage * itemsPerPage + 1}} - {{  ((currentPage * itemsPerPage) + itemsPerPage) >= totalItems ? totalItems :  (currentPage * itemsPerPage) + itemsPerPage }}  of &nbsp; {{ totalItems }}
        </span>
        <button   (click)="goToFirstPage()" [disabled]="currentPage === 0" mat-icon-button="" type="button" class="mat-paginator-navigation-first"><span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path></svg></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
        <button (click)="goToPreviousPage()" [disabled]="currentPage === 0" mat-icon-button="" type="button" class=" mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-previous mat-icon-button mat-button-base" ng-reflect-message="Previous page" ng-reflect-disabled="false" ng-reflect-position="above" aria-label="Previous page"><span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
        <button  (click)="goToNextPage()" [disabled]="currentPage === totalPages-1" mat-icon-button="" type="button" class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-next mat-icon-button mat-button-base" ng-reflect-message="Next page" ng-reflect-disabled="false" ng-reflect-position="above" aria-label="Next page"><span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
        <button   (click)="goToLastPage()" [disabled]="currentPage === totalPages-1" mat-icon-button="" type="button" class="mat-paginator-navigation-first"><span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon"><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path></svg></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
    </div>
 </div>   


<button class="btn export-button" *ngIf="dataSource.data.length > 0" mat-raised-button color="primary"
    (click)='exportAccount()'>
    <i class="fas fa-download" style="padding-right: 5px"></i>Export Accounts
</button>
<div class="dark-overlay" *ngIf="load">
    <mat-spinner class="loader" strokeWidth="3" [diameter]="50"></mat-spinner>
</div>
