<div class="list-account">
    <div class="row col-lg-6 col-md-6 col-sm-12 float-left">
        <div>
            <h2 id='main-heading'>Remove Duplicate Timesheet</h2>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 float-left">
            <form class="duplicate-form" [formGroup]="exportReportForm" novalidate>
                <mat-card class="card-display" style="display: flex;">
                    <div  class="row col-lg-12 col-md-12 col-sm-12 float-left">
                        <mat-form-field class="col-12">        
                            <span matPrefix>
                                <mat-icon matSuffix>search</mat-icon>&nbsp;&nbsp;
                            </span>
                            <input matInput #resourceSearch formControlName="resourceCode" placeholder="Search Resource (eg. type resource id, resource name, email-id.)" aria-label="State" [matAutocomplete]="autoBulk" (keyup)="searchResource($event.target.value)" [(ngModel)]="empResourceCode" />
                            <mat-autocomplete autoActiveFirstOption #autoBulk="matAutocomplete" (optionSelected)="onResourceSelection($event)" [displayWith]="displayFn">
                                <mat-option class="resourceList" *ngFor="let resource of resourcesList" [value]="resource">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <span>{{resource.code}} </span>
                                        </div>
                                        <div class="col-sm-7 res-name">
                                            <span>{{resource.name}}</span>
                                        </div>
                                        <div class="col-sm-12">
                                            <h6>{{resource.emailId}}</h6>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="exportReportForm.get('resourceCode').hasError('required')"> This is a required field.</mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-12">
                            <input matInput [matDatepicker]="picker" placeholder="Week Date"
                                    formControlName="weekDate"  readonly="true"
                                    placement="bottom-right">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker disabled="false"></mat-datepicker>
                        </mat-form-field>
                        <div class="col-12 text-right">
                            <button mat-raised-button class="searchButton" color="primary" (click)="getDuplicateTimesheet()" [disabled]="!exportReportForm.valid">Search</button>
                            <button mat-raised-button class="bg-transparent"  (click)='resetexportReport()'>Reset</button>
                        </div>
                    </div>
                </mat-card> 
           </form>          
        </div>  
        <div class="text-center mt-0 padding-top1" *ngIf="duplicates.length <=0 &&  this.submited == true">
            <p>
                <ngb-alert type="info" [dismissible]="false">
                     <b>Timesheets records not found. </b>
                </ngb-alert>
            </p>
        </div>
        <div class="text-center mt-0 padding-top col-lg-12 col-md-12 col-sm-12 float-left" *ngIf="duplicates.length >=1">
            <div class="row duplicates-padding-top">
                <div class="col-lg-12 col-md-12 col-sm-12 float-left">
                    <h2 id='main-heading'> Timesheets List </h2>
                </div>
            </div>
                <div>
                    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        
                        <ng-container matColumnDef="position">
                          <mat-header-cell *matHeaderCellDef> Sl No. </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.position + 1}} </mat-cell>
                        </ng-container>
                      
                        <ng-container matColumnDef="id">
                          <mat-header-cell *matHeaderCellDef> Timesheet Id </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
                        </ng-container>
                      
                        <ng-container matColumnDef="action">
                          <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                          <mat-cell *matCellDef="let element">
                            <button mat-icon-button (click)="removeDuplicate(element.id)">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </mat-cell>
                        </ng-container>
                      
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                      
                      </mat-table>
                          
        
                </div>
         
        </div>      
    </div>
    <div  class="row col-lg-6 col-md-6 col-sm-12 float-right">
        <div class="col-12">
            <div>
                <h2 id='main-heading'>ALMS Leave Accrual (Manually) </h2>
            </div>
            <mat-card class="overflow-auto">
                <div class="col-6 mb-4 mt-3">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="jobType">
                        <mat-radio-button class="ml-3" *ngFor="let leaveJobType of leaveJobTypes" [value] = 'leaveJobType'>{{leaveJobType}}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="col-12 mt-4 mb-2 pull-left">
                    <p class="col-6 pull-left">
                        <button (click)="updateLeaveCredit('onShore', jobType)" [disabled] ="!jobType" class="btn-block" mat-raised-button color="primary">Trigger Onshore Job {{jobType}}</button>
                    </p>
                    <p class="col-6 pull-left">
                        <button (click)="updateLeaveCredit('offShore', jobType)" [disabled] ="!jobType" class="btn-block" mat-raised-button color="primary">Trigger Offshore Job {{jobType}}</button>
                    </p>
                </div>
            </mat-card>
        </div>
    </div>
</div>