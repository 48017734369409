<div id="viewBy" class="fixed-top">
    <nav class="navbar navbar-light navbar-expand-sm jh-navbar">
        <div class="jh-logo-container float-left">
            <a class="jh-navbar-toggler d-lg-none float-right" href="javascript:void(0);" data-toggle="collapse"
                data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                aria-label="Toggle navigation" (click)="toggleNavbar()">
                <i class="fa fa-bars imagelen"></i>
            </a>
            <a class="navbar-brand logo float-left" routerLink="/" (click)="collapseNavbar()">
                <!-- <span class="logo-img"></span> -->
                <img class="mainLogo" src="../../assets/apertureByGspann.png" alt="aperture">
            </a>
            <!-- <a><img src="../../assets/refer&earn11.png" alt="Refer & Earn"></a> -->
        </div>
        <div class="navbar-collapse collapse" id="navbarResponsive" [ngbCollapse]="isNavbarCollapsed"
            [ngSwitch]="isAuthenticated()">
            <ul class="navbar-nav  mr-auto" style="margin: 0;padding: 0;line-height: 38px;">
                <li ngbDropdown class="nav-item dropdown pointer" placement="bottom-right" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}" *jhiHasAnyAuthority="'USER'">
                    <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu">
                        <span>
                            <i class="imagelen" aria-hidden="true"></i>
                            <span>Timesheet</span>
                        </span>
                    </a>
                    <ul class="dropdown-menu" ngbDropdownMenu>
                        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <a class="dropdown-item" skipLocationChange routerLink="/timesheet-view" (click)="collapseNavbar()">
                                My Timesheet
                            </a>
                            <span class="underline"></span>
                        </li>
                        <li routerLinkActive="active" *jhiHasAnyAuthority="'TIMESHEETS_NAVBAR'"
                            [routerLinkActiveOptions]="{exact: true}">
                            <a class="dropdown-item" skipLocationChange routerLink="/timesheet-approvals" (click)="collapseNavbar()">
                                Timesheet Approvals
                            </a>
                            <span class="underline"></span>
                        </li>
                        <li *jhiHasAnyAuthority="'TIMESHEETS_EXPORT'" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">
                            <a class="dropdown-item" skipLocationChange routerLink="/timesheet-export" (click)="collapseNavbar()">
                                Timesheet Export
                            </a>
                            <span class="underline"></span>
                        </li>
                    </ul>
                </li>
                <li *jhiHasAnyAuthority="'FINANCE_NAVBAR'" ngbDropdown class="nav-item dropdown pointer" placement="bottom-right"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu">
                    <span>
                        <i class=" imagelen" aria-hidden="true"></i>
                        <span>Finance</span>
                    </span>
                </a>
             <ul class="dropdown-menu" ngbDropdownMenu>
                <li *jhiHasAnyAuthority="'ACCOUNTS_NAVBAR'">
                <a class="dropdown-item" skipLocationChange routerLink="/listaccounts">
                    Account
                    <span class="underline"></span>
                 </a>
                </li>
                <li *jhiHasAnyAuthority="'PROJECTS_NAVBAR'">
                <a class="dropdown-item" skipLocationChange routerLink="/projects/list-projects">
                    Project
                    <span class="underline"></span>
                </a>
              </li>
                <li *jhiHasAnyAuthority="'SOW_NAVBAR'">
                    <a class="dropdown-item" skipLocationChange routerLink="/statement-of-work/listings-sow">
                        SoW
                    </a>
                    <span class="underline"></span>
                </li>
             </ul>
          </li>
                <li *jhiHasAnyAuthority="'RESOURCES_NAVBAR'" ngbDropdown class="nav-item dropdown pointer"
                    placement="bottom-right" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu">
                        <span>
                            <i class=" imagelen" aria-hidden="true"></i>
                            <span>Resource</span>
                        </span>
                    </a>
                    <ul class="dropdown-menu" ngbDropdownMenu>
                        <li>
                            <a class="dropdown-item" skipLocationChange routerLink="/resource/search-resource">
                                Aperture
                            </a>
                            <span class="underline"></span>
                        </li>
                        <li>
                            <a class="dropdown-item" (click)="redirectToPreboarding()" target="blank">
                                Pre-Boarding
                            </a>
                            <span class="underline"></span>
                        </li>
                        <li *jhiHasAnyAuthority="'RESOURCES_DESIGNATION_UPLOAD'">
                            <a class="dropdown-item" skipLocationChange routerLink="/designations-upload" (click)="collapseNavbar()">
                                Designations Upload
                            </a>
                            <span class="underline"></span>
                        </li>
                    </ul>
                </li>
                <li *jhiHasAnyAuthority="'ALLOCATION_NAVBAR'" class="nav-item" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" skipLocationChange routerLink="/Allocations/listings-allocations" (click)="collapseNavbar()">
                        <span>
                            <i class="" aria-hidden="true"></i>
                            <span>Allocation</span>
                        </span>
                    </a>
                </li>
                <li *jhiHasAnyAuthority="'MASTER_DATA_NAVBAR'" ngbDropdown class="nav-item dropdown pointer"
                    placement="bottom-right" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu">
                        <span>
                            <i class=" imagelen" aria-hidden="true"></i>
                            <span>Masters</span>
                        </span>
                    </a>
                    <ul class="dropdown-menu" ngbDropdownMenu>
                       
                        <li  class="nav-item dropdown pointer skill-link practic-e" placement="right" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}" *jhiHasAnyAuthority="'MASTER_PRACTICE'">
                         <div ngbDropdown placement="right">
                            <a id="skillLink" style="background-color:#fff!important; height: 44px!important;" class="nav-link dropdown-toggle skill-link" ngbDropdownToggle href="javascript:void(0);">
                                <span>
                                    <i class=" imagelen" aria-hidden="true"></i>
                                    <span > Practice </span>
                                </span>
                            </a>
                             <ul class="dropdown-menu reports" aria-labelledby="skillLink" ngbDropdownMenu>
                            <li>
                                <a skipLocationChange class="dropdown-item show" skipLocationChange routerLink="/master-database/add-practice" >
                                     Practice
                                </a>
                                <span class="underline"></span>
                            </li>
                            <li>
                                <a skipLocationChange class="dropdown-item show" skipLocationChange routerLink="/master-database/add-practice-map">
                                    Upload Practice Mapping
                                </a>
                                <span class="underline"></span>
                            </li>
                            <li>
                                <a skipLocationChange class="dropdown-item show" (click)="downloadPracticeMap()">
                                    <i class="fas fa-download" style="padding-right: 5px"></i>Download Practice Mapping
                                </a>
                                <span class="underline"></span>
                            </li>
                            </ul>
                         </div>
                        </li>
                        <li *jhiHasAnyAuthority="'MASTER_OFFICE_LOCATION'">
                            <a class="dropdown-item" skipLocationChange routerLink="/master-database/add-office-locations">
                                Office
                            </a>
                            <span class="underline"></span>
                        </li>
                        <li>
                            <a class="dropdown-item" skipLocationChange routerLink="/master-database/create-department">
                                Department
                            </a>
                            <span class="underline"></span>
                        </li>

                        <li>
                            <a class="dropdown-item" skipLocationChange routerLink="/master-database/designation">
                                Designation
                            </a>
                            <span class="underline"></span>
                        </li>
                        <li>
                            <a class="dropdown-item" skipLocationChange routerLink="/master-database/gspann-entity">
                                GSPANN Entity
                            </a>
                            <span class="underline"></span>
                        </li>

                        
                        <li  class="nav-item dropdown pointer skill-link" placement="right" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">
                         <div ngbDropdown placement="right">
                            <a id="skillLink" style="background-color:#fff!important; height: 44px!important;" class="nav-link dropdown-toggle skill-link" ngbDropdownToggle href="javascript:void(0);">
                                <span>
                                    <i class=" imagelen" aria-hidden="true"></i>
                                    <span > Skill Central</span>
                                </span>
                            </a>
                             <ul class="dropdown-menu reports" aria-labelledby="skillLink" ngbDropdownMenu>
                            <li>
                                <a skipLocationChange class="dropdown-item show" (click)="downloadReports()">
                                    <i class="fas fa-download" style="padding-right: 5px"></i>Reports
                                </a>
                                <span class="underline"></span>
                            </li>
                            </ul>
                         </div>
                        </li>

                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *jhiHasAnyAuthority="['LMS']">
                    <a class="nav-link" skipLocationChange routerLink="/leave-management/leave-dashboard" (click)="collapseNavbar()">
                        <span>
                                <i class="imagelen" aria-hidden="true"></i>
                                <span>Leaves</span>
                        </span>
                    </a>
                </li>
                <!-- <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="/leave-management/leave-dashboard/holidays" (click)="collapseNavbar()">  
                        <span>
                            <i class="imagelen" aria-hidden="true"></i>
                            <span>Holidays</span>
                        </span>
                    </a>
                </li> -->
                <!-- <li ngbDropdown class="nav-item dropdown pointer" placement="bottom-right" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu">
                        <span>
                            <i class=" imagelen" aria-hidden="true"></i>
                            <span>Referrals</span>
                        </span>
                    </a>
                    <ul class="dropdown-menu" ngbDropdownMenu>
                        <li>
                            <a class="dropdown-item" skipLocationChange routerLink="/referrals/submit-referrals">
                                Refer & Earn
                            </a>
                            <span class="underline"></span>
                        </li>
                        <li *jhiHasAnyAuthority="'TALENT_ACQUISITION'">
                            <a class="dropdown-item" skipLocationChange routerLink="/referrals/receive-referral" (click)="collapseNavbar()">
                                <span>
                                    <i class="imagelen" aria-hidden="true"></i>
                                    <span>Referrals Received</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </li> -->
                <!--  timesheet and allocation  reports  -->
                <li ngbDropdown class="nav-item dropdown pointer " placement="bottom-right" routerLinkActive="active"
                    *jhiHasAnyAuthority="'BI_REPORT_NAVBAR'" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link dropdown-toggle " ngbDropdownToggle href="javascript:void(0);" id="account-menu">
                        <span>
                            <i class=" imagelen" aria-hidden="true"></i>
                            <span>Reports BI </span>
                        </span>
                    </a>
                    <ul class="dropdown-menu" ngbDropdownMenu>
                        <li *jhiHasAnyAuthority="'TIMESHEET_BI_REPORTS'">
                            <a class="dropdown-item"
                                href="https://app.powerbi.com/links/Vgu96AKoe4?ctid=6bc832cc-63a5-450d-b896-efdcd31eefaa&pbi_source=linkShare"
                                target="_blank">
                                Timesheet Report
                            </a>
                            <span class="underline"></span>
                        </li>
                        <li *jhiHasAnyAuthority="'ALLOCATION_BI_REPORTS'">
                            <a class="dropdown-item"
                                href=" https://app.powerbi.com/links/KZbX8NZoDB?ctid=6bc832cc-63a5-450d-b896-efdcd31eefaa&pbi_source=linkShare"
                                target="_blank">
                                Allocation Report
                            </a>
                            <span class="underline"></span>
                        </li>
                        <li *jhiHasAnyAuthority="'RESOURCE_BI_REPORTS'">
                            <a class="dropdown-item"
                                href=" https://app.powerbi.com/links/LoEC8RjZm0?ctid=6bc832cc-63a5-450d-b896-efdcd31eefaa&pbi_source=linkShare"
                                target="_blank">
                                Resource Report
                            </a>
                            <span class="underline"></span>
                        </li>
                        <li *jhiHasAnyAuthority="'SOW_BI_REPORTS'">
                            <a class="dropdown-item"
                                href=" https://app.powerbi.com/links/4L_Vo8XTxz?ctid=6bc832cc-63a5-450d-b896-efdcd31eefaa&pbi_source=linkShare"
                                target="_blank"> SOW Report
                            </a>
                            <span class="underline"></span>
                        </li>
                        <li *jhiHasAnyAuthority="'BLOCKED_RESOURCE_BI_REPORTS'">
                            <a class="dropdown-item"
                                href=" https://app.powerbi.com/links/cj8cPBGp-W?ctid=6bc832cc-63a5-450d-b896-efdcd31eefaa&pbi_source=linkShare"
                                target="_blank"> Blocked Resource
                            </a>
                            <span class="underline"></span>
                        </li>
                    </ul>
                </li>
                <!-- timesheet and allocation reports  abvoe -->
                <!-- jhipster-needle-add-element-to-menu - JHipster will add new menu items here -->
                <li *jhiHasAnyAuthority="'ROLE_ADMIN'" ngbDropdown class="nav-item dropdown pointer"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="admin-menu">
                        <span>
                            <i class=" imagelen" aria-hidden="true"></i>
                            <span>Administration</span>
                        </span>
                    </a>
                    <ul class="dropdown-menu" ngbDropdownMenu>
                        <li>
                            <a class="dropdown-item" skipLocationChange routerLink="admin/jhi-metrics" routerLinkActive="active"
                                (click)="collapseNavbar()">
                                <i class="fa fa-fw fa-tachometer imagelen" aria-hidden="true"></i>
                                <span>Metrics</span>
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" skipLocationChange routerLink="admin/jhi-health" routerLinkActive="active"
                                (click)="collapseNavbar()">
                                <i class="fa fa-fw fa-heart imagelen" aria-hidden="true"></i>
                                <span>Health</span>
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" skipLocationChange routerLink="admin/jhi-configuration" routerLinkActive="active"
                                (click)="collapseNavbar()">
                                <i class="fa fa-fw fa-list imagelen" aria-hidden="true"></i>
                                <span>Configuration</span>
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" skipLocationChange routerLink="admin/audits" routerLinkActive="active"
                                (click)="collapseNavbar()">
                                <i class="fa fa-fw fa-bell imagelen" aria-hidden="true"></i>
                                <span>Audits</span>
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" skipLocationChange routerLink="admin/logs" routerLinkActive="active"
                                (click)="collapseNavbar()">
                                <i class="fa fa-fw fa-tasks imagelen" aria-hidden="true"></i>
                                <span>Logs</span>
                            </a>
                        </li>
                        <li *ngIf="swaggerEnabled">
                            <a class="dropdown-item" (click)="redirectToApertureApi()" target="_blank">
                                <i class="fa fa-fw fa-book imagelen" aria-hidden="true"></i>
                                <span>Aperture API</span>
                            </a>
                        </li>
                        <li *jhiHasAnyAuthority="'LMS_API_ADMIN'">
                            <a class="dropdown-item" (click)="redirectToLMSApi()" target="_blank">
                                <i class="fa fa-fw fa-book imagelen" aria-hidden="true"></i>
                                <span>LMS API</span>
                            </a>
                        </li>
                        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <a class="dropdown-item" skipLocationChange routerLink="/duplicates-view" (click)="collapseNavbar()">
                                Admin UI
                            </a>
                            <span class="underline"></span>
                        </li>
                      <!-- jhipster-needle-add-element-to-admin-menu - JHipster will add entities to the admin menu here -->
                    </ul>
                </li>
                <li ngbDropdown class="nav-item dropdown pointer" placement="bottom-right" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu">
                        <span>
                            <i class=" imagelen" aria-hidden="true"></i>
                            <span>Help</span>
                        </span>
                    </a>
                    <ul *jhiHasAnyAuthority="'USER'" class="dropdown-menu" ngbDropdownMenu>
                        <li>
                            <a class="dropdown-item" skipLocationChange routerLink="/faqs">
                                FAQ
                            </a>
                            <span class="underline"></span>
                        </li>
                        <li>
                            <a class="dropdown-item" skipLocationChange routerLink="/trainings">
                                Trainings
                            </a>
                            <span class="underline"></span>
                        </li>
                    </ul>
                </li>
                <li *jhiHasAnyAuthority="'CALCULATOR_NAVBAR'" class="nav-item" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" skipLocationChange routerLink="/calculator" (click)="collapseNavbar()">
                        <span>
                            <i class="" aria-hidden="true"></i>
                            <span>Calculator</span>
                        </span>
                    </a>
                </li>

             

                <!-- <li class="nav-item only-mobile-display" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="/referrals/submit-referrals" (click)="collapseNavbar()">
                        <span>
                            <i class="imagelen" aria-hidden="true"></i>
                            <span>Refer & Earn</span>
                        </span>
                    </a>
                </li> -->
            </ul>
            <div class="navbar-collapse">
                <ul class="navbar-nav" style="position: absolute;right: 0;">
                    <!-- <li class="nav-item">
                        Timezone commented
                         <div class="navbar-timezone" *ngIf="isAuthenticated()">
                            <div class="timezone-desc">
                                {{currentTime | timezone: 'default'}}
                            </div>
                        </div>
                    </li> -->
                    <!-- <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a (click)="collapseNavbar()">
                            <img class="no-display-mobile" routerLink="/referrals/submit-referrals" style="width: 120px;position: relative;bottom: -44px;z-index: -10;" src="../../assets/refer&earn11.png" alt="Refer n Earn">
                        </a>
                    </li> -->
                    <li>
                        <a class="nav-link" (click)="openMobileAppDialog()">
                            <span class="mobile-app-left">
                                <img src="../../assets/download-app/PhoneIcon@2x.png" class="h-50 mr-2" alt="PhoneIcon">
                                <span>Mobile App</span>
                            </span>
                        </a>
                    </li>
                    <li ngbDropdown class="nav-item dropdown pointer" placement="bottom-right" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"
                        style="padding-top: 3px;border-left: 1px solid #9E9E9E;">
                        <a style="padding-bottom: 1px" class="nav-link dropdown-toggle" ngbDropdownToggle
                            href="javascript:void(0);" id="account-menu">
                            <span *ngIf="!getImageUrl()">
                                <img class="circle" [src]="imageUrl" *ngIf="imageUrl">
                                <img class="circle" src="../../assets/User_Profile_Pic.png" *ngIf="!imageUrl">
                            </span>
                            <span *ngIf="getImageUrl()">
                                <img [src]="imageUrl" class="profile-image img-circle" *ngIf="imageUrl">
                                <img class="circle" src="../../assets/User_Profile_Pic.png" *ngIf="!imageUrl">
                            </span>
                            <span>
                                {{account ? account.firstName : "UserName"}}
                            </span>
                        </a>

                        <ul class="dropdown-menu profileOptions" ngbDropdownMenu>
                            <li>
                                <a class="dropdown-item" (click)="goToMyProfile(resourceCode)">
                                    <span>My Profile</span>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" (click)="logout()" id="logout">
                                    <i class="fa fa-fw fa-sign-out imagelen" aria-hidden="true"></i>
                                    <span>Sign out</span>
                                </a>
                            </li>
                            <li class="version">
                                <span>Ver : {{appVersion}}</span>
                            </li>
                            <li *ngSwitchCase="false">
                                <a class="dropdown-item" (click)="login()" id="login">
                                    <i class="fa fa-fw fa-sign-in imagelen" aria-hidden="true"></i>
                                    <span>Sign in</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <div class="navbar-timezone-alias" *ngIf="isAuthenticated()">
            <div class="timezone-desc">
                {{currentTime | timezone: 'default'}}
            </div>
        </div> -->
    </nav>
</div>