import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ListAccountsService } from './account.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import { Title } from '@angular/platform-browser';
import _ from 'lodash';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { CommonDataService } from 'app/common-data.service';
import { populateSortDataAccessort } from 'app/utils/matSort';
@Component({
    selector: 'jhi-list-accounts',
    templateUrl: './list-accounts.component.html',
    styleUrls: ['./list-account.component.css']
})
export class ListAccountsComponent implements OnInit, OnDestroy,AfterViewInit {
    searchTableDataPage: any;
    paginationSelection = 5;
    page = 0;
    pageSize: any;
    totalRecords: any;
    isLoading: boolean = false;
    originalData: Array<any> = [];
    searchTerm ='';
    tableDataParams: Array<any>;
    pagination: Array<any> = [5, 10, 25, 100];
    protected _onDestroy = new Subject<void>();
    public reenableButton = new EventEmitter<boolean>(false);
    public customerEntityListFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public customerNameListFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public accountManagerListFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public arOwnerListFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public customerEntitySelectSearchFilterCtrl: FormControl = new FormControl();
    public accountManagerNameSearchFilterCtrl: FormControl = new FormControl();
    public arOwnerNameSearchFilterCtrl: FormControl = new FormControl();
    public customerNameSearchFilterCtrl: FormControl = new FormControl();
    @ViewChild('singleSelect') singleSelect: MatSelect;
    @ViewChild('customerNameSelect') customerNameSelect: MatSelect;
    @ViewChild('accountManagerSelect') accountManagerSelect: MatSelect;
    @ViewChild('arOwnerSelect') arOwnerSelect: MatSelect;
    accountFilterForm: FormGroup;
    arOwners: any;
    accountInitData: any;
    accountManagers = [];
    customerName = [];
    customerEntity = [];
    showNoRecordsFound: boolean = false;
    load = false;
    sortActive = 'accountCode'
    sortDirection = 'asc'
    @ViewChild(MatSort) sort: MatSort
    paginationManagerName: any;
    paginationArOwner: any;
    paginationCustomerEntity: any;
    paginationIsTimeTrack: any;
    paginationCustomerName: any;
    @ViewChild(MatPaginator)
    set paginator(value: MatPaginator) {
        this.dataSource.paginator = value;
    }
    @ViewChild('listAccountsPaginator', {static: false}) listAccountsPaginator: MatPaginator;
    displayedColumns = ['accountCode', 'customerName', 'customerEntity', 'countryName', 'customerReportingManager', 'accountManagerName'];
    dataSource;
    currentPage: number = 0;
    itemsPerPage: number = 5;
    totalItems: number;
    totalPages: number;
    pageNumber:number;
    constructor(private listAccountService: ListAccountsService, private titleService: Title, private fb: FormBuilder, private router: Router, private commonService: CommonDataService) {
        	this.titleService.setTitle('aperture - List Accounts');
           this.accountFilterForm = this.fb.group({
                entityName: new FormControl(''),
                customerName: new FormControl(''),
                accountManagerName: new FormControl(''),
                AROwnerName: new FormControl(''),
                isTimeTrackingAvailable: new FormControl(''),
           });
	 	}

    ngOnInit() {
        this.showNoRecordsFound = false;
        this.dataSource = new MatTableDataSource([]);
        this.getInitAccountsData();
        this.createSearchTableData();
        this.customerEntitySelectSearchFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {this.filterList('customerEntity');});
        this.accountManagerNameSearchFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(()=> {this.filterList('accountManager')});
        this.arOwnerNameSearchFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(()=> {this.filterList('arOwner')});
        this.customerNameSearchFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(()=> {this.filterList('customerName')});
    }
    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        if (this.dataSource.sort) {
        this.sortActive = this.dataSource.sort.active;
        this.sortDirection = this.dataSource.sort.direction
        }
    }

    getInitAccountsData() {
        this.accountFilterForm.get('entityName').setValue('ALL');
        this.accountFilterForm.get('customerName').setValue('ALL');
        this.accountFilterForm.get('accountManagerName').setValue('ALL');
        this.accountFilterForm.get('AROwnerName').setValue('ALL');
        this.accountFilterForm.get('isTimeTrackingAvailable').setValue(true);
        this.listAccountService.getInitListAccountData().subscribe(response=> {
            this.arOwners = response.arOwners;
            this.arOwners.sort((a,b)=>a.arOwnerName.toLowerCase().localeCompare(b.arOwnerName.toLowerCase()))
            let tempManager = [],
            customerName = [];
            this.accountInitData = response.accountInitDto;
            response.accountInitDto.forEach(loop=> {
                if (loop.accountManagerCode) this.accountManagers.push({code: loop.accountManagerCode, name: loop.accountManagerName});
                if (loop.customerEntity)  this.customerEntity.push(loop.customerEntity); 
                if (loop.customerName !== 'Internal') customerName.push(loop.customerName);
            });
            this.accountManagers = this.accountManagers.filter((obj,index)=> {
                return index === this.accountManagers.findIndex(o=> obj.code === o.code);
            });
            this.accountManagers.sort((a,b)=>a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            this.customerEntity = this.customerEntity.filter(Boolean);
            this.customerEntity.sort((a,b)=> a.toLowerCase().localeCompare(b.toLowerCase()));
            this.customerName = customerName;
            this.customerName.sort((a,b)=>a.toLowerCase().localeCompare(b.toLowerCase()));
            this.singleSelect.open();
            this.customerNameSelect.open();
            this.arOwnerSelect.open();
            this.accountManagerSelect.open();
            setTimeout(()=> {
                this.singleSelect.close();
                this.customerNameSelect.close();
                this.arOwnerSelect.close();
                this.accountManagerSelect.close();
            },10);
        });
    }
    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    filterList(filterType) {
        if (filterType === 'customerEntity') {
            if (!this.customerEntity) {
                return;
            }
            // get the search keyword
            let search = this.customerEntitySelectSearchFilterCtrl.value;
            if (!search) {
                this.customerEntityListFiltered.next(this.customerEntity.slice());
                return;
            } else {
                search = search.toLowerCase();
            }
            // filter the list
            this.customerEntityListFiltered.next(
                this.customerEntity.filter(obj => obj.toLowerCase().indexOf(search) > -1)
            );
        } else if (filterType === 'accountManager') {
            if (!this.accountManagers) {
                return;
            }
            // get the search keyword
            let search = this.accountManagerNameSearchFilterCtrl.value;
            if (!search) {
                this.accountManagerListFiltered.next(this.accountManagers.slice());
                return;
            } else {
                search = search.toLowerCase();
            }
            // filter the list
            this.accountManagerListFiltered.next(
                this.accountManagers.filter(obj => obj.name.toLowerCase().indexOf(search) > -1)
            ); 
        } else if (filterType === 'arOwner') {
            if (!this.arOwners) {
                return;
            }
            // get the search keyword
            let search = this.arOwnerNameSearchFilterCtrl.value;
            if (!search) {
                this.arOwnerListFiltered.next(this.arOwners.slice());
                return;
            } else {
                search = search.toLowerCase();
            }
            // filter the list
            this.arOwnerListFiltered.next(
                this.arOwners.filter(obj => obj.arOwnerName.toLowerCase().indexOf(search) > -1)
            );
        } else if (filterType === 'customerName') {
            if (!this.customerName) {
                return;
            }
            // get the search keyword
            let search = this.customerNameSearchFilterCtrl.value;
            if (!search) {
                this.customerNameListFiltered.next(this.customerName.slice());
                return;
            } else {
                search = search.toLowerCase();
            }
            // filter the list
            this.customerNameListFiltered.next(
                this.customerName.filter(obj => obj.toLowerCase().indexOf(search) > -1)
            );
        }
        
    }

    checkstate(event) {
        if (event.checked) {
            this.accountFilterForm.get('isTimeTrackingAvailable').setValue(true);
        } else {
            this.accountFilterForm.get('isTimeTrackingAvailable').setValue(false);
        }
    }

    handlePageBottom(event: PageEvent) {
        this.listAccountsPaginator.pageSize = event.pageSize;
        this.listAccountsPaginator.pageIndex = event.pageIndex; 
    }

    getAccountsList(){
      
        let managerName  = this.paginationManagerName;
        let arOwner = this.paginationArOwner;
        let customerEntity = this.paginationCustomerEntity;
        let customerName = this.paginationCustomerName;
        let isTimeTrack = this.paginationIsTimeTrack;

        this.listAccountService.searchAccounts(managerName,arOwner, customerEntity, customerName, isTimeTrack,this.currentPage,this.itemsPerPage).subscribe(response=> {
            setTimeout(() => {
                this.load = false;
            }, 1000);
            if (Object.keys(response.content).length === 0) {
                this.showNoRecordsFound = true;
            } else {
                this.showNoRecordsFound = false;
            }
            this.dataSource = new MatTableDataSource(_.orderBy(response.content, this.sortActive, this.sortDirection));
            this.dataSource = populateSortDataAccessort(this.dataSource)
            this.dataSource.paginator = this.paginator;
            this.totalItems = response.totalElements;
            this.totalPages = response.totalPages;
            (err=>{
                this.load = false;
            })
        })
    }
     
    goToPreviousPage(): void {
        if (this.currentPage >= 1) {
            this.currentPage= this.currentPage-1;
          this.getAccountsList();
        }
      }
    
      goToNextPage(): void {
        if (this.currentPage < this.totalPages) {
           this.currentPage= this.currentPage+1;
           this.getAccountsList();
        }
      }
      goToLastPage(): void {
          this.currentPage = this.totalPages-1;
          this.getAccountsList();
      }
      goToFirstPage():void {
        this.currentPage = 0;
        this.getAccountsList();
      }
    
     
      goToPage(page: string): void {
          this.itemsPerPage = parseInt(page, 10);
          this.currentPage = 0;
          this.getAccountsList();
        
      }
   

    searchFilters() {
        this.load = true;
        this.paginationCustomerEntity =  encodeURIComponent(this.accountFilterForm.get('entityName').value);
        this.paginationCustomerName = encodeURIComponent(this.accountFilterForm.get('customerName').value);
        this.paginationManagerName = this.commonService.encrypt(this.accountFilterForm.get('accountManagerName').value);
        this.paginationArOwner = this.accountFilterForm.get('AROwnerName').value;
        this.paginationIsTimeTrack = this.accountFilterForm.get('isTimeTrackingAvailable').value;
        this.currentPage = 0;
        this.itemsPerPage= 5
        this.getAccountsList()
         
    }

    resetFilters() {
        this.accountFilterForm.get('entityName').setValue('ALL');
        this.accountFilterForm.get('customerName').setValue('ALL');
        this.accountFilterForm.get('accountManagerName').setValue('ALL');
        this.accountFilterForm.get('AROwnerName').setValue('ALL');
        this.dataSource = new MatTableDataSource([]);
        this.searchFilters();
         
    }

    createSearchTableData() {
        this.searchTableDataPage = {
            currentPage: 0,
            hasNext: false,
            hasPrevious: false,
            header: '',
            isFirst: false,
            isLast: false,
            pageSize: 0,
            totalPages: 0,
            totalRecords: 0
        };
    }

    getAccounts() {
        this.listAccountService.getAccountList().subscribe(listAccounts => {
            this.dataSource = new MatTableDataSource(listAccounts.content);
            this.originalData = _.cloneDeep(listAccounts.content);
            this.dataSource.paginator = this.paginator;
            this.searchTableDataPage = listAccounts.page;
            if (listAccounts.content.length > this.paginationSelection) {
                this.searchTableDataPage.hasNext = true;
            } else {
                this.searchTableDataPage.hasNext = false;
            }
            this.dataSource.sort = this.sort;
            this.dataSource = populateSortDataAccessort(this.dataSource)
        });
    }
  

    jumpPageTo(pageNumber) {
        this.page = pageNumber;
        this.searchTableDataPage.currentPage = pageNumber;
        let filteredList = [];
        let startingIndex = pageNumber * this.paginationSelection;
        let endingIndex = (pageNumber + 1) * this.paginationSelection;
        filteredList = this.originalData;
        let filteredData = new MatTableDataSource(filteredList);
        filteredData.filter = this.searchTerm;
        filteredList = filteredData.filteredData.slice(startingIndex, endingIndex);
        this.dataSource = new MatTableDataSource(filteredList);
        let total = this.totalRecords/this.paginationSelection;
        if (this.paginationSelection > this.totalRecords) {
            total = 1;
        } else {
            if (this.totalRecords%this.paginationSelection !==0) {
                let x = total.toString();
                const index = x.indexOf('.');
                x = x.substring(0,index);
                total = parseInt(x);
            }
            total = total + 1;
        }
        this.searchTableDataPage.totalPages = total;
        if ( this.searchTableDataPage.totalPages > pageNumber + 1) {
            this.searchTableDataPage.hasNext = true;
        } else {
            this.searchTableDataPage.hasNext = false;
        }
        if (pageNumber === 0) {
            this.searchTableDataPage.hasPrevious = false;
        } else {
            this.searchTableDataPage.hasPrevious = true;
        }
        this.dataSource.sort = this.sort
        this.dataSource = populateSortDataAccessort(this.dataSource)
    }

    paginationFilter() {
        this.page = 0;
        this.jumpPageTo(0);
    }

    search(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.searchTerm = filterValue;
        this.jumpPageTo(0);
    }

    exportAccount() {
        this.load = true;
        let customerEntity =  this.accountFilterForm.get('entityName').value;
        let customerName = this.accountFilterForm.get('customerName').value;
        let managerName = this.accountFilterForm.get('accountManagerName').value;
        let arOwner = this.accountFilterForm.get('AROwnerName').value;
        let isTimeTrack = this.accountFilterForm.get('isTimeTrackingAvailable').value;
        this.listAccountService.exportAccountListing(managerName,arOwner, customerEntity, customerName, isTimeTrack).subscribe(searchMD => {
            saveAs(searchMD, 'Accounts.xlsx');
            setTimeout(() => {
                this.load = false;
            }, 1000);
        });
        setTimeout(() => {
            this.load = false;
        }, 1000);
    }
    goToAccountPage(accountCode) {
        this.commonService.accountCode = accountCode;
        this.router.navigate(['/view-account'], {skipLocationChange: true})
    }
}
