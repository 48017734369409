import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { enviornmentAperture } from 'environments/environment';
@Injectable()
export class AddAccountService {
  constructor(private http: HttpClient) {}

  getLocationAndAccountManagerList(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/accounts/initAddAccount');
  }

  saveAccountDetails(data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/accounts/createAccount', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  
  quickSearchOnlyActiveResource(searchValue): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/quickSearchActive?searchParam=' + searchValue);
  }

  searchResource(searchValue): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/quickSearch?searchParam=' + searchValue);
  }
  getMethodRequest(uri): Observable<any> {
    return this.http.get(uri, { responseType: 'text' as 'json' }).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
}
