<div class="row admin-update-leaves-container">
    <div class="col-12">
        <p class="col-9 pull-left leave-upload-heading mb-0 pl-3 pt-3">Confirm Action</p>
        <mat-icon class="col-3 mat-close-icon leave-upload-heading mb-0 p-4 pull-right" style="position: absolute;right: 1.5rem;top: -0.5rem;"
            (click)="closeDialog('noAction')">close</mat-icon>
    </div>
    <div class="col-12">
        <div class="row p-4 pull-right">
        <p class="pt-3 pl-3" *ngIf="data.type==='designationsData'; else elseTemplate">
         All records from file will overwrite the existing one. Are you sure to upload all practice details?</p>
        <ng-template #elseTemplate>
            <p class="pt-3 pl-3">
                All records from file will overwrite the existing one. Are you sure to upload Annual leave or All leave or Bulk Adjust leave.
            </p>
        </ng-template>
            <div class="row p-4 pull-right">
            <div class="col-6">
                <button mat-button class="pull-right btn btn-secondary" (click)="closeDialog('noAction')">Cancel</button>
            </div>
            <div class="col-6">
                <button mat-button class="pull-right btn btn-primary text-white" (click)="closeDialog('proceed')">Proceed</button>
            </div>
        </div>
    </div>
</div>