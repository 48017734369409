import {
  Directive,
  HostListener,
  ElementRef,
  OnInit,
  Input,
} from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Directive({ selector: "[currencyInput]" })
export class CurrencyInputDirective implements OnInit {
  @Input() currencyCode: string = "USD";
  @Input() maxDigits: number = undefined;
  @Input() maxDecimal: number = 2;

  private el: HTMLInputElement;
  private digitRegex: RegExp;
  private lastValid: string = "";

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: CurrencyPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.setRegex();
    this.formatCurrency();
  }

  private formatCurrency() {
    let value = this.el.value.replace(/[^0-9.]+/g, "");
    if (value) {
      if (this.currencyCode === "INR") {
        this.el.value = this.formatINR(value);
      } else {
        this.el.value = this.currencyPipe.transform(
          value,
          this.currencyCode,
          "",
          `1.${this.maxDecimal}-${this.maxDecimal}`
        );
      }
    }
  }

  private formatINR(value: string): string {
    let [integer, decimal] = value.split(".");
    decimal = decimal || "";

    if (integer.length > 3) {
      integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      let parts = integer.split(",");
      let lastPart = parts.pop();
      let remainingPart = parts.join("");

      if (remainingPart.length > 2) {
        remainingPart = remainingPart.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      }

      let formattedInteger = remainingPart + "," + lastPart;
      decimal = decimal.padEnd(this.maxDecimal, "0");
      return `${formattedInteger}.${decimal}`;
    } else {
      decimal = decimal.padEnd(this.maxDecimal, "0");
      return `${integer}.${decimal}`;
    }
  }

  private regexString() {
    const maxStr = this.maxDigits !== undefined ? `{0,${this.maxDigits}}` : `+`;
    return `^\\d${maxStr}(\\.\\d{0,${this.maxDecimal}})?$`;
  }

  private setRegex() {
    this.digitRegex = new RegExp(this.regexString());
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    this.el.value = value.replace(/[^0-9.]+/g, "");
    this.el.select();
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    this.formatCurrency();
  }

  @HostListener("input", ["$event"])
  onInput(event) {
    const cleanValue = event.target.value.replace(/[^0-9.]/g, "");

    if (this.digitRegex.test(cleanValue) || !cleanValue) {
      this.lastValid = cleanValue;
      this.el.value = cleanValue;
    } else {
      event.target.value = this.lastValid;
    }
  }

  @HostListener("keydown.control.z", ["$event.target.value"])
  onUndo(value) {
    this.el.value = "";
  }
}
