import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { enviornmentAperture } from 'environments/environment';
import { map } from 'rxjs/operators';
import { CommonDataService } from 'app/common-data.service';

let headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
@Injectable()
export class AllocationsService {
  constructor(private http: HttpClient, private commonSerice: CommonDataService) {}
  getSearchAllocations(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/allocations/searchAllocationsMetaData', {}).pipe(
      map((response: HttpResponse<any>) => {
          return response;
      })
  );
  }
  searchAllocationsListing(allAllocationDTO,pageNumber=0,pageSize=5): Observable<any> {
   
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/allocations?pageNumber='+pageNumber+'&pageSize='+pageSize, allAllocationDTO).pipe(
      map((response: HttpResponse<any>) => {
          return response;
      })
  )};
  exportAllocationsListing(tableDataParams): Observable<any> {
    let construct_uri = '';
    tableDataParams.forEach((element, i) => {
      let specialChar;
      i === 0 ? (specialChar = '?') : (specialChar = '&');
      construct_uri = construct_uri + specialChar + element.key + '=' + element.value;
    });
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/allocations/exportAllocations' + construct_uri, { responseType: 'blob' });
  }
  getMethodRequest(uri): Observable<any> {
    return this.http.get(uri, { responseType: 'text' as 'json' }).pipe(
      map((response: HttpResponse<any>) => {
          return response;
      })
  );
  }
  getAddAllocationMetadata(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/allocations/initAddAllocationPage', {}).pipe(
      map((response: HttpResponse<any>) => {
          return response;
      })
  );
  }
  getAllocationById(id): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/allocations/' + id, {}).pipe(
      map((response: HttpResponse<any>) => {
          return response;
      })
  );
  }
  getAllocationHistoryOfResource(tableDataParams,pageNumber=0,pageSize=5): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/allocations?pageNumber='+pageNumber+'&pageSize='+pageSize, tableDataParams).pipe(
      map((response: HttpResponse<any>) => {
          return response;
    }));
  }
  getSowById(uri): Observable<any> {
    return this.http.get(uri, {}).pipe(
      map((response: HttpResponse<any>) => {
          return response;
      })
  );
  }
  getPseudoResourceList(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/allocations/editAllocationPage');
  }
  fetchSowById(id): Observable<any> {
    return this.http
      .get(enviornmentAperture.apiUrl + 'api/v1/sows/' + id + '?sowResultType=DETAILS_INCLUDING_WBSES_AND_ALLOCATIONS', {})
      .pipe(
        map((response: HttpResponse<any>) => {
            return response;
        })
    );
  }

  updateAllocation(request, allocId): Observable<HttpResponse<any>> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/allocations/' + allocId, request, {}).pipe(
      map((response: HttpResponse<any>) => {
          return response;
      })
  );
  }
  addAllocation(data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/allocations/createAllocation', data, {}).pipe(
      map((response: HttpResponse<any>) => {
          return response;
      })
  );
  }
  resourceQuickSearch(searchValue): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/quickSearch?searchParam=' + searchValue);
  }

  quickSearchOnlyActiveResource(searchValue): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/quickSearchActive?searchParam=' + searchValue);
  }

  resourceInfo(code): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/summary/' + this.commonSerice.encrypt(code));
  }
  deleteAlloc(allocId): Observable<any> {
    return this.http.delete(enviornmentAperture.apiUrl + 'api/v1/allocations/' + allocId, {}).pipe(
      map((response: HttpResponse<any>) => {
          return response;
      })
  );
  }

  deleteAllocationHr(allocId): Observable<any> {
    return this.http.delete(enviornmentAperture.apiUrl + 'api/v1/allocations/deleteAllocationHr?id=' + allocId, {}).pipe(
      map((response: HttpResponse<any>) => {
          return response;
      })
  );
  }
  createBulkAllocation(payloadData): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/allocations/bulkAllocation',payloadData,{}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  /* HR allocations */

  addHRAllocation(payload): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/allocations/addAllocationHr',payload,{}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  getAllHRAllocations(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/allocations/getAllAllocationHr');
  }
  exportHRAllocations(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/allocations/exportHrAllocations',{responseType: 'blob'});
  }
  
  updatePositionInfo(request): Observable<HttpResponse<any>> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/additional/updatePositionInfo', request, {}).pipe(
      map((response: HttpResponse<any>) => {
          return response;
      })
  );
  }
}
