import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ProjectService } from 'app/projects/projects.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { TimesheetApprovalsService } from 'app/timesheet-approvals/timesheet-approvals.service';
export const DATE_FORMATE_REQUEST = 'yyyy-MM-dd';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { PickDateAdapter, PICK_FORMATS } from 'app/validators/validator';
import { CommonDataService } from 'app/common-data.service';
@Component({
    selector: 'jhi-export-timesheet',
    templateUrl: './export-timesheet.component.html',
    styleUrls: ['./export-timesheet.component.scss'],
    providers: [{ provide: DateAdapter, useClass: PickDateAdapter }, { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }]
})
export class ExportTimesheetComponent implements OnInit, OnDestroy {
    public resourceSelectSearchFilterCtrl: FormControl = new FormControl();
    public projectSelectSearchFilterCtrl: FormControl = new FormControl();
    projectselectedOptions= ['all'];
    resourceselectedOptions= ['all'];
    accountselectedOptions = ['all'];
    public accountSelectSearchFilterCtrl: FormControl = new FormControl();
    public resourceListFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public projectListFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public accountListFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    protected _onDestroy = new Subject<void>();
    @ViewChild('singleSelect') singleSelect: MatSelect;
    exportReportForm: FormGroup;
    allResourceList: any;
    query = '';
    projectQuery = '';
    accountQuery = '';
    exportResource: any;
    projectList: any[];
    exportProjectList: any[];
    projectSelectedExport: any;
    accountList: any[];
    exportAccountList: any[];
    accountSelectedExport: any[];
    tableDataParams: any[];
    resourceSelectedExport: any;
    fromDateExport: any;
    tillDateExport: any;
    mintillDate: any;
    tillDateStatus = true;
    isLoading: boolean = false;
    selectedEmploymentType: string;
    selectedResourceType: string;
    tillDateStatusSelected: boolean;
    datePipe = new DatePipe('en-US');
    accountselectedOption = ['all'];
    projectselectedOption = ['all'];;
    resourceselectedOption = ['all'];
    statusSelectedOption = 'all';
    exportStatus = [
        { code: 'APPROVED', value: 'Approved', description: 'Approved Timesheet' },
        { code: 'REJECTED', value: 'Rejected', description: 'Rejected Timesheet' },
        { code: 'SAVED', value: 'Saved', description: 'Saved Timesheet' },
        { code: 'SUBMITTED', value: 'Submitted', description: 'Submitted Timesheet' },
        { code: 'PENDING', value: 'Pending', description: 'Pending for Submission' }
    ];
    maxtilldate: any;
    allEmployeeTypes = [
        {key:'All',value: 'All'},
        {key:'FTE',value: 'Full-time Employee'},
        {key:'DC',value: 'Direct Contractor'},
        {key:'SCH',value:'Sub Contractor Hourly'},
        {key:'SCM',value:'Sub Contractor Monthly'},
        {key:'FTEH',value: 'Full-time Employee Hourly'},
        {key:'FTEA',value: 'Full-time Employee Annual'},    
    ];
    offShoreEmployeeTypes = [
        {key:'All',value: 'All'},
        {key:'FTE',value: 'Full-time Employee'},
        {key:'DC',value: 'Direct Contractor'},
        {key:'SCH',value:'Sub Contractor Hourly'},
        {key:'SCM',value:'Sub Contractor Monthly'}
    ];
    onShoreEmployeeTypes = [
        {key:'All',value: 'All'},
        {key:'FTEH',value: 'Full-time Employee Hourly'},
        {key:'FTEA',value: 'Full-time Employee Annual'},
        {key:'DC',value: 'Direct Contractor'},
        {key:'SCH',value:'Sub Contractor Hourly'}
    ];


    constructor(
        private titleService: Title,
        private timesheetApprovalService: TimesheetApprovalsService,
        private projectService: ProjectService,
        private fb: FormBuilder,
        private router: Router,
        private commonService: CommonDataService
    ) {
        this.titleService.setTitle('aperture - Export Timesheet');
        this.exportReportForm = this.fb.group({
            ExportResource: new FormControl('', []),
            ExportProject: new FormControl('', []),
            ExportAccount: new FormControl('', []),
            ExportResourceType: new FormControl('', []),
            ExportEmploymentType: new FormControl('', []),
            tillDateExport: new FormControl({
                value: ''
            }, []),
            fromDateExport: new FormControl('', [])
        });
    }
    ngOnInit() {
        this.selectedEmploymentType = 'All';
        this.selectedResourceType = 'All';
        this.projectService.initAddProject().subscribe(projectsData => {
            this.allResourceList = projectsData.resourceManagerList;
            this.exportResource = projectsData.resourceManagerList;
            this.projectList = projectsData.projectList;
            this.accountList = projectsData.accountList;
            this.exportAccountList = projectsData.accountList;
            this.exportProjectList = projectsData.projectList;
            let accSort = this.exportAccountList.sort((a, b) => (a.value > b.value) ? 1 : ((a.value < b.value) ? -1 : 0));
            let proSort = this.exportProjectList.sort((a, b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0));
            let resourceSort = this.exportResource.sort((a, b) => (a.value > b.value) ? 1 : ((a.value < b.value) ? -1 : 0));
            let accountsArray: any = [];
            for (let i = 0; i < this.projectList.length; i++) {
                for (let j = 0; j < projectsData.accountList.length; j++) {
                    if (projectsData.accountList[j].value === this.projectList[i].account.value) {
                        if (accountsArray.findIndex(x => x.value === projectsData.accountList[j].value) < 0) {
                            accountsArray.push(projectsData.accountList[j]);
                        }
                    }
                }
            }
            if (Array.isArray(this.exportAccountList) && Array.isArray(this.exportProjectList) && Array.isArray(this.exportResource)) {
                this.exportAccountList.unshift({ key: 'all', value: 'All' });
                this.exportProjectList.unshift({ code: 'all', name: 'All', account: { key: '', value: '' } });
                this.exportResource.unshift({ key: 'all', value: 'All' });
                this.projectselectedOption = this.exportProjectList[0];
                this.resourceselectedOption = this.exportResource[0];
            }
            this.projectListFiltered.next(this.exportProjectList.slice());
            // listen for search field value changes
            this.projectSelectSearchFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.projectFilterList();
                });
            this.projectListFiltered
                .pipe(take(1), takeUntil(this._onDestroy))
                .subscribe(() => {
                    if (this.singleSelect) {
                        this.singleSelect.compareWith = (a: any, b: any) => {
                            return a && b && a === b;
                        };
                    }
                });
            this.accountListFiltered.next(this.exportAccountList.slice());
            // listen for search field value changes
            this.accountSelectSearchFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.accountFilterList();
                });
            this.accountListFiltered
                .pipe(take(1), takeUntil(this._onDestroy))
                .subscribe(() => {
                    if (this.singleSelect) {
                        this.singleSelect.compareWith = (a: any, b: any) => {
                            return a && b && a === b;
                        };
                    }
                });
            this.resourceListFiltered.next(this.exportResource.slice());
            // listen for search field value changes
            this.resourceSelectSearchFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.resourceFilterList();
                });
            this.resourceListFiltered
                .pipe(take(1), takeUntil(this._onDestroy))
                .subscribe(() => {
                    if (this.singleSelect) {
                        this.singleSelect.compareWith = (a: any, b: any) => {
                            return a && b && a === b;
                        };
                    }
                });
        });
    }

    searchResource(event) {
        if (event.keyCode >= 65 && event.keyCode <= 90) {
            this.query = event.target.value;
        }

        if (event.keyCode === 32) {
            this.query += ' ';
            event.stopImmediatePropagation();
        }

        if (event.keyCode === 8) {
            this.query = this.query.substring(0, this.query.length - 1);
        }
        if (!this.query) {
            this.exportResource = this.allResourceList;
            return;
        }
        let result: string[] = [];
        for (let list of this.allResourceList) {
            if (list.value) {
                if (
                    list.value.toLowerCase().indexOf(this.query.toLowerCase()) > -1
                ) {
                    result.push(list);
                }
            } else {
                if (list.toLowerCase().indexOf(this.query.toLowerCase()) > -1) {
                    result.push(list);
                }
            }
        }
        if (result && result.length) {
            this.exportResource = result;
        }
    }

    resourceSelected(event) {
        this.resourceSelectedExport = event.value.key;
        this.query = '';
    }
   
    searchAccount(event) {
        if (event.keyCode >= 65 && event.keyCode <= 90) {
            this.accountQuery += event.key;
        }

        if (event.keyCode === 32) {
            this.accountQuery += ' ';
            event.stopImmediatePropagation();
        }

        if (event.keyCode === 8) {
            this.accountQuery = this.accountQuery.substring(0, this.accountQuery.length - 1);
        }
        if (!this.accountQuery) {
            this.exportAccountList = this.accountList;
            return;
        }
        let result: string[] = [];
        for (let list of this.accountList) {
            if (list.value) {
                if (
                    list.value.toLowerCase().indexOf(this.accountQuery.toLowerCase()) > -1
                ) {
                    result.push(list);
                }
            } else {
                if (list.toLowerCase().indexOf(this.accountQuery.toLowerCase()) > -1) {
                    result.push(list);
                }
            }
        }
        if (result && result.length) {
            this.exportAccountList = result;
        }
    }

    accountSelected(event) {
        this.exportProjectList = [];
        this.projectSelectedExport = '';
        this.resourceSelectedExport = '';
        this.projectselectedOption = ['all'];
        if (event.value !== 'all') {
            this.exportProjectList.unshift({ code: 'all', name: 'All', account: { key: '', value: '' } });
        }
        this.projectListFiltered.next(this.exportProjectList.slice());
        this.accountselectedOption = event.value;
        this.projectselectedOption = this.exportProjectList[0];
        this.resourceselectedOption = this.exportResource[0];
        this.statusSelectedOption = 'all';
        if (event.value[0] === 'all' || event.value.length === 0) {
            this.projectList.forEach(x => {
                if (x.code !== 'all') {
                    this.exportProjectList.push(x);
                }
            });
            this.projectselectedOption = this.exportProjectList[0];
            this.projectListFiltered.next(this.exportProjectList.slice());
            this.accountSelectedExport = [''];
        }
        if (event.value && event.value.length > 0) {
            this.projectList.forEach(x => {
                event.value.forEach((item) => {
                    if (x.account.key === item) {
                        this.exportProjectList.push(x);
                        this.projectListFiltered.next(this.exportProjectList.slice());
                    }
                })
            });
        }
        let result: string[] = [];
        for (let list of this.accountList) {
            if (list.value && event.value[0] !== 'all') {
                this.accountSelectedExport = event.value.join();
            }
        }
        
        this.accountQuery = '';
    }

    searchProject(event) {
        if (event.keyCode >= 65 && event.keyCode <= 90) {
            this.projectQuery += event.key;
        }

        if (event.keyCode === 32) {
            this.projectQuery += ' ';
            event.stopImmediatePropagation();
        }

        if (event.keyCode === 8) {
            this.projectQuery = this.projectQuery.substring(0, this.projectQuery.length - 1);
        }
        if (!this.projectQuery && this.accountselectedOption) {
            this.exportProjectList = this.projectList;
            return;
        }
        let result: string[] = [];
        for (let list of this.projectList) {
            if (list.name) {
                if (
                    list.name.toLowerCase().indexOf(this.projectQuery.toLowerCase()) > -1
                ) {
                    result.push(list);
                }
            } else {
                if (list.toLowerCase().indexOf(this.projectQuery.toLowerCase()) > -1) {
                    result.push(list);
                }
            }
        }
        if (result && result.length) {
            this.exportProjectList = result;
        }
    }

    projectSelected(event) {
        if (event.value.code === 'all') {
            this.projectselectedOption = this.exportProjectList[0];
            this.projectSelectedExport = '';
        } else {
            this.exportProjectList = [];
            this.projectselectedOption = [event.value.code];
            this.projectSelectedExport = event.value.code;
           // this.accountSelectedExport = [event.value.account.key];
            this.exportProjectList.unshift({ code: 'all', name: 'All', account: { key: '', value: '' } });
            this.projectListFiltered.next(this.exportProjectList.slice());
            if (event.value.account.value && event.value.account.value.length > 0) {
                this.projectList.forEach(x => {
                    if (x.account.value === event.value.account.value) {
                        this.exportProjectList.unshift(x);
                    }
                });
                this.projectListFiltered.next(this.exportProjectList.slice());
            }
        }
        this.projectQuery = '';
    }




    fromDateChange(event) {
        this.fromDateExport = this.datePipe.transform(this.exportReportForm.get('fromDateExport').value, DATE_FORMATE_REQUEST);
        this.tillDateStatus = false;
        if (this.exportReportForm.get('tillDateExport').value && (this.exportReportForm.get('tillDateExport').value.value === '' || this.exportReportForm.get('tillDateExport').value === null)) {
            this.tillDateStatusSelected = true;
        }
        if (this.exportReportForm.get('tillDateExport').value === null) {
            this.tillDateStatusSelected = true;
        }
        this.mintillDate = (this.exportReportForm.get('fromDateExport').value);
        let maxdate = new Date(this.exportReportForm.get('fromDateExport').value).getDate();
        this.maxtilldate = new Date(this.exportReportForm.get('fromDateExport').value);
        this.maxtilldate.setDate(maxdate + 30);
    }

    tillDateChange(event) {
        this.tillDateExport = this.datePipe.transform(this.exportReportForm.get('tillDateExport').value, DATE_FORMATE_REQUEST);
        this.tillDateStatusSelected = false;
    }

    exportReport() {
        this.isLoading = true;
        this.tableDataParams = [];
        if(this.resourceSelectedExport) {
            this.tableDataParams.push({ key: 'resource', value: this.commonService.encrypt(this.resourceSelectedExport) });
        }
        if (this.projectselectedOptions[0] !== 'all' || this.projectselectedOptions.length >= 2) {
            this.tableDataParams.push({ key: 'project', value: this.projectselectedOptions });
        }
        if (this.accountselectedOptions[0] !== 'all' || this.accountselectedOptions.length >= 2) {
            this.tableDataParams.push({ key: 'account', value: this.accountselectedOptions });
        }
        if (this.exportReportForm.get('fromDateExport').value) {
            this.tableDataParams.push({ key: 'fromDate', value: this.fromDateExport });
        }
        if (this.exportReportForm.get('ExportEmploymentType').value !== 'All') {
            this.tableDataParams.push({ key: 'empType', value: this.exportReportForm.get('ExportEmploymentType').value });
        }

        if (this.exportReportForm.get('ExportResourceType').value !== 'All') {
            this.tableDataParams.push({ key: 'resourceType', value: this.exportReportForm.get('ExportResourceType').value });
        }
        if (this.exportReportForm.get('tillDateExport').value !== null && !this.tillDateStatus) {
            this.tableDataParams.push({ key: 'tillDate', value: this.tillDateExport });
        }
        if (this.statusSelectedOption && this.statusSelectedOption !== 'all' && this.statusSelectedOption.length) {
            this.tableDataParams.push({ key: 'status', value: this.statusSelectedOption });
        }
        if (this.resourceselectedOptions[0] !== 'all' || this.resourceselectedOptions.length >= 2) {  
            let resourceArrayToSend = this.resourceselectedOptions.map(item => {
                return this.commonService.encrypt(item);
            })
            this.tableDataParams.push({key: 'resource', value: resourceArrayToSend});
        }
        
        this.timesheetApprovalService.export(this.tableDataParams).subscribe(data => {
            saveAs(data, 'Timesheet Report.csv');
            setTimeout(() => {
                this.isLoading = false;
            }, 500);
            this.isLoading = false;
        });
       
    }
    resetexportReport() {
        this.exportReportForm.reset();
        this.exportReportForm.get('ExportEmploymentType').setValue('All');
        this.exportReportForm.get('ExportResourceType').setValue('All');
        this.statusSelectedOption = 'all';
        this.accountSelectSearchFilterCtrl.reset();
        this.projectSelectSearchFilterCtrl.reset();
        this.resourceSelectSearchFilterCtrl.reset();
        this.accountselectedOptions = ['all'];
        this.resourceselectedOptions = ['all'];
        this.projectselectedOptions =['all'];
    }
    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    protected projectFilterList() {
        if (!this.exportProjectList) {
            return;
        }
        // get the search keyword
        let search = this.projectSelectSearchFilterCtrl.value;
        if (!search) {
            this.projectListFiltered.next(this.exportProjectList.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the list
        this.projectListFiltered.next(
            this.exportProjectList.filter(obj => obj.name.toLowerCase().indexOf(search) > -1)
        );
    }
    protected resourceFilterList() {
        if (!this.exportResource) {
            return;
        }
        // get the search keyword
        let search = this.resourceSelectSearchFilterCtrl.value;
        if (!search) {
            this.resourceListFiltered.next(this.exportResource.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the list
        this.resourceListFiltered.next(
            this.exportResource.filter(obj => obj.value.toLowerCase().indexOf(search) > -1)
        );
    }
    protected accountFilterList() {
        if (!this.exportAccountList) {
            return;
        }
        // get the search keyword
        let search = this.accountSelectSearchFilterCtrl.value;
        if (!search) {
            this.accountListFiltered.next(this.exportAccountList.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the list
        this.accountListFiltered.next(
            this.exportAccountList.filter(obj => obj.value.toLowerCase().indexOf(search) > -1)
        );
    }
}
