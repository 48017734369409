import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { ActivatedRoute } from '@angular/router';
import { VERSION } from 'app/app.constants';
import { Principal, LoginService, Account } from 'app/core';
import { ProfileService } from '../profiles/profile.service';
import { REDIRECT_URL } from 'app/app.constants';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import * as moment from 'moment';
// import { environmentLMS } from '../../../../../ReferralPaths/enviornment';
import { MatDialog } from '@angular/material/dialog';
import { DownloadAppDialogComponent } from 'app/download-app-dialog/download-app-dialog.component';
import { MasterDatabaseService } from 'app/master-database/master-database.service';
import { ResourceService } from 'app/resource/resource.service';
import { CommonDataService } from 'app/common-data.service';
import { environmentLMS, enviornmentAperture } from 'environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'jhi-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
    inProduction: boolean;
    isNavbarCollapsed: boolean;
    languages: any[];
    swaggerEnabled = true;
    version: string;
    account: Account;
    imageUrl: any;
    resourceCode: any;
    currentTime = new Date();
    interval: any;
    appVersion: string;
    PBRedirect: any;
    userInteracted = false;

    constructor(
        private loginService: LoginService,
        private principal: Principal,
        private profileService: ProfileService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private masterDatabaseService:MasterDatabaseService,
        public ResourceService: ResourceService,
        public commonService: CommonDataService,
        @Inject(DOCUMENT) private document: any
    ) {
        this.version = VERSION ? 'v' + VERSION : '';
        this.isNavbarCollapsed = true;
        this.profileService.profilePicChanged.subscribe((data: any) => {
            this.imageUrl = data;
        });
    }

    triggerTinmesheetNotification() {
    }

    playAudio() {
        let audio = new Audio();
        audio.src = "notification.ogg";
        audio.oncanplaythrough = (event) => {
            audio.play();
        }
    }

    ngOnInit() {
        this.principal.identity().then(account => { 
            if(account){
            sessionStorage.setItem('resourceCode', account.resourceCode);
            this.account = account;
            this.resourceCode = account.resourceCode;
            if(this.account.imageUrl){
            this.profileService.getResourceImage(this.account.imageUrl).subscribe(imageDetails => {
                this.imageUrl = imageDetails;
                this.profileService.profileImageUrl = imageDetails;
            });
           } 
          }
        });

        this.interval = setInterval(() => {
            this.currentTime = new Date();
            let isFriday = moment().day() === 5;
            if ((isFriday && moment().isSameOrAfter({ hour: 15, minute: 30, second: 0 })) && localStorage.getItem('showNotification') != 'yes' && localStorage.getItem('showNotification') != 'no') {
                localStorage.getItem('showNotification') != 'no' ? localStorage.setItem('showNotification', 'yes') : '';
                this.displayNotification()
            }
        }, 1000);
        this.getAppVersion();
    }

    displayNotification() {
        if (localStorage.getItem('showNotification') != 'no') {
            let snackbar = this.snackBar.openFromComponent(TimesheetNotificationComponent, {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                data: {
                    name: this.account ? `${this.account.firstName} ${this.account.lastName}` : '',
                    close: () => {
                        localStorage.setItem('showNotification', 'no');
                        snackbar.dismiss()
                    },
                    redirect: () => {
                        localStorage.setItem('showNotification', 'no');
                        snackbar.dismiss()
                    }
                }
            },);
            if (!localStorage.getItem('played')) {
                this.playAudio();
                // localStorage.setItem('played', 'yes');
            }
        }
    }

    redirectToPreboarding() {
        location.href = enviornmentAperture.pbURL;
    }

    redirectToLMSApi () {
        location.href = environmentLMS.apiUrl+"/swagger-ui/index.html";
    }

    redirectToApertureApi () {
        location.href = enviornmentAperture.apiUrl+"swagger-ui/index.html";
    }

    ngOnDestroy() {
        localStorage.removeItem('showNotification');
        clearInterval(this.interval);
    }

    collapseNavbar() {
        this.isNavbarCollapsed = true;
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }

    login() {
        this.loginService.login();
    }

    logout() {
        this.collapseNavbar();
        this.document.location.href = enviornmentAperture.keycloakURL + '/realms/aperture/protocol/openid-connect/logout';
        this.account.firstName = 'UserName';
    }

    toggleNavbar() {
        this.isNavbarCollapsed = !this.isNavbarCollapsed;
    }

    getImageUrl() {
        return this.isAuthenticated() ? this.principal.getImageUrl() : null;
    }

    getAppVersion() {
        this.profileService.getAppVersion().subscribe(version => {
            this.appVersion = version;
        });
    }
    openMobileAppDialog() {
        let dialogRef = this.dialog.open(DownloadAppDialogComponent, {
            panelClass: 'mobile-app-modal'
        });
    }
    downloadReports() {
        this.masterDatabaseService.exportSkills().subscribe(data =>{
            saveAs(data,'Skills.xlsx')
        });
    }
    downloadPracticeMap() {
        this.masterDatabaseService.exportPracticeMap().subscribe(data =>{
            saveAs(data,'PracticeHierarchyReportDetails.csv')
        });
    }
    goToMyProfile(resourceCode) {
        this.commonService.resourceCode = resourceCode;
        this.router.navigate(['/resource/view-resource'], {skipLocationChange: true})
    }
}
@Component({
    selector: 'timesheet-notification',
    template: `<div class="timesheet-notification">
    <p><span style="font-size: 18px">Hi <span style="color: #007bff; text-transform: capitalize">{{data.name}},</span></span> <br /> It’s Friday! Have you submitted your timesheet? <br/></p>
    <div>
    <mat-icon class="cross-icon" (click)="data.close()">close</mat-icon>
    </div>
    <div class="bottom-actions">
    <p class="ok-text" (click)="data.redirect()">Ok</p>
    </div>
    </div>`,
    styleUrls: ['./navbar.css']
})
export class TimesheetNotificationComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}