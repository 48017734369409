<div class="add-account">
    <h2 id='main-heading'>Add New Account</h2>
    <mat-card class="example-card">
        <form [formGroup]="accountForm" novalidate autocomplete="off">
            <mat-card-title>
                <b class="account-heading">Please enter the details below</b>
                </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <div class="col-sm-5">
						<p>
							<mat-form-field>
								<mat-select placeholder="Select GSPANN Entity" formControlName="gspannEntity">
									<mat-option *ngFor="let companyEntity of gspannEntityList" [value]="companyEntity.value">{{companyEntity.value}}</mat-option>
								</mat-select>
								<mat-error *ngIf="accountForm.get('gspannEntity').hasError('required')">This is a required field.</mat-error>
							</mat-form-field>
						</p>

                        <p>
                            <mat-form-field>
                                <input matInput placeholder="Select or Enter Customer Group" [matAutocomplete]="auto"
                                    [(ngModel)]="customerGroup" maxlength="4" jhiUpperCase
                                    (keyup)="searchCustomerGroup($event.target.value)" formControlName="customerGroup"
                                    required>
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let customerGroup of customerGroupList" [value]="customerGroup">
                                        {{customerGroup}}</mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="accountForm.get('customerGroup').hasError('required')">This is
                                    required field</mat-error>
                                <mat-error *ngIf="accountForm.get('customerGroup').hasError('max')">Customer Group Must
                                    be less than four characters</mat-error>
                                <mat-error *ngIf="accountForm.get('customerGroup').hasError('pattern')">Allowed
                                    characters are alphabates and '+' </mat-error>
                            </mat-form-field>
                        </p>

                        <p>
                            <mat-form-field>
                                <input matInput placeholder="Customer Name" formControlName="customerName"
                                    [(ngModel)]="customerName" required>
                                <mat-error *ngIf="accountForm.get('customerName').hasError('required')">This is
                                    required field</mat-error>
                            </mat-form-field>
                        </p>

                        <p>
                            <mat-form-field>
                                <input matInput placeholder="Customer Entity" formControlName="customerEntity"
                                    [(ngModel)]="customerEntity" required>
                                <mat-error *ngIf="accountForm.get('customerEntity').hasError('required')">This is
                                    required field</mat-error>
                            </mat-form-field>
                        </p>

                        <p>
                            <mat-form-field>
                                <input matInput placeholder="Customer Contact Person"
                                    formControlName="customerReportingManager" [(ngModel)]="customerReportingManager"
                                    required>
                                <mat-error *ngIf="accountForm.get('customerReportingManager').hasError('required')">This
                                    is required field</mat-error>
                            </mat-form-field>
                        </p>

                        <p>
                            <mat-form-field class="example-full-width search-full-width">
                                <span matPrefix>
                                    <mat-icon matSuffix>search</mat-icon>&nbsp;&nbsp;
                                </span>
                                <input matInput
                                    placeholder="Search Manager (eg. type resource id, resource name, email-id, technology etc.)"
                                    aria-label="State" [matAutocomplete]="auto1"
                                    (input)="searchResourceManager($event.target.value)" formControlName="accountManager"
                                    required>
                                <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"
                                    (optionSelected)="onResourceSelection($event)">
                                    <mat-option matTooltip="{{resource.designation}}" class="resourceList" *ngFor="let resource of resourcesList"
                                        [value]="resource">
                                        <div *ngIf="resource.showResource" class="row">
                                            <div class="col-sm-1">
                                                <img class="example-option-img" *ngIf="resource.image;else defaultImage1" aria-hidden src="{{resource.image}}" height="25" />
                                                <ng-template #defaultImage1>
                                                    <img class="example-option-img" aria-hidden src="../../assets/User_Profile_Pic.png" height="25" />
                                                </ng-template>
                                            </div>
                                            <div class="col-sm-2">
                                                <span>{{resource.code}}</span>
                                            </div>
                                            <div class="col-sm-5 res-name">
                                                <span>{{resource.name}}</span>
                                            </div>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="accountForm.get('accountManager').hasError('required')">This is required field</mat-error>
                                <mat-error *ngIf="accountForm.get('accountManager').hasError('incorrect')">Select a value from the list.</mat-error>
                            </mat-form-field>
                        </p>

                        <p>
                            <mat-form-field class="example-full-width">
                                <input type="text" placeholder="Country" aria-label="Number" matInput formControlName="country" [matAutocomplete]="countryAuto">
                                <mat-autocomplete autoActiveFirstOption #countryAuto="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngFor="let list of filteredCountries" [value]="list">
                                        {{list.value}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="accountForm.get('country').hasError('required')">This is required field</mat-error>
                                <mat-error *ngIf="accountForm.get('country').errors && accountForm.get('country').errors.noresult">No search results found</mat-error>
                            </mat-form-field>
                        </p>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                        <p>
                            <mat-form-field>
                                <input matInput placeholder="Contact Person's Email" formControlName="customerEmailId" [(ngModel)]="customerEmailId" pattern="^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$" required>
                                <mat-error *ngIf="accountForm.get('customerEmailId').hasError('required')">This is required field</mat-error>
                                <mat-error *ngIf="accountForm.get('customerEmailId').errors?.pattern">Please check the email format</mat-error>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field>
                                <input matInput id="phone" class="contactnumber" placeholder="Contact Person's Mobile No." formControlName="customerContactNumber" [(ngModel)]="customerContactNumber" (keypress)="validateNumber($event)" [minlength]="10" [maxlength]="10" pattern="([0-9]+)" required>
                                <mat-error *ngIf="accountForm.get('customerContactNumber').hasError('required')">This is required field</mat-error>
                                <mat-error *ngIf="accountForm.get('customerContactNumber').errors?.pattern">Mobile number format is wrong
                                </mat-error>
                                <script src="./../../../../node_modules/intl-tel-input/build/js/intlTelInput.js"></script>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-checkbox class="example-margin" [(ngModel)]="checked" [ngModelOptions]="{standalone:true}" (change)="checkstate($event)">Mutual Termination</mat-checkbox>
                        </p>
                        <p>
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker" placeholder="MSA Start Date" (dateChange)="startDateChange($event)" formControlName="addMsaStartDate" readonly="true" required>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="accountForm.get('addMsaStartDate').hasError('required')">This is a required field.</mat-error>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker1" placeholder="MSA End Date" formControlName="addMsaEndDate" readonly="true" [min]="minEndDate" required>
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                                <mat-error *ngIf="accountForm.get('addMsaEndDate').hasError('required')">This is a required field.</mat-error>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field class="example-full-width search-full-width">
                                <span matPrefix>
                                    <mat-icon matSuffix>search</mat-icon>&nbsp;&nbsp;
                                </span>
                                <input matInput
                                    placeholder="Search AR Owner (eg. type resource id, resource name, email-id)"
                                    aria-label="State" [matAutocomplete]="auto2"
                                    (keyup)="searchResourceARmanager($event.target.value)" formControlName="armanager"
                                    required>
                                <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete"
                                    (optionSelected)="onResourceSelectionAR($event)">
                                    <mat-option matTooltip="{{resource.designation}}" class="resourceList" *ngFor="let resource of resourcesList"
                                        [value]="resource">
                                        <div *ngIf="resource.showResource" class="row">
                                            <div class="col-sm-1">
                                                <img class="example-option-img" *ngIf="resource.image;else defaultImage1" aria-hidden src="{{resource.image}}" height="25">
                                                <ng-template #defaultImage1>
                                                    <img class="example-option-img" aria-hidden src="../../assets/User_Profile_Pic.png" height="25">
                                                </ng-template>
                                            </div>
                                            <div class="col-sm-2">
                                                <span>{{resource.code}}</span>
                                            </div>
                                            <div class="col-sm-5 res-name">
                                                <span>{{resource.name}}</span>
                                            </div>
                                          
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="accountForm.get('armanager').hasError('required')">This is required field</mat-error>
                                <mat-error *ngIf="accountForm.get('armanager').hasError('incorrect')"> Select a value from the list.</mat-error>
                            </mat-form-field>
                        </p>
                        <p>
                            <label class="time-tracking-label">Time Tracking System Available</label>
                            <mat-radio-group [(ngModel)]="isTimeTrackingAvailable" formControlName="timeTracking">
                                <mat-radio-button value="1" [checked]="true">Yes</mat-radio-button>
                                <mat-radio-button value="0">NO</mat-radio-button>
                            </mat-radio-group>
                        </p>
                        <div id="upload_button">
                            <label>
                                <input #fileInput type="file" (change)="changeListener($event)" (click)="fileInput.value = null" accept=".png,.jpeg,.jpg,.pdf,.xlsx,.xls,.zip,.docx,.doc" multiple />
                                <span class="btn btn-sm btn-outline-primary label-attach" mat-raised-button>
                                    <i class="fa fa-plus-circle" aria-hidden="true"></i> Attach NDA/MSA document
                                </span>

                            </label>
                            <mat-error *ngIf="resumeSizeFlag">File size is exeeded to 10MB. Please choose a file below 10MB</mat-error>
                            <mat-error *ngIf="resumeLengthFlag">File name is too long. File name should be less than 100 characters</mat-error>
                            <mat-error *ngIf="incorrectFileFormat">Please select file of .png,.jpeg,.jpg,.pdf,.xlsx,.xls,.zip,.docx type
                            </mat-error>
                        </div>
                        <div class="col-md-12" id="upload_document" *ngIf="resumeData.length">
                            <section class="scroll">
                                <ul class="list-group list-group-vertical">
                                    <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let name of resumeNameList; let i = index;">{{name}}
                                        <span class="badge badge-danger badge-pill"><i class="fas fa-times-circle" style="color:#ffffff; size:40px;" (click)="removeResume(i)">
                                        </i>
                                        </span>
                                    </li>
                                </ul>
                            </section>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions>
					<button mat-raised-button color="primary" (click)="openConfirmDialog()" [disabled]="accountForm.invalid || clicked">Create Account</button>
                <button type="button" mat-raised-button (click)="reset();">
                    <mat-icon>refresh</mat-icon>Reset All
                </button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>
<div class="dark-overlay" *ngIf="load">
    <mat-spinner class="loader" strokeWidth="3" [diameter]="50"></mat-spinner>
</div>