import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { UploadLeaveConfimationModalComponent } from 'app/leave-management-system/upload-leave-confimation-modal/upload-leave-confimation-modal.component';
import { ResourceService } from 'app/resource/resource.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-designations-upload',
  templateUrl: './designations-upload.component.html',
  styleUrls: ['./designations-upload.component.scss']
})
export class DesignationsUploadComponent implements OnInit {
  
  uploadLeaveDataForm: FormGroup;
  uploadAnnualLeaveDataForm: FormGroup;
  load: boolean;
  fileToUpload: File;
  fileToUploadAnnual: File;
  displayFileName: string;
  displayFileNameAnnual: String;
  isFileSelected: boolean;
  isAnnualFileSelected: boolean;
  isFileUpdateSuccess: boolean = false;
  showFileTypeError: boolean;
  incorrectFileFormatLeave: boolean;
  incorrectFileFormatAnnual: boolean;
  

  constructor(
    private titleService: Title,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private resourceService: ResourceService 
  ) { 
    this.titleService.setTitle('Aperture - Admin Upload Leave Data');
    this.uploadLeaveDataForm = this.fb.group({
      newFileUpload: new FormControl('', [Validators.required]),
    });
    this.uploadAnnualLeaveDataForm = this.fb.group({
      annualLeaveExcel: new FormControl('', [Validators.required]),
    })
  }

  ngOnInit(): void {
  }

  handleFileInput(event) {
		this.fileToUpload = <File>event.target.files[0];
    if ( this.fileToUpload.type ==='text/csv') {
      this.incorrectFileFormatLeave = false;
      // return;
    } else {
      this.incorrectFileFormatLeave = true;
      this.displayFileName = '';
		  this.isFileSelected = false;  
      return;
    }
		this.displayFileName = this.fileToUpload.name;
		this.isFileSelected = true;
	}

  annualFileInputHandler(event) {
		this.fileToUploadAnnual = <File>event.target.files[0];
    if (this.fileToUploadAnnual.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || this.fileToUploadAnnual.type === 'application/vnd.ms-excel' || this.fileToUploadAnnual.type === 'application/excel' || this.fileToUploadAnnual.type === 'application/x-excel' || this.fileToUploadAnnual.type === 'application/x-msexcel') {
      this.incorrectFileFormatAnnual = false;
      // return;
  } else {
      this.incorrectFileFormatAnnual = true;
      this.displayFileNameAnnual = '';
      this.isAnnualFileSelected = false;
      return;
  }
		this.displayFileNameAnnual = this.fileToUploadAnnual.name;
		this.isAnnualFileSelected = true;
	}

  removeFile() {
		this.fileToUpload = null;
		this.isFileSelected = false;
		this.displayFileName = '';
	}

  removeAnnualFile() {
    this.fileToUploadAnnual = null;
		this.isAnnualFileSelected = false;
    this.displayFileNameAnnual = ''
	}

  uploadFileData(type) {
    this.showFileTypeError = false;
    const dialogRef = this.dialog.open(UploadLeaveConfimationModalComponent, {
      width: '45%',
      maxWidth: 'none',
      data: {
        type: type,
      },
          panelClass: 'upload-leave-confirmation-modal'
    });
    dialogRef.afterClosed().subscribe(response=> {
      if (response === 'proceed') {
        this.submitRequest(type);
      }
    });
}

  submitRequest (type) {
    if (type === 'designationsData') {
      let payload = {
        file: this.fileToUpload ? this.fileToUpload : null, 
      }
        this.load = true;
         this.resourceService.uploadLeaveData(payload).subscribe(
          (response => {
          saveAs(response, ('FailedDesignationsReport.xlsx').replace(/\s+/g, ''));
          console.log("response",response,response.size);
          this.load = false;
          this.uploadLeaveDataForm.reset();
          this.removeFile()
          this.showFileTypeError = false;
          this.isFileUpdateSuccess= true;
        }),
        (err => {
          this.load = false;
          this.uploadLeaveDataForm.reset();
          this.removeFile()
        })); 
    }  
  }

  closeErrorAndReset() {
    this.showFileTypeError = false;
    this.removeFile()
  }

  closeStatusMessage() {
    this.isFileUpdateSuccess = false;
  }




}
