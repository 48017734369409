<div class="col-md-4 col-sm-12" *ngIf="monthlyPending">    
    <p class="group monthly-view monthly-pending-since" aria-label="ToolTip">
        <jhi-pending-component [month]="monthObj.currentMonth" [dateRange]="dateRange" [monthPendingSince]="monthPendingSince"
            (onDatePicked)="routeWeekTS($event)">
        </jhi-pending-component>
    </p>
</div>
<mat-card class="main-container color-code mat-card " id="prevTSView">
    <div class="row monthly-view-display" *ngIf="!loading">
        <div class="col-md-2 col-sm-12 ">
            <p class="group" aria-label="ToolTip" style="padding: 0px;">
                <span class="from_to_text monthly-text">This Month</span>
                <img class="left-enabled" src="../../assets/left-enable.png" alt="Left chevron enabled" (click)="monthObj.previousMonthEnabled && getMonthTSData(this.resourceCode, this.monthObj.previousMonthStartDate)"
                    [style.cursor]="monthObj.previousMonthEnabled ? 'pointer' : 'not-allowed'" [style.display]="monthObj.previousMonthEnabled ? 'inline-block' : 'none'"
                    height="25">
                <img class="left-disabled" src="../../assets/left-disable.png" alt="Left chevron disabled" [style.cursor]="monthObj.previousMonthEnabled ? 'pointer' : 'not-allowed'"
                    [style.display]="!monthObj.previousMonthEnabled ? 'inline-block' : 'none'" height="25">
                <span class="week-date monthly">{{monthObj.currentMonth}}</span>
                <img class="right-enabled" src="../../assets/right-enable.png" disabed alt="Right chevron enabled" (click)="monthObj.nextMonthEnabled && getMonthTSData(this.resourceCode, this.monthObj.nextMonthStartDate)"
                    [style.cursor]="monthObj.nextMonthEnabled ? 'pointer' : 'not-allowed'" [style.display]="monthObj.nextMonthEnabled ? 'inline-block' : 'none'"
                    height="25">
                <img class="right-disabled" src="../../assets/right-disable.png" disabed alt="Right chevron disabled" [style.cursor]="monthObj.nextMonthEnabled ? 'pointer' : 'not-allowed'"
                    [style.display]="monthObj.nextMonthEnabled ? 'none' : 'inline-block'" height="25">
                    <input matInput [matDatepicker]="monthPicker"
                    formControlName="fromDateExport" readonly="true"
                    placement="bottom-right" class="mat-date-input" [value]="selectedMonthDate">
                <mat-datepicker-toggle class="position-absolute" style="top: 0;right: 1rem;" matSuffix [for]="monthPicker"></mat-datepicker-toggle>
                <mat-datepicker (monthSelected)="selectedMonth($event)" #monthPicker disabled="false" startView="year"></mat-datepicker>
            </p>
        </div>
        <div class="col-md-2 col-sm-12 " *ngIf=false>
            <p class="group" aria-label="ToolTip" style="padding: 0px;">
                <i class="fa fa-angle-left fat-arrow" (click)="monthObj.previousMonthEnabled && getMonthTSData(this.resourceCode, this.monthObj.previousMonthStartDate)"
                    [style.color]="monthObj.previousMonthEnabled ? '#0EB3F7' : 'lightgray'" [style.cursor]="monthObj.previousMonthEnabled ? 'pointer' : 'not-allowed'"></i>
                <span class="week-date monthly">{{monthObj.currentMonth}}</span>
                <i class="fa fa-angle-right fat-arrow" disabed (click)="monthObj.nextMonthEnabled && getMonthTSData(this.resourceCode, this.monthObj.nextMonthStartDate)"
                    [style.color]="monthObj.nextMonthEnabled ? '#0EB3F7' : 'lightgray'" [style.cursor]="monthObj.nextMonthEnabled ? 'pointer' : 'not-allowed'"></i>
                <mat-datepicker-toggle matSuffix [for]="weekDatepicker"></mat-datepicker-toggle>
                <mat-datepicker startView="multi-year" #weekDatepicker (selectedChanged)="updateTimesheetData($event)">

                </mat-datepicker>
                <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="weekDatepicker" [(ngModel)]="monthDpModel"
                    class="mat-date-input">
            </p>
        </div>
    </div>
    <table id='WeekData' class="Desktop_timesheet full-width">
        <tr *ngFor="let weekDataTS of WeekTimeSheetObj; let i = index;" class='total-row background-white'>
            <div id="viewBy">
                <table class="delimiter-table">
                    <tr class='total-div-row'>
                        <td class="td-left">
                            <div class="timesheet-weekname-div">
                                <span class="timesheet-weekName" (click)="weekDataTS.weekName; pickDate(weekDataTS.startDate)">
                                    {{ weekDataTS.weekName }}
                                </span>
                            </div>
                        </td>
                        <td class="td-right">
                            <div class="timesheet-status projectWise-status monthly-status">
                                <p>
                                    <span class="status-code"><b>Status: </b><span class="status-color-{{weekDataTS.code}}">{{weekDataTS.status}}</span></span>
                                </p>
                                <div *ngIf="weekDataTS.code && weekDataTS.description">
                                    <span><b>Description: </b>{{weekDataTS.description}}</span>
                                </div> 
                                <span class="timesheet-lastUpdatedOn" *ngIf="weekDataTS.lastUpdatedOn">Last Updated On: {{weekDataTS.lastUpdatedOn}}</span>
                            </div>
                        </td>
                    </tr>
                </table>
                <jhi-week-timesheet-view [weekObj]="weekDataTS"></jhi-week-timesheet-view>
            </div>
        </tr>
    </table>
    <div class="mobile_timesheet">
        <div class="timesheet_monthly_view">
            <h3>Weeks of {{monthObj.currentMonth}}</h3>
            <div class="sheet_view" *ngFor="let weekDataTS of WeekTimeSheetObj; let i = index;">
                <div class="week_view">
                    {{weekDataTS.weekName}}
                </div>
                <div class="ttl_hrs">
                    {{weekDataTS.timesheet.grandTotal | formatDate}}.00
                </div>
                <div class="week_sheet_status">
                    <span class="status-color-{{weekDataTS.code}}">{{weekDataTS.status}}</span>
                </div>
            </div>
        </div>
    </div>
</mat-card>
<div class="dark-overlay" *ngIf="load" style="height:200px;">
    <mat-spinner class="loader" strokeWidth="3" [diameter]="50"></mat-spinner>
</div>