import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, Inject } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AllocationsService } from '../allocations.service';
import { ResourceService } from '../../resource/resource.service';
import { DatePipe } from '@angular/common';
export const DATE_FORMATE = 'yyyy-MM-dd';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DEFAULT_DATE_FORMAT } from '../../app.constants';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import { Title } from '@angular/platform-browser';
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { PickDateAdapter, PICK_FORMATS } from 'app/validators/validator';
import { CommonDataService } from 'app/common-data.service';
const moment = (_moment as any).default ? (_moment as any).default : _moment;
@Component({
    selector: 'jhi-add-allocation',
    templateUrl: './add-allocation.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./add-allocation.component.scss'],
    providers: [{ provide: DateAdapter, useClass: PickDateAdapter }, { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }]
})
export class AddAllocationComponent implements OnInit, AfterViewInit, OnDestroy {
    public sowSelectSearchFilterCtrl: FormControl = new FormControl();
    public sowListFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public accountSelectSearchFilterCtrl: FormControl = new FormControl();
    public accountListFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public associatedProjectsSelectSearchFilterCtrl: FormControl = new FormControl();
    public associatedProjectsListFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public allocationsOptionsSelectSearchFilterCtrl: FormControl = new FormControl();
    public resourcesOptionsSelectSearchFilterCtrl: FormControl = new FormControl();
    public allocationsOptionsListFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public resourcesOptionsListFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    @ViewChild('singleSelect') singleSelect: MatSelect;
    HRAllocationTotalRecords: any;
     @ViewChild('mySelect') mySelect: MatSelect;
    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();
    loadCur = true;
    loadPrev = false;
    bulkupdated: boolean = false;
    loadPart = false;
    minDate = null;
    endMinDate = null;
    maxDate = null;
    endMaxDate = null;
    datePipe = new DatePipe('en-US');
    metaData: any;
    metaData1: any;
    requestPayload: any = {};
    accounts: Array<any>;
    accountsValue: string;
    resourceValue: any;
    startDatePicker: Array<any>;
    endDatePicker: Array<any>;
    pseudoFromPicker: Array<any>;
    pseudoToPicker: Array<any>;
    currencies: Array<any>;
    miscBill: number;
    remarks: Array<any>;
    resourceType: Array<any>;
    addAllocationForm: FormGroup;
    addbulkForm: FormGroup;
    originalAccount: Array<any>;
    originalCurrencies: Array<any>;
    originalAllocations: Array<any>;
    allocation: any;
    totalBillPercentage: any;
    contractedHoursPercentage: any;
    billableHoursPercentage: any;
    sowselectedOption: any;
    sowOptions: Array<any>;
    originalSows: Array<any>;
    projectSelectedValue: any;
    occupancyValue: any;
    allocationType: any;
    wbsHrsPercentage: any;
    wbsBillsPercentage: any;
    flexibleWE: any;
    clientTS: any;
    clientId: any;
    pseudoResult: any;
    pseudoResourceList: Array<any>;
    pseudoNameList: Array<any>;
    PseudoName: any;
    clinetIdList: any;
    clientIdListCopy: any;
    clientResourceList: any;
    clientResourceListCopy;
    pseudoResource: any;
    pseduoEmailId: any;
    timeOut = null;
    resourcesList: any;
    emailList: any;
    paginationSelection: number;
    pagination: Array<any> = [6, 8, 10, 12, 14, 16, 18, 20];
    reg = '^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+.)?[a-zA-Z]+.)?(gspann).com$';
    resourceSelectedCode = null;
    resourceSelected: any;
    allocations: any;
    resImageData: any = '';
    selectedProject: string = '';
    bulkselectedProject: string = '';
    sow_selected_resetWbs: any = {};
    allocationValue_bu = null;
    sow: any;
    customerClientID: string;
    customerClientName: string;
    preserveSelectedSOW = '';
    modalRef: NgbModalRef;
    currentallocationmodalRef: NgbModalRef;
    allocateButtonStatus = false;
    clientSelectedStatus = false;
    resourceSelectedStatus = false;
    isSubmitClicked = false;

    OccupanyError = false;
    searchTableDataPage;
    columnOrder: boolean;
    page: number;
    searchMDContent: any;
    searchMDResource: any;
    searchMDCode: any;
    groupBy: any = 'PROJECT';
    count = 1;
    bulkAllocationChecked: boolean;
    showAllocationCheckBox: boolean;
    bulkRemove: any;
    buttonActiveIndex: any;
    resourceTypeList: any;
    BillresourceTypeList: any;
    benchResourceTypeList: any;
    bufferResourceTypeList: any;
    pendingAllocationCount: any;
    getHRAllocations = [];
    isHRAllocation = false;
    HRAllocatedResourceCode: any;
    deleteHRAllocationID: any;
    HRAllocationModal: NgbModalRef;
    totalRecords: any;
    pageSize1: any;
    isPendingAllocationError: boolean;
    isPendingAllocationBulkError: boolean;
    selectedResourceName: any;
    selectedResourceNameBulkAllocation: any;
    resourceAvailableInPendingAllocation: boolean;
    HRAllocatedResourceStartDate: String;
    HRAllocatedResourceEndDate: String;
    checkForHRAllocationEndDate: boolean;
    checkForHRAllocationEndDateBulk: boolean;
    resourceSelectedFromPendingAllocation: boolean = false;
    showErrorOnAddAllocation: boolean;
    resourceNameSelectedPendingAllocation: any;
    isStartDateOverlappingHRAllocation: boolean;
    selectedResourceCodeBulkAllocation: any;
    resultLength: number;
    listOfPBAllocationNames: {}[];
    idOfHrAllocationDeletion: any;
    load=false;
    currentPage: number = 0;
    itemsPerPage: number = 5;
    totalItems: number;
    totalPages: number;
    pageNumber:number;
    createSearchTableData() {
        this.searchTableDataPage = {
            currentPage: 0,
            hasNext: false,
            hasPrevious: false,
            header: '',
            isFirst: false,
            isLast: false,
            pageSize: 0,
            totalPages: 0,
            totalRecords: 0
        };
    }
    resourceInfo: any = {
        name: '',
        code: '',
        company: '',
        department: '',
        designation: '',
        employmentStatus: '',
        employmentStatusKey: '',
        actualJoiningDate: '',
        utilization: '',
        utilizationType: '',
        employmentType: '',
        emailId: '',
        currentAllocations: ''
    };
    @ViewChild('content') contentModal: ElementRef;
    @ViewChild('currentAllocations') currentAllocationsModal: ElementRef;
    @ViewChild('resourceSearch') resourceSearch: ElementRef;
    @ViewChild('myInput2') sowsearch: ElementRef;
    @ViewChild('myInput1') accountsearch: ElementRef;
    DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
    allocateFromProject = true;
    accountFromProject: any;
    projectIn: any;
    sowSearchQuery = '';
    accountSearchQuery = '';
    resPseudoImageData: any;
    pseudoResourceCode: any;
    pseudoFromMinDate: any;
    pseudoFromMaxDate: Date;
    deliveryManagerName: any;
    selectedDeliveryManager: any;
    sameResourceSelected = false;
    delegateManagerList: any[];
    searchMetaData: any;
    arrayList: any = [];
    accountOptions: any;
    statusesOptions: any;
    originalStatusesOptions: any;
    projectOptions: any;
    originalProject: any;
    allocationTypeOptions: any;
    originalAllcoations: any;
    tableDataParams: {};
    searchTableData: any[];
    statusSelectedOption = 'all';
    projectselectedOption = 'all';
    allocationselectedOption = 'all';
    accountselectedOption = 'all';
    fromDate: any;
    tillDate: any;
    resourceCodes: any[];
    projectOptionsCopy: any;
    i: any;
    occupancyFromHRAllocation: any;
    createSowObj() {
        this.sow = {
            account: '',
            sowNumber: '',
            start_date: '',
            end_date: '',
            currency: '',
            total_bill: 0,
            total_remaining: 0,
            total_consumed: 0,
            miscBill: 0,
            remarks: '',
            billable_hrs: 0,
            billable_hrs_remaining: 0,
            billable_hrs_consumed: 0,
            contracted_hrs: 0,
            contracted_hrs_remaining: 0,
            contracted_hrs_consumed: 0,
            total_wbs_count: 0,
            billable_wbs_count: 0,
            availableWBSesAllocationMetaData: '',
            allottedWBSesAllocationMetaData: '',
            partialAvailableWBSesAllocationMetaData: '',
            associatedProjects: '',
            originalProjects: '',
            allocationsOptions: '',
            originalAllocationsOptions: '',
            originalResourcesOptions: '',
            resourcesOptions: '',
            wbses: '',
            wbs: '',
            clientTimeTracking: '',
            allottedWbsCount: 0,
            avilableWbsCount: 0
        };
    }
    constructor(
        private allocationsService: AllocationsService,
        private titleService: Title,
        private fb: FormBuilder,
        private resourceService: ResourceService,
        private modalService: NgbModal,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef,
        private commonService: CommonDataService,

    ) {
        this.titleService.setTitle('aperture - Add Allocation');
        this.arrayList = [
            {
                id: 1,
                name: 'test'
            }
        ];
    }

    pendingCountResourceClicked(pendingAllocation) {
        this.getHRAllocations.forEach(result => {
            if (result.resourceCode === pendingAllocation.resourceCode) {
                if (result.accountCode === pendingAllocation.accountCode && result.projectCode === pendingAllocation.projectCode) {
                    this.deleteHRAllocationID = result.id;
                this.HRAllocatedResourceCode = pendingAllocation.resourceCode;
                this.isHRAllocation = true;
                this.addAllocationForm.get('accountsValue').setValue(result.accountCode);
                this.accountSelected(result.accountCode, false);
                this.addAllocationForm.get('sowselectedOption').setValue('all');
                this.addAllocationForm.get('startDatePicker').setValue(this.datePipe.transform(result.startDate, 'yyyy-MM-dd'));
                this.addAllocationForm.get('EndDatePicker').setValue(this.datePipe.transform(result.endDate, 'yyyy-MM-dd'));
                this.addAllocationForm.get('occupancyValue').setValue(result.occupancy);
                this.addAllocationForm.get('resourceValue').setValue(result.resourceName);
                if (result.projectCode !== null) {
                    this.addAllocationForm.get('projectSelectedValue').setValue(result.projectCode);
                    this.addAllocationForm.controls['projectSelectedValue'].valueChanges.subscribe((value)=> {
                        this.projectSelected(value);
                    });
                    this.addAllocationForm.get('allocationType').setValue(result.allocationType.key);
                    this.addAllocationForm.controls['allocationType'].valueChanges.subscribe((val)=> {
                        this.allocationTypeSelected(val);
                    })
                } else {
                    this.addAllocationForm.get('projectSelectedValue').setValue('all');
                    this.addAllocationForm.get('allocationType').setValue('all');
                }
                this.allocationsService.resourceInfo(this.HRAllocatedResourceCode).subscribe(resource => {
                    this.resourceInfo = {
                        name: resource.identity.name,
                        code: resource.identity.code,
                        company: resource.company.value,
                        department: resource.department.value,
                        designation: resource.designation.value,
                        employmentStatus: resource.employmentStatus.statusType.value,
                        employmentStatusKey: resource.employmentStatus.statusType.key,
                        actualJoiningDate: this.datePipe.transform(resource.employmentStatus.actualJoiningDate, DATE_FORMATE),
                        utilization: resource.utilization,
                        utilizationType: resource.utilizationType,
                        employmentType: resource.employmentType,
                        emailId:'',
                        currentAllocations: resource.allocations
                    };
                    let practices = '';
                    let technologies = '';
                    resource.practices.forEach(x => {
                        practices.length ? (practices = practices + ', ' + x.value) : (practices = x.value);
                    });
                    this.resourceInfo.practices = practices;
                    resource.technologies.forEach(y => {
                        technologies.length ? (technologies = technologies + ', ' + y.value) : (technologies = y.value);
                    });
                    this.resourceInfo.technologies = technologies;
                });
                this.resourcesList = [];
                this.occupancyFromHRAllocation = result.occupancy;
                }
            }
        });
        this.resourceSelectedFromPendingAllocation = true;
        this.resourceNameSelectedPendingAllocation = pendingAllocation.resourceName
    }

    ngOnInit() {
        this.createSowObj();
        this.getAllHRAllocations();
        this.paginationSelection = 6;
        this.addAllocationForm = this.fb.group({
            accountsValue: new FormControl('all', []),
            sowselectedOption: new FormControl('all', [Validators.required]),
            startDatePicker: new FormControl('', [Validators.required]),
            EndDatePicker: new FormControl('', [Validators.required]),
            pseudoFromPicker: new FormControl('', []),
            pseudoToPicker: new FormControl('', []),
            // bulkupdated:new FormControl(false),
            miscBill: new FormControl(0, []),
            remarks: new FormControl('', []),
            occupancyValue: new FormControl('', [Validators.required, Validators.min(1), Validators.max(100)]),
            clientId: new FormControl('', [Validators.required]),
            PseudoresourceValue: new FormControl('', [Validators.required]),
            projectSelectedValue: new FormControl('all', [Validators.required]),
            allocationType: new FormControl('all', [Validators.required]),
            resourceType: new FormControl('all', [Validators.required]),
            resourceValue: new FormControl('', [Validators.required]),
            deliveryManager: new FormControl('', []),
            clientTS: new FormControl('', []),
            pseudoResource: new FormControl('', []),
            pseduoEmailId: new FormControl('', Validators.pattern(this.reg)),
            flexibleWE: new FormControl('', []),
            customerClientID: new FormControl('', []),
            customerClientName: new FormControl('', []),
        });
        this.addbulkForm = new FormGroup({
            fromDate: new FormControl('', [Validators.required]),
            tillDate: new FormControl('', [Validators.required]),
            bulkAllocationForm: new FormArray([
                new FormGroup({
                    resourceName: new FormControl('', [Validators.required]),
                    resourceCode: new FormControl(''),
                    flexibleWeekendsEnabled: new FormControl(false),
                    allocationTypebulk: new FormControl('all', [Validators.required]),
                    delegateManager: new FormControl('', []),
                    occupancy: new FormControl('', [Validators.required, Validators.min(1), Validators.max(100)]),
                    clientTimeTrackingEnabled: new FormControl(''),
                    projectSelectedValue: new FormControl('', [Validators.required]),
                    wbsId: new FormControl('', [Validators.required]),
                    deliveryManagerCode: new FormControl(''),
                    hourlyRate: new FormControl('')
                })
            ])
        });

        this.addAllocationForm.get('clientId').disable();
        this.addAllocationForm.get('PseudoresourceValue').disable();
        this.allocationsService.getAddAllocationMetadata().subscribe(response => {
            (this.pseudoResourceList = response.pseudoResources),
                (this.accounts = response.accounts),
                (this.originalAccount = response.accounts),
                (this.sowOptions = response.sows),
                (this.originalSows = response.sows),
                (this.currencies = response.currencies),
                (this.originalCurrencies = response.currencies),
                (this.allocations = response.allocationTypes),
                (this.originalAllocations = response.allocationTypes),
                (this.metaData = response),
                (this.metaData1 = response);
            this.pendingAllocationCount = response.allocationHrCount;
            let sowSort = this.sowOptions.sort((c, d) => c.sowNumber.toLowerCase().localeCompare(d.sowNumber.toLowerCase()));
            let sowOps: any = {},
                sowOpsArray: any = [];
            for (let i = 0; i < this.sowOptions.length; i++) {
                sowOpsArray.push(this.sowOptions[i]);
                if (this.sowOptions[i].children && this.sowOptions[i].children.length) {
                    let sowChildSort = this.sowOptions[i].children.sort((a, b) => a.sowNumber.toLowerCase().localeCompare(b.sowNumber.toLowerCase()));
                    for (let j = 0; j < this.sowOptions[i].children.length; j++) {
                        this.sowOptions[i].children[j].parentSowId = this.sowOptions[i].sowId;
                        sowOpsArray.push(this.sowOptions[i].children[j]);
                    }
                }
            }
            let accountsArray: any = [];
            for (let i = 0; i < this.originalSows.length; i++) {
                for (let j = 0; j < response.accounts.length; j++) {
                    if (response.accounts[j].name === this.originalSows[i].account.value) {
                        if (accountsArray.findIndex(x => x.name === response.accounts[j].name) < 0) {
                            accountsArray.push(response.accounts[j]);
                        }
                    }
                }
            }
            this.accounts = accountsArray;
            let accountsSortArray = this.accounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            this.sowOptions = sowOpsArray;
            this.originalSows = sowOpsArray;
            if (this.route.snapshot.params.accountCode) {
                this.projectIn = this.route.snapshot.params.projectCode;
                this.addAllocationForm.get('accountsValue').setValue(this.route.snapshot.params.accountCode);
                let result = this.accounts.filter(obj => {
                    return obj.code === this.route.snapshot.params.accountCode;
                });
                this.accountSelected(result[0].code, false);
                this.allocateFromProject = true;
            } else {
                this.allocateFromProject = false;
            }
            // load the initial  list
            this.accountListFiltered.next(this.accounts.slice());
            this.accountSelectSearchFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.accountFilterList();
                });
            this.sowListFiltered.next(this.sowOptions.slice());
            this.sowSelectSearchFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.sowFilterList();
                });
        });
        // this.getAllHRAllocations();
    }

    closeModalPopup() {
        this.HRAllocationModal.close();
    }

    openHRAllocation() {
        let dialogRef = this.dialog.open(AllocationPendingComponent, {
            width: '90%',
            maxWidth: 'none',
            height:'100%',
            data: this.getHRAllocations,
        });
        dialogRef.afterClosed().subscribe(result=>{
			if (result){
                this.pendingCountResourceClicked(result);
			}
		});
    }

    getAllHRAllocations() {
        this.allocationsService.getAllHRAllocations().subscribe(response => {
            response.forEach(loop=> {
                if (loop.allocationType.key === 'BENCH') {
                    return;
                } else {
                    this.getHRAllocations.push(loop);
                }
            });
        });
    }

    addBulkAllocation() {
        let payloadArray = [];
        this.requestPayload = {};
        let bulkData = this.addbulkForm.controls['bulkAllocationForm'].value;
        let joiningDate = this.resourceInfo.actualJoiningDate;
        let sowStartDate = this.sow.start_date;
        bulkData.forEach(res => {
            this.requestPayload = {};
            this.requestPayload['clientId'] = '';
            this.requestPayload['pseudoResourceId'] = '';
            this.requestPayload['pseudoResource'] = false;
            this.requestPayload['clientTimeTrackingEnabled'] = false;
            this.requestPayload['flexibleWeekendsEnabled'] = res.flexibleWeekendsEnabled;
            this.requestPayload['fromDate'] = this.datePipe.transform(this.addbulkForm.get('fromDate').value, DATE_FORMATE);
            this.requestPayload['occupancy'] = res.occupancy;
            this.requestPayload['clientResourceId'] = '';
            this.requestPayload['clientResourceName'] = '';
            this.requestPayload['resourceCode'] = this.commonService.encrypt(res.resourceCode);
            this.requestPayload['tillDate'] = this.datePipe.transform(this.addbulkForm.get('tillDate').value, DATE_FORMATE);
            this.requestPayload['wbsId'] = res.wbsId;
            this.requestPayload['delegateManager'] = res.delegateManager ? this.commonService.encrypt(res.deliveryManagerCode) : null;
            payloadArray.push(this.requestPayload);
        });
        this.allocationsService.createBulkAllocation(payloadArray).subscribe(response => {
            if (this.allocateFromProject === true) {
                this.router.navigate(['/projects/view-project/'],{skipLocationChange: true});
            } else {
                this.router.navigate(['/Allocations/listings-allocations'],{skipLocationChange: true});
            }
        });
    }

    resourceSelectedFunction(event, index?) {
        if (this.bulkAllocationChecked && index > 0) {
            if (this.addbulkForm.get('bulkAllocationForm')['controls'][index - 1].get('wbsId').value === event.value) {
                let dialogRef = this.dialog.open(ReviewAllocationDialog, {
                    width: '400px',
                    height: '110px',
                });
            }
        }
        this.wbsHrsPercentage = 0;
        if (event.value) {
            for (let j = 0; j < this.sow.wbses.length; j++) {
                if (this.sow.wbses[j].wbsId === event.value) {
                    this.sow.wbses[j].allocationTimeline.timeSlots.forEach((x, i) => {
                        if (x.resource) {
                            this.allocationsService.getMethodRequest(x['resource']['_links']['itrack:image']['href']).subscribe((imgData: any) => {
                                x.img = imgData;
                                this.sow.wbs = this.sow.wbses[j];
                            });
                        } else {
                            this.sow.wbs = this.sow.wbses[j];
                        }
                        this.sow.wbs = this.sow.wbses[j];
                    });
                }
                this.minDate = new Date(this.sow.wbses[j].allocationTimeline.timeline.startInstant);
                this.endMinDate = new Date(this.sow.wbses[j].allocationTimeline.timeline.startInstant);
                // this.endMinDate = new Date(this.sow.wbses[j].allocationTimeline.timeline.startInstant);
                // if (this.sow.wbses[j].allocationTimeline.timeline.endInstant) {
                //     this.maxDate = new Date(this.sow.wbses[j].allocationTimeline.timeline.endInstant);
                //     this.endMaxDate = new Date(sow.wbses[j].allocationTimeline.timeline.endInstant);
                // }
                this.maxDate = new Date(this.sow.end_date);
                this.endMaxDate = new Date(this.sow.end_date);
            }
        }
        if (this.bulkAllocationChecked) {
         this.addbulkForm.get('bulkAllocationForm')['controls'][index].get('hourlyRate').setValue(this.sow.wbs.hourlyRate);
        }
        this.wbsHrsPercentage = this.sow.wbs.hoursSummary.consumedHours / this.sow.wbs.hoursSummary.contractedHours * 100;
        this.wbsHrsPercentage = Math.trunc(this.wbsHrsPercentage);
        this.wbsBillsPercentage = this.sow.wbs.billsSummary.consumedBill.amount / this.sow.wbs.billsSummary.contractedBill.amount * 100;
        this.wbsBillsPercentage = Math.trunc(this.wbsBillsPercentage);
        this.mySelect.open();
         setTimeout(()=>this.mySelect.close(),100);
    }

    calPercentage(total: number, consumed: number) {
        let per = (total - (total - consumed)) / total * 100;
        if (!isFinite(per)) {
            return 0;
        }
        return Math.trunc(per);
    }

    openLg(content, id) {
        this.allocation = [];
        this.allocationsService.getAllocationById(id).subscribe(response => {
            this.allocation = response;
            this.modalRef = this.modalService.open(this.contentModal, { size: 'lg' });
        });
    }

    jumpPageTo(pageNumber) {
        this.page = pageNumber;
        this.getAllocationsList();
    }

    openCurrentAllocations(currentAllocations) {
        this.allocation = [];
        this.currentallocationmodalRef = this.modalService.open(this.currentAllocationsModal, { size: 'lg' });
    }

    bulk(event) {
        this.bulkupdated = event.checked;
        if (this.bulkupdated) {
            this.bulkAllocationChecked = true;
            let control = <FormArray>this.addbulkForm.controls['bulkAllocationForm'];
            for (let i = control.length - 1; i > 0; i--) {
                control.removeAt(i);
            }
        } else {
            this.bulkAllocationChecked = false;
            this.isPendingAllocationError = true ? false : false;
            // this.isPendingAllocationBulkError
            this.addbulkForm.get('bulkAllocationForm')['controls'][0].get('resourceName').setValue('');
        }
        this.isPendingAllocationError = true ? false : false;
        this.addAllocationForm.get('resourceValue').setValue('');
    }
    closeLg() {
        this.modalRef.close();
    }

    closeCurrentAllocations() {
        this.currentallocationmodalRef.close();
    }

    numberOnly(event) {
        let charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    loadCurrent() {
        this.loadCur = true;
        this.loadPrev = false;
        this.loadPart = false;
        this.addAllocationForm.get('resourceType').setValue('all');
        this.addAllocationForm.get('allocationType').setValue('all');
        this.addAllocationForm.get('projectSelectedValue').setValue('all');
        if (this.metaData) {
            this.allocationsResourcesFilter(this.metaData, this.loadCur);
        }
    }

    loadPrevious() {
        this.loadPrev = true;
        this.loadCur = false;
        this.loadPart = false;
        this.addAllocationForm.get('resourceType').setValue('all');
        this.addAllocationForm.get('allocationType').setValue('all');
        this.addAllocationForm.get('projectSelectedValue').setValue('all');
        if (this.metaData) {
            this.allocationsResourcesFilter(this.metaData, this.loadCur);
        }
    }

    loadPartial() {
        this.loadPrev = false;
        this.loadCur = false;
        this.loadPart = true;
        this.addAllocationForm.get('resourceType').setValue('all');
        this.addAllocationForm.get('allocationType').setValue('all');
        if (this.metaData) {
            this.allocationsResourcesFilter(this.metaData, this.loadCur, this.loadPart);
        }
    }

    notMoreThan100() {
        if (this.addAllocationForm.get('occupancyValue').value > 100) {
            this.OccupanyError = true;
            this.addAllocationForm.get('occupancyValue').setValue('');
        } else if (this.addAllocationForm.get('occupancyValue').value < 1) {
            this.OccupanyError = true;
            this.addAllocationForm.get('occupancyValue').setValue('');
        } else if (this.addAllocationForm.get('occupancyValue').value >= 1 && this.addAllocationForm.get('occupancyValue').value <= 100) {
            this.OccupanyError = false;
        }

    }

    checkDates(event, flag: string) {
        // this.endMinDate = new Date(event);
        let enddp;
        let startdp;
        let local_flag = false;
        if (flag === 'enddp') {
            enddp = event;
            if (this.bulkAllocationChecked) {
                startdp = this.addbulkForm.get('fromDate').value;
            } else {
                startdp = this.addAllocationForm.get('startDatePicker').value;
            }
        } else if (flag === 'startdp') {
            if (this.bulkAllocationChecked) {
                enddp = this.addbulkForm.get('tillDate').value;
            } else {
                enddp = this.addAllocationForm.get('EndDatePicker').value;
            }
            startdp = event;
            let dd: Date = new Date(event);
            if (dd > this.endMinDate) {
                this.endMinDate = new Date(dd.setDate(dd.getDate()));
                // this.endMinDate = new Date(dd.setDate(dd.getDate() + 1));
            }
        }
        if (startdp && enddp) {
            if (startdp > enddp) {
                local_flag = true;
                alert('End date should be greater than Start date');
                if (this.bulkAllocationChecked) {
                    this.addbulkForm.get('tillDate').setValue('');
                } else {
                    this.addAllocationForm.get('EndDatePicker').setValue('');
                }
            }
        } else if (enddp && !startdp) {
            local_flag = true;
            alert('Select start date in order to validate end date');
            if (this.bulkAllocationChecked) {
                this.addbulkForm.get('tillDate').setValue('');
            } else {
                this.addAllocationForm.get('EndDatePicker').setValue('');
            }
        }
        if (local_flag) {
            setTimeout(() => {
                if (flag === 'enddp' && this.bulkAllocationChecked) {
                    this.addbulkForm.get('tillDate').setValue('');
                } else if (flag !== 'enddp' && this.bulkAllocationChecked) {
                    this.addbulkForm.get('fromDate').setValue('');
                }
                else if (flag === 'enddp' && !this.bulkAllocationChecked) {
                    this.addAllocationForm.get('EndDatePicker').setValue('');
                } else if (flag !== 'enddp' && !this.bulkAllocationChecked) {
                    this.addAllocationForm.get('startDatePicker').setValue('');
                }
            }, 100);
        }
        if (flag === 'startdp') {
            this.pseudoFromMinDate = new Date(event);
        }
        if (flag === 'enddp') {
            this.pseudoFromMaxDate = new Date(event);
        }
    }

    searchAccounts(event) {
        if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 65 && event.keyCode <= 90) {
            this.accountSearchQuery += event.key;
        }

        if (event.keyCode === 32) {
            this.accountSearchQuery += ' ';
            event.stopImmediatePropagation();
        }

        if (event.keyCode === 8) {
            this.accountSearchQuery = this.accountSearchQuery.substring(0, this.accountSearchQuery.length - 1);
        }
        if (!this.accountSearchQuery) {
            this.accounts = this.originalAccount;
            return;
        }
        let result: string[] = [];
        for (let list of this.originalAccount) {
            if (list.name) {
                if (list.name.toLowerCase().indexOf(this.accountSearchQuery.toLowerCase()) > -1) {
                    result.push(list);
                }
            } else {
                if (list.toLowerCase().indexOf(this.accountSearchQuery.toLowerCase()) > -1) {
                    result.push(list);
                }
            }
        }
        this.accounts = result;
    }

    searchProjects(query: string) {
        if (!query) {
            this.sow.associatedProjects = this.sow.originalProjects;
            return;
        }
        let result: string[] = [];
        for (let list of this.sow.originalProjects) {
            if (list.value) {
                if (list.value.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(list);
                }
            } else {
                if (list.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(list);
                }
            }
        }
        this.sow.associatedProjects = result;
    }

    searchAllocations(query: string) {
        if (!query) {
            this.sow.allocationsOptions = this.originalAllocations;
            return;
        }
        let result: string[] = [];
        for (let list of this.originalAllocations) {
            if (list.key) {
                if (list.value.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(list);
                }
            } else {
                if (list.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(list);
                }
            }
        }
        this.sow.allocationsOptions = result;
    }

    searchCurrencies(query: string) {
        if (!query) {
            this.currencies = this.originalCurrencies;
            return;
        }
        let result: string[] = [];
        for (let list of this.originalCurrencies) {
            if (list.key) {
                if (list.key.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(list);
                }
            } else {
                if (list.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(list);
                }
            }
        }
        this.currencies = result;
    }

    resetAll() {
        this.arrayList = [];
        this.bulkupdated = false;
        this.showAllocationCheckBox = false;
        this.bulkAllocationChecked = false;
        this.resourcesList = [];
        this.sow.allocationsOptions = [];
        this.sow.resourcesOptions = [];
        this.sow.associatedProjects = [];
        this.sow.allottedWbsCount = 0;
        this.sow.avilableWbsCount = 0;
        this.sow.partialAvailableWbsCount = 0;
        this.loadCur = true;
        this.loadPrev = false;
        this.loadPart = false;
        this.sow_selected_resetWbs = {};
        this.allocationValue_bu = null;
        if (this.route.snapshot.params.accountCode) {
            this.addAllocationForm.get('accountsValue').setValue(this.route.snapshot.params.accountCode);
            let result = this.accounts.filter(obj => {
                return obj.code === this.route.snapshot.params.accountCode;
            });
            this.accountSelected(result[0].code, false);
            this.allocateFromProject = true;
        } else {
            this.addAllocationForm.get('accountsValue').setValue('all');
            this.sowOptions = this.originalSows;
        }
        this.addAllocationForm.get('occupancyValue').setValue('');
        this.addAllocationForm.get('occupancyValue').setErrors(null);
        this.addAllocationForm.get('clientId').setValue('');
        this.addAllocationForm.get('PseudoresourceValue').setValue('');
        this.addAllocationForm.get('startDatePicker').setValue('');
        this.addAllocationForm.get('startDatePicker').setErrors(null);
        this.addAllocationForm.get('EndDatePicker').setValue('');
        this.addAllocationForm.get('resourceValue').setValue('');
        this.addAllocationForm.get('resourceValue').setErrors(null);
        this.addAllocationForm.get('deliveryManager').setValue('');
        this.addAllocationForm.get('sowselectedOption').setValue('all');
        this.addAllocationForm.get('miscBill').setValue(0);
        this.addAllocationForm.get('remarks').setValue('');
        this.addAllocationForm.get('projectSelectedValue').setValue('all');
        this.addAllocationForm.get('allocationType').setValue('all');
        this.addAllocationForm.get('resourceType').setValue('all');
        this.addAllocationForm.get('flexibleWE').setValue('');
        this.addAllocationForm.get('clientTS').setValue('');
        this.addAllocationForm.get('pseudoResource').setValue('');
        this.addAllocationForm.get('pseudoFromPicker').setValue('');
        this.addAllocationForm.get('pseudoToPicker').setValue('');
        this.addAllocationForm.get('customerClientID').setValue('');
        this.addAllocationForm.get('customerClientName').setValue('');
        this.addbulkForm.reset();
        this.isHRAllocation = false;
        let control = <FormArray>this.addbulkForm.controls['bulkAllocationForm'];
        for (let i = control.length - 1; i > 0; i--) {
            control.removeAt(i)
        }
        this.selectedDeliveryManager = '';
        this.requestPayload = {};
        this.sowSearchQuery = '';
        this.accountSearchQuery = '';
        this.sowsearch.nativeElement.value = '';
        this.accountsearch.nativeElement.value = '';
        this.totalBillPercentage = 0;
        this.contractedHoursPercentage = 0;
        this.billableHoursPercentage = 0;
        this.resourceSelectedCode = null;
        this.resImageData = '';
        this.resourceInfo = {
            name: '',
            code: '',
            company: '',
            department: '',
            designation: '',
            employmentStatus: '',
            employmentStatusKey: '',
            actualJoiningDate: '',
            utilization: '',
            utilizationType: '',
            employmentType: '',
            emailId: '',
            currentAllocations: ''
        };
    }

    resetWbs() {
        this.allocationValue_bu = this.addAllocationForm.get('accountsValue').value;
        this.sow_selected_resetWbs = this.addAllocationForm.get('sowselectedOption').value;
        this.loadCur = true;
        this.loadPrev = false;
        this.loadPart = false;
        this.addAllocationForm.get('occupancyValue').setValue('');
        this.addAllocationForm.get('clientId').setValue('');
        this.addAllocationForm.get('PseudoresourceValue').setValue('');
        this.addAllocationForm.get('startDatePicker').setValue('');
        this.addAllocationForm.get('pseudoFromPicker').setValue('');
        this.addAllocationForm.get('pseudoToPicker').setValue('');
        this.addAllocationForm.get('EndDatePicker').setValue('');
        this.addAllocationForm.get('resourceValue').setValue('');
        this.addAllocationForm.get('deliveryManager').setValue('');
        this.addAllocationForm.get('miscBill').setValue(0);
        this.addAllocationForm.get('remarks').setValue('');
        this.addAllocationForm.get('projectSelectedValue').setValue('all');
        this.addAllocationForm.get('allocationType').setValue('all');
        this.addAllocationForm.get('resourceType').setValue('all');
        this.addAllocationForm.get('flexibleWE').setValue('');
        this.addAllocationForm.get('clientTS').setValue('');
        this.addAllocationForm.get('pseudoResource').setValue('');
        this.addAllocationForm.get('pseduoEmailId').setValue('');
        this.isHRAllocation = false;
        this.requestPayload = {};
        this.totalBillPercentage = 0;
        this.contractedHoursPercentage = 0;
        this.billableHoursPercentage = 0;
        this.resourceSelectedCode = null;
        this.resImageData = '';
        this.resourceInfo = {
            name: '',
            code: '',
            company: '',
            department: '',
            designation: '',
            employmentStatus: '',
            employmentStatusKey: '',
            actualJoiningDate: '',
            utilization: '',
            utilizationType: '',
            employmentType: '',
            emailId: '',
            currentAllocations: ''
        };
    }

    allocationTypeSelected(event) {
        this.sow.resourcesOptions = [];
        let iterate = [];
        if (this.loadCur) {
            iterate = this.metaData.availableWBSesAllocationMetaData;
        } else if (this.loadPart) {
            iterate = this.metaData.partiallyAvailableWBSesAllocationMetaData;
        } else {
            iterate = this.metaData.allottedWBSesAllocationMetaData;
        }
        if (iterate) {
            iterate.forEach((element) => {
                if (this.addAllocationForm.get('projectSelectedValue').value === element.project.key) {
                    element.wbsAllocationIdentities.forEach(x => {
                        if (event !== 'all' && x.allocationType.key === event) {
                            x.wbsIdentities.forEach(y => {
                                this.sow.resourcesOptions.push(y);
                            });
                        }
                    });
                }
                this.addAllocationForm.get('resourceType').setValue('all');
            });   
        }
        this.resourcesOptionsListFiltered.next(this.sow.resourcesOptions.slice());
    }

    projectSelected(event) {
        this.sow.allocationsOptions = [];
        this.sow.resourcesOptions = [];
        let iterate = [];
        if (this.loadCur) {
            iterate = this.metaData.availableWBSesAllocationMetaData;
        } else if (this.loadPart) {
            iterate = this.metaData.partiallyAvailableWBSesAllocationMetaData;
        } else {
            iterate = this.metaData.allottedWBSesAllocationMetaData;
        }
            /**Tooltip implemented  */
             let  index;
            if (this.sow.associatedProjects) {
                index = this.sow.associatedProjects.findIndex(x => x.key === event);
            }
            if (index !== -1) {
               this.selectedProject = this.sow.associatedProjects[index].value;  
            } else {
                this.selectedProject = 'all';
            }

        if (iterate) {
            iterate.forEach((element) => {
                if (event !== 'all' && event === element.project.key) {
                    element.wbsAllocationIdentities.forEach(x => {
                        if (x.allocationType) {
                            if (this.sow.allocationsOptions.length) {
                                let check = false;
                                for (let j = 0; j < this.sow.allocationsOptions.length; j++) {
                                    if (x.allocationType.key === this.sow.allocationsOptions[j].key) {
                                        check = true;
                                    }
                                }
                                if (!check) {
                                    this.sow.allocationsOptions.push(x.allocationType);
                                }
                            } else {
                                this.sow.allocationsOptions.push(x.allocationType);
                            }
                            x.wbsIdentities.forEach(y => {
                                this.sow.resourcesOptions.push(y);
                            });
                        }
                    });
                } else if (event === 'all') {
                    element.wbsAllocationIdentities.forEach(x => {
                        if (x.allocationType) {
                            if (this.sow.allocationsOptions.length) {
                                let check = false;
                                for (let j = 0; j < this.sow.allocationsOptions.length; j++) {
                                    if (x.allocationType.key === this.sow.allocationsOptions[j].key) {
                                        check = true;
                                    }
                                }
                                if (!check) {
                                    this.sow.allocationsOptions.push(x.allocationType);
                                }
                            } else {
                                this.sow.allocationsOptions.push(x.allocationType);
                            }
                        }
                        x.wbsIdentities.forEach(y => {
                            this.sow.resourcesOptions.push(y);
                        });
                    });
                }
                this.addAllocationForm.get('allocationType').setValue('all');
                this.addAllocationForm.get('resourceType').setValue('all');
            });
        }
        
    }

    allocationsResourcesFilter(response, available_allotted, partialAllocated?) {
        this.sow.allocationsOptions = [];
        this.sow.resourcesOptions = [];
        this.sow.associatedProjects = [];
        let iterate = [];
        if (available_allotted) {
            iterate = response.availableWBSesAllocationMetaData;
        } else {
            iterate = response.allottedWBSesAllocationMetaData;
        }
        if (partialAllocated) {
            iterate = response.partiallyAvailableWBSesAllocationMetaData;
        }
        iterate.forEach((element) => {
            element.wbsAllocationIdentities.forEach(x => {
                if (x.allocationType) {
                    if (this.sow.allocationsOptions.length) {
                        let check = false;
                        for (let j = 0; j < this.sow.allocationsOptions.length; j++) {
                            if (x.allocationType.key === this.sow.allocationsOptions[j].key) {
                                check = true;
                            }
                        }
                        if (!check) {
                            this.sow.allocationsOptions.push(x.allocationType);
                        }
                    } else {
                        this.sow.allocationsOptions.push(x.allocationType);
                    }
                }
                x.wbsIdentities.forEach(y => {
                    this.sow.resourcesOptions.push(y);
                });
            });
            this.sow.associatedProjects.push(element.project);
        });
        this.sow.originalAllocationsOptions = this.sow.allocationsOptions;
        this.sow.originalResourcesOptions = this.sow.resourcesOptions;
        this.sow.originalProjects = this.sow.associatedProjects;
        this.associatedProjectsListFiltered.next(this.sow.associatedProjects.slice());
        this.associatedProjectsSelectSearchFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.associatedProjectsFilterList();
            });
        this.allocationsOptionsListFiltered.next(this.sow.allocationsOptions.slice());
        this.allocationsOptionsSelectSearchFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.allocationsOptionsFilterList();
            });
        this.resourcesOptionsListFiltered.next(this.sow.resourcesOptions.slice());
        this.resourcesOptionsSelectSearchFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.resourcesOptionsFilterList();
            });
    }

    emptySlotClick(startDate, endDate) {
        this.addAllocationForm.get('startDatePicker').setValue(startDate);
        if (endDate) {
            if (this.sow.end_date !== null && this.sow.end_date <= endDate) {
                this.addAllocationForm.get('EndDatePicker').setValue(this.sow.end_date);
            } else if (this.sow.end_date !== null && this.sow.end_date >= endDate) {
                this.addAllocationForm.get('EndDatePicker').setValue(endDate);
            } else {
                this.addAllocationForm.get('EndDatePicker').setValue(endDate);
            }
        }
    }

    PseudoSelected(event) {
        if (this.addAllocationForm.get('pseudoResource').value === true) {
            this.addAllocationForm.get('clientId').enable();
            this.addAllocationForm.get('PseudoresourceValue').enable();
        } else {
            this.addAllocationForm.get('clientId').disable();
            this.addAllocationForm.get('PseudoresourceValue').disable();
        }
    }
    accountSelected(value, isManuallyChanged) {
        this.clinetIdList = [];
        this.clientIdListCopy = [];
        this.clientResourceList = [];
        this.clientResourceListCopy = [];
        this.sowOptions = [];
        if (value === 'all') {
            this.metaData1.sows.forEach(x => {
                this.sowOptions.push(x);
            });
            this.sowListFiltered.next(this.sowOptions.slice());
        }
        if (value && value.length > 0) {
            this.metaData1.sows.forEach(x => {
                if (x.account.key === value) {
                    this.accountFromProject = x.account.value;
                    this.sowOptions.push(x);
                }
            });
            this.sowListFiltered.next(this.sowOptions.slice());
        }
        for (let list of this.originalAccount) {
            if (list.code) {
                if (list.code.indexOf(value) > -1) {
                    this.pseudoResult = Object.assign(list);
                }
            }
        }
        this.addAllocationForm.controls['resourceValue'].reset();
        this.addAllocationForm.controls['deliveryManager'].reset();
        this.addAllocationForm.controls['startDatePicker'].reset();
        this.addAllocationForm.controls['EndDatePicker'].reset();
        this.addAllocationForm.controls['occupancyValue'].reset();
        this.bulkupdated = false;
        this.bulkAllocationChecked = false;
        this.addbulkForm.reset();
        this.addAllocationForm.get('sowselectedOption').setValue('all');
        this.addAllocationForm.get('resourceType').setValue('all');
        this.resourceInfo = {
            name: '',
            code: '',
            company: '',
            department: '',
            designation: '',
            employmentStatus: '',
            employmentStatusKey: '',
            actualJoiningDate: '',
            utilization: '',
            utilizationType: '',
            employmentType: '',
            emailId: '',
            currentAllocations: ''
        };
        if (this.resourceSelectedFromPendingAllocation === true) {
            this.resourceSelectedFromPendingAllocation = false;
            if (isManuallyChanged) {
                this.showErrorOnAddAllocation = true;
            }
        } else {
            this.showErrorOnAddAllocation = false;
        }
    }

    sowSelected(event) {
        this.clinetIdList = [];
        this.clientIdListCopy = [];
        this.clientResourceList = [];
        this.clientResourceListCopy = [];
        this.addAllocationForm.get('accountsValue').setValue(event.value.account.key);
        if (this.sow.wbs.wbsId) {
            this.resetWbs();
        }
        if (event.value !== 'all') {
            let uri = event.value['_links']['self']['href'];
            this.totalBillPercentage = 0;
            this.contractedHoursPercentage = 0;
            this.billableHoursPercentage = 0;
            this.showAllocationCheckBox = true;
            this.allocationsService.getSowById(uri).subscribe(response => {
                this.metaData = response;
                this.sow = {
                    selectedSowtoAccount: event.value.account.key,
                    account: response.account.name,
                    sowNumber: response.sowNumber,
                    start_date: response.startDate,
                    end_date: response.endDate,
                    currency: response.currencyUnit.key,
                    total_bill: response.totalBillsSummary.contractedBill.amount,
                    total_remaining: response.totalBillsSummary.remainingBill.amount,
                    total_consumed: response.totalBillsSummary.consumedBill.amount,
                    miscBill: response.miscellaneousBill.amount,
                    remarks: response.remarks,

                    billable_hrs: this.numberWithCommas(response.billableHoursSummary.contractedHours),
                    billable_hrs_remaining: this.numberWithCommas(response.billableHoursSummary.remainingHours),
                    billable_hrs_consumed: this.numberWithCommas(response.billableHoursSummary.consumedHours),

                    contracted_hrs: this.numberWithCommas(response.totalHoursSummary.contractedHours),
                    contracted_hrs_remaining: this.numberWithCommas(response.totalHoursSummary.remainingHours),
                    contracted_hrs_consumed: this.numberWithCommas(response.totalHoursSummary.consumedHours),

                    total_wbs_count: response.totalWBSCount,
                    billable_wbs_count: response.totalBillableWBSCount,
                    availableWBSesAllocationMetaData: response.availableWBSesAllocationMetaData,
                    allottedWBSesAllocationMetaData: response.allottedWBSesAllocationMetaData,
                    partialAvailableWBSesAllocationMetaData: response.partiallyAvailableWBSesAllocationMetaData,
                    associatedProjects: response.associatedProjects,
                    originalProjects: response.associatedProjects,
                    allocationsOptions: '',
                    wbses: response.wbses,
                    wbs: '',
                    clientTimeTracking: response.account.clientTimeTracking
                };
                if (response.endDate) {
                    this.endMaxDate = new Date(response.endDate);
                }
                this.sow.allottedWbsCount = 0;
                this.sow.avilableWbsCount = 0;
                this.sow.partialAvailableWbsCount = 0;
                let associatedProjectsSort = this.sow.associatedProjects.sort(function (a, b) {
                    if (a.value > b.value) {
                        return 1;
                    }
                    if (a.value < b.value) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                let sowSort = this.sowOptions.sort((c, d) => c.sowNumber.toLowerCase().localeCompare(d.sowNumber.toLowerCase()));
                if (response.allottedWBSesAllocationMetaData.length) {
                    response.allottedWBSesAllocationMetaData.forEach(element => {
                        element.wbsAllocationIdentities.forEach(wbs => {
                            let allocationTypeSort = element.wbsAllocationIdentities.sort((c, d) => c.allocationType.value.toLowerCase().localeCompare(d.allocationType.value.toLowerCase()));
                            if (wbs.wbsIdentities) {
                                let allocatedResourceTypesort = wbs.wbsIdentities.sort((a, b) => a.resourceType.toLowerCase().localeCompare(b.resourceType.toLowerCase()));
                            }
                            if (wbs.wbsIdentities.length) {
                                this.sow.allottedWbsCount += parseInt(wbs.wbsIdentities.length, 10);
                            }
                        });
                    });
                }
                if (response.availableWBSesAllocationMetaData.length) {
                    response.availableWBSesAllocationMetaData.forEach(element => {
                        element.wbsAllocationIdentities.forEach(wbs => {
                            let availableAllocationTypeSort = element.wbsAllocationIdentities.sort((a, b) => a.allocationType.value.toLowerCase().localeCompare(b.allocationType.value.toLowerCase()));
                            if (wbs.wbsIdentities) {
                                let availableAllocatedResourceTypesort = wbs.wbsIdentities.sort((a, b) => a.resourceType.toLowerCase().localeCompare(b.resourceType.toLowerCase()));
                            }
                            if (wbs.wbsIdentities.length) {
                                this.sow.avilableWbsCount += parseInt(wbs.wbsIdentities.length, 10);
                            }
                        });
                    });
                }
                if (response.partiallyAvailableWBSesAllocationMetaData.length) {
                    response.partiallyAvailableWBSesAllocationMetaData.forEach(element => {
                        element.wbsAllocationIdentities.forEach(wbs => {
                            let partiallyAllocationTypeSort = element.wbsAllocationIdentities.sort((c, d) => c.allocationType.value.toLowerCase().localeCompare(d.allocationType.value.toLowerCase()));
                            if (wbs.wbsIdentities) {
                                let PartialallocatedResourceTypesort = wbs.wbsIdentities.sort((a, b) => a.resourceType.toLowerCase().localeCompare(b.resourceType.toLowerCase()));
                            }
                            if (wbs.wbsIdentities.length) {
                                this.sow.partialAvailableWbsCount += parseInt(wbs.wbsIdentities.length, 10);
                            }
                        });
                    });
                }
                if (!this.isHRAllocation) {
                    this.addAllocationForm.controls['resourceValue'].reset();
                    this.addAllocationForm.controls['deliveryManager'].reset();
                    this.addAllocationForm.controls['startDatePicker'].reset();
                    this.addAllocationForm.controls['EndDatePicker'].reset();
                    this.addAllocationForm.controls['occupancyValue'].reset();
                }
                this.bulkupdated = false;
                this.bulkAllocationChecked = false;
                this.addbulkForm.reset();
                this.totalBillPercentage = this.sow.total_consumed / this.sow.total_bill * 100;
                this.totalBillPercentage = Math.trunc(this.totalBillPercentage);
                this.contractedHoursPercentage = this.sow.contracted_hrs_consumed / this.sow.contracted_hrs * 100;
                this.contractedHoursPercentage = Math.trunc(this.contractedHoursPercentage);
                this.billableHoursPercentage = this.sow.billable_hrs_consumed / this.sow.billable_hrs * 100;
                this.billableHoursPercentage = Math.trunc(this.billableHoursPercentage);
                this.allocationsResourcesFilter(response, this.loadCur);
            });
        }
        this.sowSearchQuery = '';
        this.accountSearchQuery = '';
    }
    numberWithCommas(x) {
        let parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
    searchResources(query: string) {
        if (!query) {
            this.sow.resourcesOptions = this.sow.originalResourcesOptions;
            return;
        }
        let result: string[] = [];
        for (let list of this.sow.originalResourcesOptions) {
            if (list.resourceType) {
                if (list.resourceType.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(list);
                }
            } else {
                if (list.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(list);
                }
            }
        }
        this.sow.resourcesOptions = result;
        query = '';
    }

    validateEmail(email) {
        if (/^\w+([\.-]?\w+)*@.*/.test(email)) {
            return true;
        }
        return false;
    }

    searchResource(searchValue) {
        this.isPendingAllocationError =false;
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
        let localSearchValue = '';
        if (this.validateEmail(searchValue)) {
            localSearchValue = searchValue.substring(0, searchValue.lastIndexOf('@'));
        } else {
            localSearchValue = searchValue;
        }

        this.timeOut = setTimeout(() => {
            this.allocationsService.quickSearchOnlyActiveResource(localSearchValue).subscribe(resourceList => {
                this.resourcesList = resourceList['_embedded']['itrack:resourceListItemList'];
                this.resourcesList.forEach((element, i) => {
                    this.allocationsService.getMethodRequest(element['_links']['itrack:image']['href']).subscribe((imgData: any) => {
                        this.resourcesList[i].image = imgData;
                    });
                });
            });
        }, 1000);
    }

    quickSearchDelegateResource(searchValue) {
        if (searchValue) {
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }
            this.timeOut = setTimeout(() => {
                this.allocationsService.resourceQuickSearch(searchValue).subscribe(resourceList => {
                    this.delegateManagerList = resourceList['_embedded']['itrack:resourceListItemList'];
                });
            }, 1000);
        } else {
            this.delegateManagerList = [];
        }
    }

    selectedManager(event, index?) {
        this.selectedDeliveryManager = event.option.value.code;
        if (this.resourceSelected && (this.resourceSelected === this.selectedDeliveryManager)) {
            this.sameResourceSelected = true;
            this.deliveryManagerName = '';
            this.selectedDeliveryManager = '';
            if (this.bulkAllocationChecked) {
                this.addbulkForm.get('bulkAllocationForm')['controls'][index].get('delegateManager').setValue('');
                this.addbulkForm.get('bulkAllocationForm')['controls'][index].get('deliveryManagerCode').setValue('');
            } else {
                this.addAllocationForm.get('deliveryManager').setValue('');
            }
        } else {
            if (this.bulkAllocationChecked) {
                this.addbulkForm.get('bulkAllocationForm')['controls'][index].get('delegateManager').setValue(event.option.value.name);
                this.addbulkForm.get('bulkAllocationForm')['controls'][index].get('deliveryManagerCode').setValue(event.option.value.code);
            } else {
                this.addAllocationForm.get('deliveryManager').setValue(event.option.value.name);
            }
            this.deliveryManagerName = event.option.value.name;
            this.sameResourceSelected = false;
        }
    }

    searchPseudoResource(searchValue) {
        this.allocateButtonStatus = false;
        if (searchValue) {
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }
            let localSearchValue = '';
            if (this.validateEmail(searchValue)) {
                localSearchValue = searchValue.substring(0, searchValue.lastIndexOf('@'));
            } else {
                localSearchValue = searchValue;
            }
            this.timeOut = setTimeout(() => {
                this.allocationsService.resourceQuickSearch(localSearchValue).subscribe(resourceList => {
                    this.pseudoNameList = resourceList['_embedded']['itrack:resourceListItemList'];
                    this.pseudoNameList.forEach((element, i) => {
                        this.allocationsService.getMethodRequest(element['_links']['image']['href']).subscribe((imgData: any) => {
                            this.resPseudoImageData = imgData;
                            this.pseudoNameList[i].image = imgData;
                        });
                    });
                });
            }, 1000);
        }
    }

    onPseudoResourceSelection(event) {
        this.pseudoNameList.forEach(element => {
            if (element.code === event.option.value.code) {
                this.pseudoResourceCode = element.code;
                this.pseudoResourceList = element.name;
            }
        });
        this.addAllocationForm.get('PseudoresourceValue').setValue(event.option.value.name);
        this.pseudoNameList = [];
    }

    onBlurMethod() {
        setTimeout(() => {
            if (!this.allocateButtonStatus) {
                this.addAllocationForm.get('resourceValue').setValue('');
            }
        }, 1000);
    }

    onBlurClient() {
        if (!this.clientSelectedStatus) {
            this.addAllocationForm.get('clientId').setValue('');
        }
    }

    onBlurName() {
        if (!this.resourceSelectedStatus) {
            this.addAllocationForm.get('PseudoName').setValue('');
        }
    }

    onClickAddHrAllocationDetails(){ 
      let clickedResource=  this.getHRAllocations.filter(res => res.resourceName===this.addAllocationForm.get('resourceValue').value)
      this.addAllocationForm.get('occupancyValue').setValue(clickedResource[0].occupancy);
      this.addAllocationForm.get('startDatePicker').setValue(clickedResource[0].startDate);
      this.addAllocationForm.get('EndDatePicker').setValue(clickedResource[0].endDate);
      this.idOfHrAllocationDeletion = clickedResource[0].id;
      this.isPendingAllocationError=false;
    }

    onResourceSelection(event, index?) {
        let splitedEmail = event.option.value.emailId.split('@gspann.com')[1];
        this.isPendingAllocationError = false;
        this.resourceAvailableInPendingAllocation = false;
        this.HRAllocatedResourceStartDate = '';
        this.HRAllocatedResourceEndDate = '';
        this.selectedResourceName = event.option.value.name;
        if (splitedEmail !== '') {
            alert("You have selected an Exited resource.");
            if (this.bulkAllocationChecked) {
                this.addbulkForm.get('bulkAllocationForm')['controls'][index].get('resourceName').setValue('');
            } else {
                this.addAllocationForm.get('resourceValue').setValue('');
            }
            return;
        }
        this.allocateButtonStatus = true;
        this.resImageData = '';
        this.resourceSelected = event.option.value.code;
        if (this.selectedDeliveryManager && (this.selectedDeliveryManager === this.resourceSelected)) {
            this.sameResourceSelected = true;
            this.addAllocationForm.get('deliveryManager').setValue('');
            if (this.bulkAllocationChecked) {
                this.addbulkForm.controls['bulkAllocationForm'].get('resourceName').setValue('');
            } else {
                this.addAllocationForm.get('resourceValue').setValue('');
            }
            this.deliveryManagerName = '';
            this.selectedDeliveryManager = '';
            return;
        } else {
            this.sameResourceSelected = false;
        }
        this.resourcesList.forEach(element => {
            if (element.code === event.option.value.code) {
                this.resourceSelectedCode = element.code;
                this.resourceValue = element.name;
                this.allocationsService.resourceInfo(this.resourceSelectedCode).subscribe(resource => {
                    this.allocationsService.getMethodRequest(element['_links']['itrack:image']['href']).subscribe((imgData: any) => {
                        this.resImageData = imgData;
                    });
                    this.resourceInfo = {
                        name: resource.identity.name,
                        code: resource.identity.code,
                        company: resource.company.value,
                        department: resource.department.value,
                        designation: resource.designation.value,
                        employmentStatus: resource.employmentStatus.statusType.value,
                        employmentStatusKey: resource.employmentStatus.statusType.key,
                        actualJoiningDate: this.datePipe.transform(resource.employmentStatus.actualJoiningDate, DATE_FORMATE),
                        utilization: resource.utilization,
                        utilizationType: resource.utilizationType,
                        employmentType: resource.employmentType,
                        emailId: element.emailId,
                        currentAllocations: resource.allocations
                    };
                    let practices = '';
                    let technologies = '';
                    resource.practices.forEach(x => {
                        practices.length ? (practices = practices + ', ' + x.value) : (practices = x.value);
                    });
                    this.resourceInfo.practices = practices;
                    resource.technologies.forEach(y => {
                        technologies.length ? (technologies = technologies + ', ' + y.value) : (technologies = y.value);
                    });
                    this.resourceInfo.technologies = technologies;
                });
                if (this.bulkAllocationChecked) {
                    this.addbulkForm.get('bulkAllocationForm')['controls'][index].get('resourceName').setValue(event.option.value.name);
                    this.addbulkForm.get('bulkAllocationForm')['controls'][index].get('resourceCode').setValue(event.option.value.code);
                    this.addbulkForm.controls['bulkAllocationForm'].value
                    this.resourceInfo = {};
                } else {
                    this.addAllocationForm.get('resourceValue').setValue(event.option.value.name);
                }
            }
        });
        if (this.bulkAllocationChecked) {
            let props = ['resourceName','resourceCode'];
        let addBulkForm = this.addbulkForm.value.bulkAllocationForm;
        let bulkStartDate = this.addbulkForm.value.fromDate;
        let bulkTillDate = this.addbulkForm.value.tillDate;
        let result = this.getHRAllocations.filter(function(o1){
            // filter out (!) items in result2
            return addBulkForm.some(function(o2){
                return o1.resourceCode === o2.resourceCode;
            });
        }).map(function(o){
            // use reduce to make objects with only the required properties
            // and map to apply this to the filtered array as a whole
            return props.reduce(function(newo, resourceName){
                newo[resourceName] = o[resourceName];
                return newo;
            }, {});
        });
        if (result.length) {
            this.listOfPBAllocationNames = result;
            this.resultLength = result.length;
            let isStartDateOverlappingHRAllocation, isEndDateOverlappingHRAllocation;
            // TODO If name match then set this var this.resourceAvailableInPendingAllocation to true
            // And set the name of resource in this.selectedResourceNameBulkAllocation
            this.resourceAvailableInPendingAllocation = true;
            this.selectedResourceNameBulkAllocation = result[0]['resourceName'];
            this.selectedResourceCodeBulkAllocation = result[0]['resourceCode'];
                this.getHRAllocations.map(item => {
                    if (item.resourceCode === this.selectedResourceCodeBulkAllocation) {
                        this.HRAllocatedResourceStartDate = moment(item.startDate).format("YYYY-MM-DD");
                        this.HRAllocatedResourceEndDate = moment(item.endDate).format("YYYY-MM-DD");
                        if (bulkStartDate || bulkTillDate) {
                            let shouldCheckWithEndDate = moment(item.endDate).isAfter(this.HRAllocatedResourceEndDate);
                            let startDateInForm = moment(this.addbulkForm.value.fromDate).format('YYYY-MM-DD');
                            if (item.startDate === this.HRAllocatedResourceStartDate) {
                                item.startDate = moment(item.startDate).add(1, 'day').format('YYYY-MM-DD');
                            } else if (item.startDate === this.HRAllocatedResourceEndDate) {
                                item.startDate = moment(item.startDate).subtract(1, 'day').format('YYYY-MM-DD');
                            }
                            isStartDateOverlappingHRAllocation = moment(item.startDate).isBetween(this.HRAllocatedResourceStartDate, this.HRAllocatedResourceEndDate);
                            if (moment(item.endDate).isSame(this.HRAllocatedResourceEndDate)) {
                                item.endDate = moment(item.endDate).subtract(1, 'day').format('YYYY-MM-DD');
                            } else if (moment(item.endDate).isSame(this.HRAllocatedResourceStartDate)) {
                                item.endDate = moment(item.endDate).add(1, 'day').format('YYYY-MM-DD');
                            }
                            if (shouldCheckWithEndDate) {
                                isEndDateOverlappingHRAllocation = moment(this.HRAllocatedResourceEndDate).isBetween(startDateInForm, item.endDate);
                            } else {
                                isEndDateOverlappingHRAllocation = moment(item.endDate).isBetween(this.HRAllocatedResourceStartDate, this.HRAllocatedResourceEndDate);
                            }
                            if (isStartDateOverlappingHRAllocation || isEndDateOverlappingHRAllocation) {
                                this.isPendingAllocationBulkError = true;
                            } else {
                                this.isPendingAllocationBulkError = false;
                            }
                    }}});
            // }
                } else {
            // This is else block
                this.isPendingAllocationBulkError = false;
                this.selectedResourceNameBulkAllocation = '';
            }
        } else {
            let allocationStartDate = this.addAllocationForm.get('startDatePicker').value;
            let allocationEndDate = this.addAllocationForm.get('EndDatePicker').value;
            this.getHRAllocations.map(item => {
                if (item.resourceCode === event.option.value.code) {
                    this.resourceAvailableInPendingAllocation = true;
                        this.HRAllocatedResourceStartDate = moment(item.startDate).format("YYYY-MM-DD");
                        this.HRAllocatedResourceEndDate = moment(item.endDate).format("YYYY-MM-DD");
                    if (allocationStartDate || allocationEndDate) {
                        this.startDateChange(undefined,allocationStartDate);
                        this.endDateChange(undefined,allocationEndDate);
                    }
                }
            });
        }  
        this.resourcesList = [];
    }

    startDateChange (event, allocationPendingStartDate?) {
        this.checkForHRAllocationEndDate = false;
        let startDate = event ? moment(event.value).format("YYYY-MM-DD") : moment(allocationPendingStartDate).format('YYYY-MM-DD');
        // let startDate = moment(event.value).format("YYYY-MM-DD");
        let shouldCheckWithStartDate = moment(startDate).isBefore(this.HRAllocatedResourceStartDate);

        if (!this.resourceSelectedFromPendingAllocation) {    
            if (this.resourceAvailableInPendingAllocation) {
                let isStartDateOverlappingHRAllocation
                if (!shouldCheckWithStartDate) {
                    if (moment(startDate).isBetween(this.HRAllocatedResourceStartDate, this.HRAllocatedResourceEndDate) || moment(startDate).isSame(this.HRAllocatedResourceStartDate) || moment(startDate).isSame(this.HRAllocatedResourceEndDate)) {
                        isStartDateOverlappingHRAllocation = true;
                    }
                }
                if (isStartDateOverlappingHRAllocation) {
                    this.isPendingAllocationError = true;
                } else {
                    this.checkForHRAllocationEndDate = true
                }
            }
        }
    }

    endDateChange (event,allocationPendingEndDate) {
        if (!this.resourceSelectedFromPendingAllocation){
            if (this.resourceAvailableInPendingAllocation && this.checkForHRAllocationEndDate) {
                let isEndDateOverlappingHRAllocation;
                // let endDate = moment(event.value).format("YYYY-MM-DD");
                let endDate = event ? moment(event.value).format("YYYY-MM-DD") : moment(allocationPendingEndDate).format('YYYY-MM-DD');
                let startDateInForm = moment(this.addAllocationForm.get('startDatePicker').value).format('YYYY-MM-DD');
                let shouldCheckWithEndDate = moment(endDate).isAfter(this.HRAllocatedResourceEndDate);

                if (moment(endDate).isSame(this.HRAllocatedResourceEndDate)) {
                    endDate = moment(endDate).subtract(1, 'day').format('YYYY-MM-DD');
                } else if (moment(endDate).isSame(this.HRAllocatedResourceStartDate)) {
                    endDate = moment(endDate).add(1, 'day').format('YYYY-MM-DD');
                }

                if (shouldCheckWithEndDate) {
                    isEndDateOverlappingHRAllocation = moment(this.HRAllocatedResourceEndDate).isBetween(startDateInForm, endDate);
                } else {
                    isEndDateOverlappingHRAllocation = moment(endDate).isBetween(this.HRAllocatedResourceStartDate, this.HRAllocatedResourceEndDate);
                }
    
                if (isEndDateOverlappingHRAllocation) {
                    this.isPendingAllocationError = true;
                } else {
                    this.isPendingAllocationError = false;
                }
            }
        }
    }

    bulkAllocationStartDateChange (event) {
        this.checkForHRAllocationEndDateBulk = false;
        let startDate = moment(event.value).format("YYYY-MM-DD");
        let shouldCheckWithStartDate = moment(startDate).isBefore(this.HRAllocatedResourceStartDate);
        let isStartDateOverlappingHRAllocation
        if (!this.resourceSelectedFromPendingAllocation) {
            if (this.resourceAvailableInPendingAllocation) {
                if (startDate === this.HRAllocatedResourceStartDate) {
                    startDate = moment(startDate).add(1, 'day').format('YYYY-MM-DD');
                } else if (startDate === this.HRAllocatedResourceEndDate) {
                    startDate = moment(startDate).subtract(1, 'day').format('YYYY-MM-DD');
                }
                    isStartDateOverlappingHRAllocation = moment(startDate).isBetween(this.HRAllocatedResourceStartDate, this.HRAllocatedResourceEndDate);
    
                if (isStartDateOverlappingHRAllocation) {
                    this.isPendingAllocationBulkError = true;
                } else {
                    this.checkForHRAllocationEndDateBulk = true
                }
            }
        }
    }

    bulkAllocationEndDateChange (event) {
        if (!this.resourceSelectedFromPendingAllocation) { 
            if (this.resourceAvailableInPendingAllocation && this.checkForHRAllocationEndDateBulk) {
                let endDate = moment(event.value).format("YYYY-MM-DD");
                let startDateInForm = moment(this.addbulkForm.value.fromDate).format('YYYY-MM-DD');
                let isEndDateOverlappingHRAllocation;
                let shouldCheckWithEndDate = moment(endDate).isAfter(this.HRAllocatedResourceEndDate);

                if (endDate === this.HRAllocatedResourceEndDate) {
                    endDate = moment(endDate).subtract(1, 'day').format('YYYY-MM-DD');
                } else if (endDate === this.HRAllocatedResourceStartDate) {
                    endDate = moment(endDate).add(1, 'day').format('YYYY-MM-DD');
                }

                if (shouldCheckWithEndDate) {
                    isEndDateOverlappingHRAllocation = moment(this.HRAllocatedResourceEndDate).isBetween(startDateInForm, endDate);
                } else {
                    isEndDateOverlappingHRAllocation = moment(endDate).isBetween(this.HRAllocatedResourceStartDate, this.HRAllocatedResourceEndDate);
                }
    
                if (isEndDateOverlappingHRAllocation) {
                    this.isPendingAllocationBulkError = true;
                } else {
                    this.isPendingAllocationBulkError = false;
                }
            }
        }
    }

    paginationFilter(totalRecords, totalPages) {
        if (this.page && this.page === (totalPages - 1)) {
            if (totalRecords % this.paginationSelection === 0) {
                this.page = (totalRecords / this.paginationSelection) - 1;
            } else {
                this.page = Math.floor(totalRecords / this.paginationSelection);
            }
        } else if (this.page && this.page !== (totalPages - 1)) {
            if (Math.floor(totalRecords / this.paginationSelection) < this.page) {
                this.page = Math.floor(totalRecords / this.paginationSelection);
            }
        }
        this.getAllocationsList();
    }
    search() {
        this.page = 0;
        this.paginationSelection = 6;
        this.getAllocationsList();
    }

    goToPreviousPage(): void {
        if (this.currentPage >= 1) {
            this.currentPage= this.currentPage-1;
          this.getAllocationsList();
        }
      }
    
      goToNextPage(): void {
        if (this.currentPage < this.totalPages) {
           this.currentPage= this.currentPage+1;
           this.getAllocationsList();
        }
      }
      goToLastPage(): void {
          this.currentPage = this.totalPages-1;
          this.getAllocationsList();
      }
      goToFirstPage():void {
        this.currentPage = 0;
        this.getAllocationsList();
      }
    
     
      goToPage(page: string): void {
          this.itemsPerPage = parseInt(page, 10);
          this.currentPage = 0;
          this.getAllocationsList();
        
      }

    getAllocationsList() {
        this.tableDataParams = { };
        this.searchTableData = [];
        this.createSearchTableData();
        if (this.isHRAllocation) {
            if (this.HRAllocatedResourceCode) {
                this.tableDataParams['resource']=  this.HRAllocatedResourceCode;
            }
        }
        if (this.resourceValue && this.resourceSelectedCode && this.resourceValue) {
            this.tableDataParams['resource']=  this.commonService.encrypt(this.resourceSelectedCode);
        }
        if (this.paginationSelection) {
            this.tableDataParams['size'] = this.paginationSelection;
        }
        if (this.page > 0) {
            this.tableDataParams['page']= this.page;
        }
        this.tableDataParams['isAllocationInfo'] = false;
        console.log(this.tableDataParams)

        this.allocationsService.searchAllocationsListing(this.tableDataParams,this.currentPage,this.itemsPerPage).subscribe(searchMD => {
            this.resourceCodes = [];
            if (searchMD && searchMD.content && searchMD.page) {
                let startingIndex = searchMD.page.currentPage * searchMD.page.pageSize;
                let endingIndex;
                if ((startingIndex + searchMD.page.pageSize) > searchMD.page.totalRecords) {
                    endingIndex = searchMD.page.totalRecords;
                } else {
                    endingIndex = startingIndex + searchMD.page.pageSize;
                }
                //searchMD.content = searchMD.content.slice(startingIndex, endingIndex);
            }
            searchMD.content.forEach(element => {
                let check = true;
                this.resourceCodes.forEach(x => {
                    if (element.resource.code === x.key) {
                        check = false;
                    }
                });
                if (check) {
                    this.resourceCodes.push({ key: element.resource.code, image: element.resource.image });
                }
            });

            this.searchTableData = searchMD.content;
            this.searchTableData.forEach(element => {
                element.wbs.allocationType.img = '../../assets/' + element.wbs.allocationType.value.toLowerCase() + '.png';
            });

            this.searchTableDataPage = searchMD.page;
            this.totalItems = searchMD.totalElements;
            this.totalPages = searchMD.totalPages;
        });
    }

    searchSows(event) {
        if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 65 && event.keyCode <= 90) {
            this.sowSearchQuery += event.key;
        }

        if (event.keyCode === 32) {
            this.sowSearchQuery += ' ';
            event.stopImmediatePropagation();
        }

        if (event.keyCode === 8) {
            this.sowSearchQuery = this.sowSearchQuery.substring(0, this.sowSearchQuery.length - 1);
        }
        if (!this.sowSearchQuery) {
            this.sowOptions = this.originalSows;
            return;
        }
        let result: string[] = [];
        for (let list of this.originalSows) {
            if (list.sowNumber) {
                if (list.sowNumber.toLowerCase().indexOf(this.sowSearchQuery.toLowerCase()) > -1) {
                    result.push(list);
                }
            } else {
                if (list.toLowerCase().indexOf(this.sowSearchQuery.toLowerCase()) > -1) {
                    result.push(list);
                }
            }
        }
        this.sowOptions = result;
    }

    autoPopulateEmail(searchParam) {
        if (searchParam) {
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }
            this.timeOut = setTimeout(() => {
                this.resourceService.autoPopulateEmail(searchParam).subscribe(emailList => {
                    this.emailList = emailList;
                });
            }, 1000);
        } else {
            this.emailList = [];
        }
    }

    onSubmit() {

        this.isSubmitClicked = true;
        setTimeout(() => this.isSubmitClicked = false, 2000);
            let joiningDate = this.resourceInfo.actualJoiningDate;
            let sowStartDate = this.datePipe.transform(this.addAllocationForm.get('startDatePicker').value, DATE_FORMATE);
            if (joiningDate <= sowStartDate) {
                if (this.addAllocationForm.get('pseudoResource').value === true) {
                    this.requestPayload['clientId'] = this.addAllocationForm.get('clientId').value;
                    this.requestPayload['pseudoResourceId'] = this.pseudoResourceCode;
                    this.requestPayload['pseudoResource'] = this.addAllocationForm.get('pseudoResource').value;
                    this.requestPayload['pseudoFrom'] = this.datePipe.transform(this.addAllocationForm.get('pseudoFromPicker').value, DATE_FORMATE);
                    this.requestPayload['pseudoTo'] = this.datePipe.transform(this.addAllocationForm.get('pseudoToPicker').value, DATE_FORMATE);
                } else {
                    this.requestPayload['clientId'] = '';
                    this.requestPayload['pseudoResourceId'] = '';
                    this.requestPayload['pseudoResource'] = false;
                }
                this.requestPayload['clientTimeTrackingEnabled'] = this.addAllocationForm.get('clientTS').value ? true : false;
                this.requestPayload['flexibleWeekendsEnabled'] = this.addAllocationForm.get('flexibleWE').value ? true : false;
                this.requestPayload['fromDate'] = this.datePipe.transform(this.addAllocationForm.get('startDatePicker').value, DATE_FORMATE);
                this.requestPayload['occupancy'] = this.addAllocationForm.get('occupancyValue').value;
                this.requestPayload['clientResourceId'] = this.addAllocationForm.get('customerClientID').value;
                this.requestPayload['clientResourceName'] = this.addAllocationForm.get('customerClientName').value;
                if(this.isHRAllocation) {
                    this.requestPayload['resourceCode'] = this.commonService.encrypt(this.HRAllocatedResourceCode);
                    this.requestPayload['allocationHrId'] = this.deleteHRAllocationID; 
                } else {
                    this.requestPayload['resourceCode'] = this.commonService.encrypt(this.resourceSelectedCode);
                    this.requestPayload['allocationHrId'] = 0;
                }
                this.requestPayload['tillDate'] = this.datePipe.transform(this.addAllocationForm.get('EndDatePicker').value, DATE_FORMATE);
                this.requestPayload['wbsId'] = this.sow.wbs.wbsId;
                if (this.addAllocationForm.get('deliveryManager').value) {
                    this.requestPayload['delegateManager'] = this.commonService.encrypt(this.selectedDeliveryManager);
                }
                this.requestPayload['updateFromActuallAllocation'] = true;
                // TODO commentd on purpose to be re-added in future.
                
                // if (this.occupancyFromHRAllocation === this.addAllocationForm.get('occupancyValue').value) {
                //     this.requestPayload['isOccupancyChanged'] = false;
                // } else {
                //     this.requestPayload['isOccupancyChanged'] = true;
                // }  
                let checkResourceExitInHrAllocations = this.getHRAllocations.filter(res => res.id === this.idOfHrAllocationDeletion);
                 // deleting the HR Allocation 
                this.allocationsService.addAllocation(this.requestPayload).subscribe(response => {
                    setTimeout(() => {
                        this.load = false;
                        }, 1000);
                    if(checkResourceExitInHrAllocations.length > 0)
                     this.allocationsService.deleteAllocationHr(this.idOfHrAllocationDeletion).subscribe(response => { });
                    
                    if (this.allocateFromProject === true) {
                        this.router.navigate([`projects/view-project/`], {skipLocationChange: true});
                    } else {
                        this.router.navigate(['/Allocations/listings-allocations'],{skipLocationChange: true});
                    }
                },  (err=>{
                    this.load = false;
                    }));
            } else {
                alert(
                    'Allocation start date: ' + sowStartDate + ' can not be before ' + this.resourceInfo.name + "'s Actual Joining date: " + joiningDate
                );
            }
                    
    }
    ngAfterViewInit() {
        this.sowListFiltered
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.singleSelect.compareWith = (a: any, b: any) => {
                    return a && b && a === b;
                };
            });
        this.accountListFiltered
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.singleSelect.compareWith = (a: any, b: any) => {
                    return a && b && a === b;
                };
            });
        this.associatedProjectsListFiltered
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.singleSelect.compareWith = (a: any, b: any) => {
                    return a && b && a === b;
                };
            });
        this.allocationsOptionsListFiltered
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.singleSelect.compareWith = (a: any, b: any) => {
                    return a && b && a === b;
                };
            });
        this.resourcesOptionsListFiltered
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.singleSelect.compareWith = (a: any, b: any) => {
                    return a && b && a === b;
                };
            });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    protected accountFilterList() {
        if (!this.accounts) {
            return;
        }
        // get the search keyword
        let search = this.accountSelectSearchFilterCtrl.value;
        if (!search) {
            this.accountListFiltered.next(this.accounts.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the list
        this.accountListFiltered.next(
            this.accounts.filter(obj => obj.name.toLowerCase().indexOf(search) > -1)
        );
    }
    protected sowFilterList() {
        if (!this.sowOptions) {
            return;
        }
        // get the search keyword
        let search = this.sowSelectSearchFilterCtrl.value;
        if (!search) {
            this.sowListFiltered.next(this.sowOptions.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the list
        this.sowListFiltered.next(
            this.sowOptions.filter(obj => obj.sowNumber.toLowerCase().indexOf(search) > -1 || (obj.startDate != null && obj.startDate.indexOf(search) > -1))
        );
    }
    protected associatedProjectsFilterList() {
        if (!this.sow.associatedProjects) {
            return;
        }
        // get the search keyword
        let search = this.associatedProjectsSelectSearchFilterCtrl.value;
        if (!search) {
            this.associatedProjectsListFiltered.next(this.sow.associatedProjects.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the list
        this.associatedProjectsListFiltered.next(
            this.sow.associatedProjects.filter(obj => obj.value.toLowerCase().indexOf(search) > -1)
        );
    }
    protected allocationsOptionsFilterList() {
        if (!this.sow.allocationsOptions) {
            return;
        }
        // get the search keyword
        let search = this.allocationsOptionsSelectSearchFilterCtrl.value;
        if (!search) {
            this.allocationsOptionsListFiltered.next(this.sow.allocationsOptions.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the list
        this.allocationsOptionsListFiltered.next(
            this.sow.allocationsOptions.filter(obj => obj.value.toLowerCase().indexOf(search) > -1)
        );
    }
    protected resourcesOptionsFilterList() {
        if (!this.sow.resourcesOptions) {
            return;
        }
        // get the search keyword
        let search = this.resourcesOptionsSelectSearchFilterCtrl.value;
        if (!search) {
            this.sowListFiltered.next(this.sow.resourcesOptions.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the list
        this.resourcesOptionsListFiltered.next(
            this.sow.resourcesOptions.filter(obj => obj.resourceType.toLowerCase().indexOf(search) > -1)
        );
    }
    get bulkAllocationForm(): FormArray {
        return this.addbulkForm.get('bulkAllocationForm') as FormArray;
    }
    bulkProjectSelected(event, i) {
        this.sow.allocationsOptions = [];
        this.sow.resourcesOptions = [];
         /**Tooltip implemented  */
         const index = this.sow.associatedProjects.findIndex(x => x.key
            === event.value);
        if (index !== -1) {
            this.bulkselectedProject = this.sow.associatedProjects[index].value;
        } else {
            this.bulkselectedProject = 'all';
        }
        let iterate = [];
        iterate = this.metaData.availableWBSesAllocationMetaData;
        iterate.forEach((element) => {
            if (event.value && event.value !== 'all' && event.value === element.project.key) {
                element.wbsAllocationIdentities.forEach(x => {
                    if (x.allocationType) {
                        if (this.sow.allocationsOptions.length) {
                            let check = false;
                            for (let j = 0; j < this.sow.allocationsOptions.length; j++) {
                                if (x.allocationType.key === this.sow.allocationsOptions[j].key) {
                                    check = true;
                                }
                            }
                            if (!check) {
                                this.sow.allocationsOptions.push(x.allocationType);
                            }
                        } else {
                            this.sow.allocationsOptions.push(x.allocationType);
                        }
                        x.wbsIdentities.forEach(y => {
                            this.sow.resourcesOptions.push(y);
                        });
                    }
                });
            } else if (event.value && event.value === 'all') {
                element.wbsAllocationIdentities.forEach(x => {
                    if (x.allocationType) {
                        if (this.sow.allocationsOptions.length) {
                            let check = false;
                            for (let j = 0; j < this.sow.allocationsOptions.length; j++) {
                                if (x.allocationType.key === this.sow.allocationsOptions[j].key) {
                                    check = true;
                                }
                            }
                            if (!check) {
                                this.sow.allocationsOptions.push(x.allocationType);
                            }
                        } else {
                            this.sow.allocationsOptions.push(x.allocationType);
                        }
                    }
                    x.wbsIdentities.forEach(y => {
                        this.sow.resourcesOptions.push(y);
                    });
                });
            }
            this.addbulkForm.get('bulkAllocationForm')['controls'][i].get('allocationTypebulk').setValue('all');
            this.addbulkForm.get('bulkAllocationForm')['controls'][i].get('wbsId').setValue('all');
        });
    }

    bulkAllocationSelected(i) {
        this.resourceTypeList = [];
        let iterate = [];
        iterate = this.metaData.availableWBSesAllocationMetaData;
        iterate.forEach((element, index) => {
            if (this.addbulkForm.get('bulkAllocationForm')['controls'][i].get('projectSelectedValue').value === element.project.key) {
                element.wbsAllocationIdentities.forEach((x) => {
                    if (x.allocationType.key === this.addbulkForm.get('bulkAllocationForm')['controls'][i].get('allocationTypebulk').value) {
                        if (this.addbulkForm.get('bulkAllocationForm')['controls'][i].get('allocationTypebulk').value === 'BILLABLE') {
                            let billableData = [];
                            billableData.push(x.wbsIdentities);
                            this.BillresourceTypeList = billableData[0];
                        } else if (this.addbulkForm.get('bulkAllocationForm')['controls'][i].get('allocationTypebulk').value === 'NON_BILLABLE') {
                            let NbillableData = [];
                            NbillableData.push(x.wbsIdentities);
                            this.resourceTypeList = NbillableData[0];
                        } else if (this.addbulkForm.get('bulkAllocationForm')['controls'][i].get('allocationTypebulk').value === 'BENCH') {
                            let benchData = [];
                            benchData.push(x.wbsIdentities);
                            this.benchResourceTypeList = benchData[0];
                        } else if (this.addbulkForm.get('bulkAllocationForm')['controls'][i].get('allocationTypebulk').value === 'BUFFER') {
                            let bufferData = [];
                            bufferData.push(x.wbsIdentities);
                            this.bufferResourceTypeList = bufferData[0];
                        }
                    }
                });
            }
        });
    }
    addbulk(i) {
        document.getElementById(`add${i}`).style.display = 'none';
        let project = this.bulkAllocationForm.controls[0].get('projectSelectedValue').value;
        let allocationType = this.bulkAllocationForm.controls[0].get('allocationTypebulk').value;
        let delegateManager = this.bulkAllocationForm.controls[0].get('delegateManager').value;
        let delegateManagerCode = this.bulkAllocationForm.controls[0].get('deliveryManagerCode').value;
        let occupancy = this.bulkAllocationForm.controls[0].get('occupancy').value;
        
        this.bulkAllocationForm.push(new FormGroup({
            resourceName: new FormControl('', [Validators.required]),
            resourceCode: new FormControl(''),
            flexibleWeekendsEnabled: new FormControl(false),
            allocationTypebulk: new FormControl(allocationType, [Validators.required]),
            delegateManager: new FormControl(delegateManager, []),
            occupancy: new FormControl(occupancy, [Validators.required, Validators.min(1), Validators.max(100)]),
            clientTimeTrackingEnabled: new FormControl(''),
            projectSelectedValue: new FormControl(project, [Validators.required]),
            wbsId: new FormControl('', [Validators.required]),
            deliveryManagerCode: new FormControl(delegateManagerCode),
            hourlyRate: new FormControl('')
        }));
    }

    removeBulkRow(index) {
        this.bulkRemove = this.addbulkForm.get('bulkAllocationForm') as FormArray;
        this.bulkRemove.removeAt(this.bulkRemove.value.findIndex((image, i) => i === index));
        document.getElementById(`add${index - 1}`).style.display = 'inline';
        this.resourceAvailableInPendingAllocation = false;
    }
    formatNumber(value, code) {
        if(code) {
    
            if(value && !isNaN(Number(value))) {
                return Intl.NumberFormat(`en-${code === 'CAD' ? 'US' : code.slice(0,2)}`, {
                    style: 'currency',
                    currency: code,
                }).format(value);
            }
            if(value == 0) {
                return Intl.NumberFormat(`en-${code === 'CAD' ? 'US' : code.slice(0,2)}`, {
                    style: 'currency',
                    currency: code,
                }).format(0);
            }
        }else {
            return value;
        }
    }
}

@Component({
    selector: 'jhi-review-allocation-dialog',
    templateUrl: 'review-allocation-dialog.html',
})
export class ReviewAllocationDialog {
    constructor(public dialogRef: MatDialogRef<ReviewAllocationDialog>) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'jhi-allocation-pending-dialog',
    templateUrl: 'allocation-pending.component.html',
    styleUrls: ['./add-allocation.component.scss'],
})
export class AllocationPendingComponent implements OnInit, AfterViewInit {
    globalSearch: any;
    constructor(public dialogRef: MatDialogRef<AllocationPendingComponent>, private allocationsService: AllocationsService, @Inject(MAT_DIALOG_DATA) public data: any,private resourceService: ResourceService) { }
    HRAllocationsDataSource  = new MatTableDataSource([]);
    HRAllocationsDisplayedColumns = ['resourceName','accontName','projectName','allocationType','startDate','endDate','occupancy','remark'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    resourcesList: any;
    timeOut = null;
    isAllocationPending = false
    closeModalPopup(): void {
        this.dialogRef.close();
    }
    ngOnInit() {
        this.HRAllocationsDataSource = new MatTableDataSource(this.data);
    }
    ngAfterViewInit() {
         this.HRAllocationsDataSource.paginator = this.paginator;
    }
    exportHRAllocation() {
        this.allocationsService.exportHRAllocations().subscribe(response=> {
            saveAs(response,'HR-Allocations.xlsx');
        });
    }
    pendingCountResourceClicked(row) {
        this.dialogRef.close(row);
    }
    searchResourceInHRA(event) {
        let filterValue = (event.target as HTMLInputElement).value;
        this.HRAllocationsDataSource.filter = filterValue.trim().toLowerCase();
    }
    searchResource(searchValue) {
        this.globalSearch = searchValue;
        if (searchValue) {
            this.allocationsService.quickSearchOnlyActiveResource(searchValue).subscribe(resourceList => {
                this.resourcesList = resourceList;
                this.isAllocationPending = true;
            }, (err)=>{
                this.isAllocationPending = false;
            });
        this.HRAllocationsDataSource.filter = searchValue.trim().toLowerCase();
        } else {
            this.resourcesList = [];
            this.HRAllocationsDataSource.filter = '';
        }
    }
}