<div id='main-cont'>
    <div class="row heading-row">
        <div class="col-lg-6 col-md-4 col-sm-12">
            <h2 id='main-heading'>Add Allocation</h2>
        </div>
        <div class="checkboxbulk col-lg-6 col-md-4 col-sm-12" *ngIf="showAllocationCheckBox">
            <mat-checkbox [(ngModel)]="bulkupdated" (change)="bulk($event)">Bulk Allocation
            </mat-checkbox>
        </div>
    </div>
    <p class="error-message error-message-acount-change" *ngIf="showErrorOnAddAllocation">Account name has been changed for the <b>{{resourceNameSelectedPendingAllocation}}</b>. Please delete same record from HR Allocation to continue or select again from pending allocation.</p>
    <mat-card>
        <mat-card-content>
            <form class="sow-listing-form" [formGroup]="addAllocationForm" novalidate autocomplete="off">
                <div class="row">
                    <div class="col-md-3" *ngIf="!allocateFromProject">
                        <p>
                            <mat-form-field>
                                <mat-select formControlName="accountsValue" placeholder="Account"
                                    (selectionChange)="accountSelected($event.value, true)" [disabled]="allocateFromProject"
                                    #singleSelect>
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="accountSelectSearchFilterCtrl"
                                            placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-divider></mat-divider>
                                    <mat-option value="all">Select One</mat-option>
                                    <mat-option *ngFor="let account of accountListFiltered | async"
                                        [value]="account.code">
                                        {{account.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addAllocationForm.get('accountsValue').hasError('required')">This
                                    is required field</mat-error>
                            </mat-form-field>
                        </p>
                    </div>
                    <div class="col-md-3" *ngIf="allocateFromProject">
                        <p><span class="detailslabel">Account : </span> <b>{{accountFromProject}}</b></p>
                    </div>
                    <div class="col-md-3">
                        <p [ngClass]="{'sow-select': true}">
                            <mat-form-field>
                                <mat-select #mySelect formControlName="sowselectedOption" placeholder="SOW"
                                    (selectionChange)="sowSelected($event)" #singleSelect required>
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="sowSelectSearchFilterCtrl"
                                            placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-divider></mat-divider>
                                    <mat-option *ngIf="sowOptions && sowOptions.length" value="all">Select One
                                    </mat-option>
                                    <mat-option *ngFor="let sow of sowListFiltered | async" [value]="sow"
                                        [ngStyle]="{'color':!sow.parentSowId ? '#2b8fb9':''}"
                                        matTooltip="SOW Name: {{sow.sowNumber}}, Remaining Hours: {{sow.totalRemainingHours}}, Available WBS Count: ({{sow.availableWBSCount}}), SOW Start Date: {{sow.startDate | date: DEFAULT_DATE_FORMAT}}, SOW End Date: {{sow.endDate | date: DEFAULT_DATE_FORMAT}}"
                                        matTooltipPosition="right">
                                        <span
                                            *ngIf="sow.parentSowId && sow.sowId!='all'">&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                                            *ngIf="sow.sowId!='all'">{{sow.sowNumber}},
                                            {{sow.totalRemainingHours}}
                                            ({{sow.availableWBSCount}})</span>
                                        <span *ngIf=" sow.sowId=='all'">{{sow.sowNumber}}</span>
                                        <span *ngIf=" sow.startDate && sow.sowId!='all'">{{sow.startDate | date:
                                            DEFAULT_DATE_FORMAT}}</span>
                                        <span *ngIf=" !sow.startDate && sow.sowId!='all'">-</span>
                                        <span *ngIf=" sow.sowId!='all'">&nbsp;to&nbsp;</span>
                                        <span *ngIf=" sow.endDate && sow.sowId!='all'">{{sow.endDate | date:
                                            DEFAULT_DATE_FORMAT}}</span>
                                        <span *ngIf=" !sow.endDate && sow.sowId!='all'">-</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </p>
                    </div>
                    <div class="col-md-3"></div>
                    <ng-template [jhiHasAnyAuthority]="'ALLOCATION_PENDING'">
                    <div class="col-md-3" style="float: right;" *ngIf="pendingAllocationCount > 0">
                        <button [disabled]="pendingAllocationCount==0" [ngClass]="{'btn btn-outline-primary': true}"
                            id="allocationPending" (click)="openHRAllocation()">Allocation Pending
                            <span class="badge badge-secondary">{{ pendingAllocationCount }}</span>
                        </button>
                    </div>
                    </ng-template>
                    <div class="row heading-text" *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'">
                        <h2>SOW Details</h2>
                    </div>
                    <div class="col-md-3" *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'">
                        <p><span class="detailslabel">Account : </span> <b>{{sow.account}}</b></p>
                    </div>
                    <div class="col-md-3" *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'">
                        <p><span class="detailslabel">SOW Name : </span> <b>{{sow.sowNumber}}</b></p>
                    </div>
                    <div class="col-md-3" *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'">
                        <p><span class="detailslabel">Start Date : </span>
                            <b>{{sow.start_date | date: DEFAULT_DATE_FORMAT}}</b>
                        </p>
                    </div>
                    <div class="col-md-3" *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'">
                        <p><span class="detailslabel">End Date : </span>
                            <b>{{sow.end_date | date: DEFAULT_DATE_FORMAT}}</b>
                        </p>
                    </div>
                    <div class="col-md-3" *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'">
                        <p><span class="detailslabel">Total Bill : </span>
                            <b>{{formatNumber(sow.total_remaining,sow.currency)}}
                                ({{formatNumber(sow.total_bill,sow.currency)}}
                                - {{formatNumber(sow.total_consumed,sow.currency)}})</b>
                        </p>
                        <p
                            [ngClass]="{ 'red-progress': 74 <= totalBillPercentage, 'green-progress': 74 > totalBillPercentage }">
                            <ngb-progressbar type="info" mat-raised-button
                                matTooltip="Contracted Hours: {{sow.total_bill}}, Consumed Hours: {{sow.total_consumed}}, Consumed Hours Percentage: {{totalBillPercentage}}%"
                                [value]="totalBillPercentage">
                                <!-- <i *ngIf="totalBillPercentage">{{totalBillPercentage}}%</i> -->
                            </ngb-progressbar>
                        </p>
                    </div>
                    <div class="col-md-3" *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'">
                        <p><span class="detailslabel">Misc Bill : </span> <b>{{formatNumber(sow.miscBill,sow.currency)}}
                            </b></p>
                    </div>
                    <div class="col-md-4" *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'">
                        <mat-form-field>
                            <textarea class="remarkArea" matInput placeholder="Remarks" maxlength="255"
                                readonly="true">{{sow.remarks}}</textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2" *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'">
                    </div>
                    <div *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'"
                        [ngClass]="{ 'col-md-6': true, 'red-progress': 74 <= calPercentage(sow.billable_hrs,sow.billable_hrs_consumed), 'green-progress': 74 > calPercentage(sow.billable_hrs,sow.billable_hrs_consumed) }">
                        <p><span class="detailslabel">Total Billable Hours : </span> <b>{{sow.billable_hrs_remaining}}
                                ({{sow.billable_hrs}}
                                -{{sow.billable_hrs_consumed}})</b></p>
                        <p>
                            <ngb-progressbar type="info" [value]="billableHoursPercentage" mat-raised-button
                                matTooltip="Billable Hours: {{sow.billable_hrs}}, Consumed Hours: {{sow.billable_hrs_consumed}}, Consumed Hours Percentage: {{billableHoursPercentage}}%"
                                aria-label="Billable Hours Info">
                            </ngb-progressbar>
                        </p>
                    </div>
                    <div *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'"
                        [ngClass]="{ 'col-md-6': true, 'red-progress': 90 <= calPercentage(sow.contracted_hrs,sow.contracted_hrs_consumed), 'green-progress': 90 > calPercentage(sow.contracted_hrs,sow.contracted_hrs_consumed)}">
                        <p><span class="detailslabel">Total Contracted Hours : </span>
                            <b>{{sow.contracted_hrs_remaining}}
                                ({{sow.contracted_hrs}} - {{sow.contracted_hrs_consumed}})</b>
                        </p>
                        <p>
                            <ngb-progressbar type="info" [value]="contractedHoursPercentage" mat-raised-button
                                matTooltip="Contracted Hours: {{sow.contracted_hrs}}, Consumed Hours: {{sow.contracted_hrs_consumed}}, Consumed Hours Percentage: {{contractedHoursPercentage}}%}"
                                aria-label="Contracted Hours Info">
                                <!-- <i *ngIf="contractedHoursPercentage">({{contractedHoursPercentage}})%</i> -->
                            </ngb-progressbar>
                        </p>
                    </div>
                    <div *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'" class="col-md-3">
                        <p><span class="detailslabel">Total WBS Count : </span> <b>{{sow.total_wbs_count}}</b></p>
                    </div>
                    <div *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'" class="col-md-3">
                        <p><span class="detailslabel">Billable WBS Count : </span><b>{{sow.billable_wbs_count}}</b></p>
                    </div>
                    <div class="col-md-6" *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'"></div>
                </div>
                <div class="row"
                    *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all' && !bulkAllocationChecked">
                    <div class="row heading-text" *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all'">
                        <h2>WBS Details</h2>
                    </div>
                    <div class="col-md-6">
                        <div class="btn-group weekly-monthly-toggle" role="group" aria-label="Basic example">
                            <button type="button" [ngClass]="{'active': loadCur, 'btn': true, 'btn-secondary' : true}"
                                (click)="loadCurrent();">Available
                                ({{sow.avilableWbsCount}})</button>
                            <button type="button" [ngClass]="{'active': loadPrev, 'btn': true, 'btn-secondary' : true}"
                                (click)="loadPrevious();">Allocated
                                ({{sow.allottedWbsCount}})</button>
                            <button type="button" [ngClass]="{'active': loadPart, 'btn': true, 'btn-secondary' : true}"
                                (click)="loadPartial();">Partially Allocated
                                <!-- ({{sow.partialavailableWbsCount}})</button> -->
                                ({{sow.partialAvailableWbsCount}})
                            </button>
                        </div>
                    </div>
                    <div class="col-md-9"></div>
                </div>
                <p></p>
                <p></p>
                <div class="row"
                    *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all' && ( (sow.availableWBSesAllocationMetaData && sow.availableWBSesAllocationMetaData.length) || (sow.allottedWBSesAllocationMetaData && sow.allottedWBSesAllocationMetaData.length) || (sow.partialAvailableWBSesAllocationMetaData && sow.partialAvailableWBSesAllocationMetaData.length) ) && !bulkAllocationChecked">
                    <div class="col-md-3">
                        <p>
                            <mat-form-field>
                                <mat-select formControlName="projectSelectedValue" placeholder="Project"
                                    matTooltip="{{selectedProject === 'all' ? '' : selectedProject}}"
                                   
                                    matTooltipClass="project-tooltip"
                                    (selectionChange)="projectSelected($event.value)" required class="allocationDropPanel"
                                    #singleSelect>
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="associatedProjectsSelectSearchFilterCtrl"
                                            placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-divider></mat-divider>
                                    <mat-option *ngIf="sow.associatedProjects && sow.associatedProjects.length"
                                        value="all">Select
                                        One</mat-option>
                                    <mat-divider></mat-divider>
                                    <mat-option  matTooltip="{{project.value}}" matTooltipClass="project-tooltip" *ngFor="let project of associatedProjectsListFiltered | async"
                                        [value]="project.key">
                                        {{project.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addAllocationForm.get('projectSelectedValue').hasError('required')">
                                    This
                                    is required field</mat-error>
                            </mat-form-field>
                        </p>
                    </div>
                    <div class="col-md-3" *ngIf="!bulkAllocationChecked">
                        <p [class.disabledInput]="addAllocationForm.get('projectSelectedValue').value === 'all'">
                            <mat-form-field>
                                <mat-select formControlName="allocationType" placeholder="Allocation Type"
                                    (selectionChange)="allocationTypeSelected($event.value)" required class="allocationDropPanel"
                                    #singleSelect>
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="allocationsOptionsSelectSearchFilterCtrl"
                                            placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-divider></mat-divider>
                                    <mat-option *ngIf="sow.associatedProjects && sow.associatedProjects.length"
                                        value="all">Select
                                        One</mat-option>
                                    <mat-divider></mat-divider>
                                    <ng-container *ngFor="let account of allocationsOptionsListFiltered | async">
                                        <mat-option *ngIf="account.value != 'Bench'"
                                        [value]="account.key">
                                        {{account.value}}
                                    </mat-option>
                                </ng-container>
                                </mat-select>
                                <mat-error *ngIf="addAllocationForm.get('allocationType').hasError('required')">This
                                    is required field</mat-error>
                            </mat-form-field>
                        </p>
                    </div>
                    <div class="col-md-3" *ngIf="!bulkAllocationChecked">
                        <p
                            [class.disabledInput]="addAllocationForm.get('allocationType').value === 'all' || addAllocationForm.get('projectSelectedValue').value === 'all'">
                            <mat-form-field>
                                <mat-select formControlName="resourceType" placeholder="Resource Type"
                                    (selectionChange)="resourceSelectedFunction($event, 0)" required class="allocationDropPanel"
                                    #singleSelect>
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="resourcesOptionsSelectSearchFilterCtrl"
                                            placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-divider></mat-divider>
                                    <mat-option *ngIf="sow.resourcesOptions && sow.resourcesOptions.length" value="all">
                                        Select
                                        One</mat-option>
                                    <mat-divider></mat-divider>
                                    <mat-option *ngFor="let resource of resourcesOptionsListFiltered | async"
                                        [value]="resource.wbsId">
                                        {{resource.resourceType}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addAllocationForm.get('resourceType').hasError('required')">This
                                    is required field</mat-error>
                            </mat-form-field>
                        </p>
                    </div>
                    <div class="col-md-3"
                        *ngIf="addAllocationForm.get('resourceType').value !== 'all' && !bulkAllocationChecked">
                        <p>Available : <span *ngIf="!sow.wbs.allotted"
                                [class.availableClsGreen]="!sow.wbs.allotted">Yes</span>
                            <span *ngIf="sow.wbs.allotted" [class.availableClsRed]="sow.wbs.allotted">No</span>
                        </p>
                        <p>Hourly Rate : <b>&nbsp;{{formatNumber(sow.wbs.hourlyRate.amount,sow.currency)}}
                            </b></p>
                    </div>
                </div>
                <div class="row"
                    *ngIf="addAllocationForm.get('resourceType').value !== 'all' && ( (sow.availableWBSesAllocationMetaData && sow.availableWBSesAllocationMetaData.length) || (sow.allottedWBSesAllocationMetaData && sow.allottedWBSesAllocationMetaData.length) || (sow.partialAvailableWBSesAllocationMetaData && sow.partialAvailableWBSesAllocationMetaData.length) ) && !bulkAllocationChecked">
                    <div class="col-md-3">
                        <p>Hours Summary : <b>{{sow.wbs.hoursSummary.remainingHours}}
                                ({{sow.wbs.hoursSummary.contractedHours}} - {{sow.wbs.hoursSummary.consumedHours}})</b>
                        </p>
                        <p>
                            <ngb-progressbar type="info" [value]="wbsHrsPercentage" mat-raised-button
                                matTooltip="Contracted Hours: {{sow.wbs.hoursSummary.contractedHours}}, Consumed Hours: {{sow.wbs.hoursSummary.consumedHours}}, Consumed Hours Percentage: {{wbsHrsPercentage}}%"
                                aria-label="Contracted Hours Info">
                                <!-- <i *ngIf="wbsHrsPercentage">({{wbsHrsPercentage}})%</i> -->
                            </ngb-progressbar>
                        </p>
                    </div>
                    <div class="col-md-3">
                        <p>Bill Summary : <b>{{formatNumber(sow.wbs.billsSummary.remainingBill.amount,sow.currency)}}
                                ({{formatNumber(sow.wbs.billsSummary.contractedBill.amount,sow.currency)}} -
                                {{formatNumber(sow.wbs.billsSummary.consumedBill.amount,sow.currency)}})</b>
                        </p>
                        <p>
                            <ngb-progressbar type="info" [value]="wbsBillsPercentage" mat-raised-button
                                matTooltip="Contracted Hours: {{sow.wbs.billsSummary.contractedBill.amount}}, Consumed Hours: {{sow.wbs.billsSummary.consumedBill.amount}}, Consumed Hours Percentage: {{wbsBillsPercentage}}%"
                                aria-label="Contracted Hours Info">
                                <!-- <i *ngIf="wbsBillsPercentage">({{wbsBillsPercentage}})%</i> -->
                            </ngb-progressbar>
                        </p>
                    </div>
                    <div class="col-md-6">
                        <p>Allocation Timeline : </p>
                        <div>
                            <span class="sow-sd">{{sow.wbs.allocationTimeline.timeline.startInstant | date:
                                DEFAULT_DATE_FORMAT}}</span>
                            <span class="sow-days">Duration : {{sow.wbs.allocationTimeline.timeline.durationDays}}
                                days</span>
                            <span class="sow-ed">{{sow.wbs.allocationTimeline.timeline.endInstant | date:
                                DEFAULT_DATE_FORMAT}}</span>
                            <p class="full-width" *ngIf="sow.wbs !== ''">
                                <span *ngFor="let slot of sow.wbs.allocationTimeline.timeSlots; let i = index;"
                                    [style.width.%]="slot.percent">
                                    <span *ngIf="slot.allocationId" (click)="openLg(content,slot.allocationId)"
                                        [ngClass]="{'filled-slot': slot.resource, 'empty-slot': !slot.resource}"
                                        style="width:100%">&nbsp;</span>
                                    <span *ngIf="!slot.allocationId"
                                        (click)="emptySlotClick(slot.startDate,slot.endDate)"
                                        [ngClass]="{'filled-slot': slot.resource, 'empty-slot': !slot.resource}"
                                        style="width:100%">&nbsp;</span>

                                    <div *ngIf="!slot.allocationId" class="grey-bg" [ngStyle]="{'left': slot.percent < 48 ? '48%' : 'auto'}">
                                        <p>Available for Allocation</p>
                                        <span>Start Date: {{slot.startDate | date: DEFAULT_DATE_FORMAT}}</span> <br />
                                        <span>End Date: {{slot.endDate | date: DEFAULT_DATE_FORMAT}}</span> <br />
                                        <span>Duration: {{slot.durationDays}} Days</span>
                                    </div>

                                    <div *ngIf="slot.allocationId" [ngStyle]="{'left': slot.percent < 48 ? '48%' : 'auto'}">
                                        <img class="example-option-img hover-rsc-img" *ngIf="slot.img;else defaultImage"
                                            aria-hidden src="{{slot.img}}" height="25">
                                        <ng-template #defaultImage>
                                            <img class="example-option-img hover-rsc-img" aria-hidden
                                                src="../../assets/User_Profile_Pic.png" height="25">
                                        </ng-template>
                                        <span>&nbsp;&nbsp;{{slot.resource.name}}&nbsp;-&nbsp;{{slot.resource.code}}</span><br />
                                        <span>Start Date: {{slot.startDate | date: DEFAULT_DATE_FORMAT}}</span> <br />
                                        <span>End Date: {{slot.endDate | date: DEFAULT_DATE_FORMAT}}</span> <br />
                                        <span>Duration: {{slot.durationDays}} Days</span>
                                    </div>
                                </span>
                                <span class="currentDate" mat-raised-button
                                    matTooltip="Context Date: {{sow.wbs.allocationTimeline.context.date | date: DEFAULT_DATE_FORMAT}}, Elapsed Days: {{sow.wbs.allocationTimeline.context.elapsedDurationDays}}"
                                    matTooltipClass="example-tooltip-red"
                                    *ngIf="sow.wbs.allocationTimeline.context.elapsedPercent < 100"
                                    [style.left.%]="-(100-sow.wbs.allocationTimeline.context.elapsedPercent)">|</span>
                                <span class="currentDate" mat-raised-button
                                    matTooltip="Context Date: {{sow.wbs.allocationTimeline.context.date | date: DEFAULT_DATE_FORMAT}}, Elapsed Days: {{sow.wbs.allocationTimeline.context.elapsedDurationDays}}"
                                    matTooltipClass="example-tooltip-red"
                                    *ngIf="sow.wbs.allocationTimeline.context.elapsedPercent >= 100"
                                    [style.left.%]="0">|</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row"
                    *ngIf="addAllocationForm.get('resourceType').value !== 'all' && ( (sow.availableWBSesAllocationMetaData && sow.availableWBSesAllocationMetaData.length) || (sow.allottedWBSesAllocationMetaData && sow.allottedWBSesAllocationMetaData.length) || (sow.partialAvailableWBSesAllocationMetaData && sow.partialAvailableWBSesAllocationMetaData.length) ) && (loadCur || loadPart) && !bulkAllocationChecked">
                    <div *ngIf="addAllocationForm.get('resourceType').value !== 'all' && ( (sow.availableWBSesAllocationMetaData && sow.availableWBSesAllocationMetaData.length) || (sow.allottedWBSesAllocationMetaData && sow.allottedWBSesAllocationMetaData.length) || (sow.partialAvailableWBSesAllocationMetaData && sow.partialAvailableWBSesAllocationMetaData.length) ) && (loadCur || loadPart)"
                        class="row heading-text">
                        <h2>Allocate Resource</h2>
                        <p class="error-message error-message-hr-allocation" *ngIf="isPendingAllocationError"><b>{{selectedResourceName}}</b> has already been allocated by HR. Please choose from Pending Allocation or Delete Allocation from HR-Allocation and proceed.  Click <span (click)="onClickAddHrAllocationDetails()" class="current-allocations"> Here </span> </p>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="full-width allocated-resource-search">
                                <mat-form-field class="example-full-width search-full-width">
                                    <span matPrefix>
                                        <mat-icon matSuffix>search</mat-icon>&nbsp;&nbsp;
                                    </span>
                                    <input matInput #resourceSearch formControlName="resourceValue"
                                        placeholder="Search Resource (eg. type resource id, resource name, email-id.)"
                                        aria-label="State" [matAutocomplete]="auto"
                                        (keyup)="searchResource($event.target.value)">
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                        (optionSelected)="onResourceSelection($event)">
                                        <mat-option matTooltip="{{resource.designation}}" class="resourceList"
                                            *ngFor="let resource of resourcesList" [value]="resource">
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <span>{{resource.code}} </span>
                                                </div>
                                                <div class="col-sm-7 res-name">
                                                    <span>{{resource.name}}</span>
                                                </div>

                                                <div class="col-sm-12">
                                                    <h6>{{resource.emailId}}</h6>
                                                </div>
                                            </div>
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="addAllocationForm.get('resourceValue').hasError('required')">
                                        Please select any resource from suggestion list
                                    </mat-error>
                                </mat-form-field>
                                <div class="allocation-cuurent-detail"
                                    *ngIf="resourceInfo && resourceInfo.name.length || isHRAllocation">
                                    <div class="current-allocations"
                                        (click)="search();openCurrentAllocations(currentAllocations)">See details
                                    </div>
                                </div>
                            </div>
                            <div class="onethird-width">
                                <p *ngIf="sow.clientTimeTracking">
                                    <span class="left-space-label">Select if applicable</span><br />
                                    <mat-checkbox formControlName="clientTS">Client Time
                                        Tracking</mat-checkbox>
                                </p>
                            </div>
                            <div class="onethird-width">
                                <p>
                                    <span class="left-space-label">Select if applicable</span><br />
                                    <mat-checkbox formControlName="flexibleWE">Flexible Week
                                        Ends</mat-checkbox>
                                </p>
                            </div>
                            <div class="onethird-width">
                                <p *ngIf="sow.clientTimeTracking">
                                    <span class="left-space-label">Select if applicable</span><br />
                                    <mat-checkbox formControlName="pseudoResource" (change)="PseudoSelected($event)">
                                        Pseudo Resource
                                    </mat-checkbox>
                                </p>
                            </div>
                            <div *ngIf="addAllocationForm.get('pseudoResource').value">
                                <div class="half-width">
                                    <p>
                                        <mat-form-field>
                                            <input matInput placeholder="Pseudo Resource ID" [(ngModel)]="clientId"
                                                formControlName="clientId" pattern="[a-zA-Z0-9\s]+" required>
                                            <mat-error *ngIf="addAllocationForm.get('clientId').hasError('pattern')">
                                                Only alpha numeric
                                                charaters are allowed</mat-error>
                                        </mat-form-field>
                                    </p>
                                </div>
                                <div class="half-width">
                                    <p>
                                        <mat-form-field class="example-full-width search-full-width">
                                            <span matPrefix>
                                                <mat-icon matSuffix>search</mat-icon>&nbsp;&nbsp;
                                            </span>
                                            <input matInput #resourceSearch formControlName="PseudoresourceValue"
                                                placeholder="Search Pseudo Resource *" aria-label="State"
                                                [matAutocomplete]="auto"
                                                (keyup)="searchPseudoResource($event.target.value)">
                                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                                (optionSelected)="onPseudoResourceSelection($event)">
                                                <mat-option class="resourceList" *ngFor="let resource of pseudoNameList"
                                                    [value]="resource">
                                                    <div class="row">
                                                        <div class="col-sm-2">&nbsp;
                                                            <img class="example-option-img"
                                                                *ngIf="resource.image;else defaultImage1" aria-hidden
                                                                src="{{resource.image}}" height="25">
                                                            <ng-template #defaultImage1>
                                                                <img class="example-option-img" aria-hidden
                                                                    src="../../assets/User_Profile_Pic.png" height="25">
                                                            </ng-template>
                                                        </div>
                                                        <div class="col-sm-3">
                                                            <span>{{resource.code}} </span>
                                                        </div>
                                                        <div class="col-sm-7">
                                                            <span>{{resource.name}}</span>
                                                        </div>
                                                        <div class="col-sm-12">
                                                            <span>{{resource.designation}}</span>
                                                        </div>
                                                        <div class="col-sm-12">
                                                            <h6>{{resource.emailId}}</h6>
                                                        </div>
                                                    </div>
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </p>
                                </div>
                            </div>
                            <div class="half-width">
                                <p>
                                    <mat-form-field class="example-full-width search-full-width">
                                        <input matInput aria-label="State" placeholder="Delegate Manager"
                                            [matAutocomplete]="deliveryManagerAuto"
                                            (keyup)="quickSearchDelegateResource($event.target.value)"
                                            formControlName="deliveryManager" [(ngModel)]="deliveryManagerName">
                                        <mat-autocomplete #deliveryManagerAuto="matAutocomplete"
                                            (optionSelected)="selectedManager($event, 0)">
                                            <mat-option matTooltip="{{resource.code}}" class="resourceList"
                                                *ngFor="let resource of delegateManagerList" [value]="resource">
                                                <div class="row">
                                                    <div class="col-sm-12 resource-list-option">
                                                        <span>{{resource.name}}</span>
                                                        <span class="resource-email">{{resource.emailId}}</span>
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="sameResourceSelected">Delegate Manager and resource for
                                            allocation cannot be same.</mat-error>
                                    </mat-form-field>
                                </p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="onethird-width">
                                <p>
                                    <mat-form-field class="sow-listing-full-width">
                                        <input formControlName="startDatePicker" [min]="minDate" [max]="maxDate"
                                            matInput [matDatepicker]="startDatepicker" placeholder="Start Date"
                                            readonly="true" required (dateChange)="startDateChange($event,undefined)">
                                        <mat-datepicker-toggle matSuffix [for]="startDatepicker">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker (selectedChanged)="checkDates($event,'startdp')"
                                        #startDatepicker></mat-datepicker>
                                    </mat-form-field>
                                </p>
                            </div>
                            <div class="onethird-width">
                                <p>
                                    <mat-form-field>
                                        <input formControlName="EndDatePicker" [min]="endMinDate" readonly="true"
                                            [max]="endMaxDate" matInput [matDatepicker]="endDatepicker"
                                            placeholder="End Date" required (dateChange)="endDateChange($event,undefined)">
                                        <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
                                        <mat-datepicker (selectedChanged)="checkDates($event,'enddp')" #endDatepicker>
                                        </mat-datepicker>
                                        <mat-error *ngIf="!isPendingAllocationError">SOW end date & HR-Allocation date mismatches.
                                            Please modify one of them.</mat-error>
                                    </mat-form-field>
                                </p>
                            </div>
                            <div class="onethird-width">
                                <p>
                                    <mat-form-field class="sow-listing-full-width">
                                        <input formControlName="occupancyValue" min="1" max="100" matInput
                                            (keypress)="numberOnly($event)" placeholder="Occupancy" required>
                                        <!-- <div *ngIf="OccupanyError">Occupancy cannot be 0 or more than 100</div> -->
                                        <mat-error *ngIf="addAllocationForm.get('occupancyValue').hasError('required')">
                                            This
                                            is required field</mat-error>
                                        <mat-error *ngIf="addAllocationForm.get('occupancyValue').hasError('min')">Occupancy cannot be 0%</mat-error>
                                        <mat-error *ngIf="addAllocationForm.get('occupancyValue').hasError('max')">Occupancy cannot be more than 100%
                                        </mat-error>
                                    </mat-form-field>
                                </p>
                            </div>
                            <div class="half-width">
                                <p>
                                    <mat-form-field>
                                        <input matInput placeholder="Client ID Of Resource"
                                            formControlName="customerClientID" [(ngModel)]="customerClientID"
                                            pattern="[a-zA-Z0-9\s]+">
                                        <mat-error *ngIf="addAllocationForm.get('customerClientID').hasError('pattern')">Only alpha numeric charaters are allowed</mat-error>
                                    </mat-form-field>
                                </p>
                            </div>
                            <div class="half-width">
                                <p>
                                    <mat-form-field>
                                        <input matInput placeholder="Name At Client Side"
                                            formControlName="customerClientName" [(ngModel)]="customerClientName"
                                            pattern="([A-Za-z0-9\-\_\ ]+)">
                                        <mat-error *ngIf="addAllocationForm.get('customerClientName').hasError('pattern')">Special charaters are not allowed</mat-error>
                                    </mat-form-field>
                                </p>
                            </div>
                            <div *ngIf="addAllocationForm.get('pseudoResource').value">
                                <div class="half-width">
                                    <p>
                                        <mat-form-field>
                                            <input formControlName="pseudoFromPicker" [min]="pseudoFromMinDate"
                                                [max]="pseudoFromMaxDate" matInput [matDatepicker]="pseudoFromPicker"
                                                placeholder="Pseudo From Date" readonly="true">
                                            <mat-datepicker-toggle matSuffix [for]="pseudoFromPicker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #pseudoFromPicker></mat-datepicker>
                                        </mat-form-field>
                                    </p>
                                </div>
                                <div class="half-width" style="margin-left: 1%;">
                                    <p>
                                        <mat-form-field>
                                            <input formControlName="pseudoToPicker" [min]="pseudoFromMinDate"
                                                readonly="true" [max]="pseudoFromMaxDate" matInput
                                                [matDatepicker]="pseudoToPicker" placeholder="Pseudo To Date">
                                            <mat-datepicker-toggle matSuffix [for]="pseudoToPicker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #pseudoToPicker></mat-datepicker>
                                        </mat-form-field>
                                    </p>
                                </div>
                            </div>
                            <div class="allocateBtn">
                                <button mat-raised-button color="primary"
                                    [disabled]="addAllocationForm.invalid || isSubmitClicked || isPendingAllocationError" (click)='onSubmit()'>
                                    <mat-icon>add</mat-icon> Allocate
                                </button>
                                <button id="allocateBtn-btn" mat-raised-button color="primary" (click)='resetAll()'>
                                    <mat-icon>refresh</mat-icon> Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #content let-modal>
                    <div class="modal-header">
                        <h4 class="modal-title">Allocation Details</h4>
                        <button type="button" class="close" aria-label="Close" (click)="closeLg()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Resource</th>
                                            <th scope="col">Project</th>
                                            <th scope="col">Occupancy</th>
                                            <th scope="col">Worked Hours</th>
                                            <th scope="col">Billing</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Client<br />Time<br />Tracking</th>
                                            <th scope="col">Flexible<br />Weekends</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>
                                                    
                                                    <span class="f-bold">{{allocation.resource.name}}</span>
                                                </div>
                                                <div>
                                                    <span>{{allocation.resource.code}}</span>
                                                </div>
                                                <div>
                                                    <img *ngIf="allocation.wbs.allocationType.key === 'BILLABLE'"
                                                        aria-hidden src="../../assets/billable.png" mat-raised-button
                                                        matTooltip="{{allocation.wbs.allocationType.value}}"
                                                        class="allocation_img"
                                                        alt="{{allocation.wbs.allocationType.key}}" />
                                                    <img *ngIf="allocation.wbs.allocationType.key === 'BUFFER'"
                                                        aria-hidden src="../../assets/buffer.png" mat-raised-button
                                                        matTooltip="{{allocation.wbs.allocationType.value}}"
                                                        class="allocation_img"
                                                        alt="{{allocation.wbs.allocationType.key}}" />
                                                    <img *ngIf="allocation.wbs.allocationType.key === 'NON_BILLABLE'"
                                                        aria-hidden src="../../assets/non_billable.png"
                                                        mat-raised-button
                                                        matTooltip="{{allocation.wbs.allocationType.value}}"
                                                        class="allocation_img"
                                                        alt="{{allocation.wbs.allocationType.key}}" />
                                                    <span>{{allocation.wbs.resourceType}}</span>
                                                </div>
                                            </td>
                                            <td>{{allocation.project.identifier.value}}</td>
                                            <td>{{allocation.occupancy}}%</td>
                                            <td>{{allocation.workedHours}}</td>

                                            <td>{{formatNumber(allocation.billing.amount,allocation.billing.amount)}}</td>
                                            <td>
                                                <div class="status_{{ allocation.status.value |lowercase }}">{{
                                                    allocation.status.value === "In-Active" ? "Inactive" :
                                                    allocation.status.value }}</div>
                                                <div *ngIf="allocation.startDate; else empty">
                                                    {{allocation.startDate| date: DEFAULT_DATE_FORMAT}}</div>
                                                <div *ngIf="allocation.endDate; else empty">
                                                    {{allocation.endDate| date: DEFAULT_DATE_FORMAT}}
                                                </div>
                                                <ng-template #empty>
                                                    <div>-</div>
                                                </ng-template>
                                            </td>
                                            <td>{{allocation.clientTimeTrackingEnabled ? 'Yes' : 'No' }}</td>
                                            <td>{{allocation.flexibleWeekends ? 'Yes' : 'No' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" (click)="closeLg()">Close</button>
                    </div>
                </ng-template>

                <ng-template #currentAllocations let-modal>
                    <div class="modal-header">
                        <h4 class="modal-title">Current Allocation Details</h4>
                        <button type="button" class="close close-details-btn" aria-label="Close" (click)="closeCurrentAllocations()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-2">
                                <p>
                                    <img class="example-option-img rsc-img-big"
                                        *ngIf="resImageData && resImageData !== '';else defaultImage1" aria-hidden
                                        src="{{resImageData}}">
                                    <ng-template #defaultImage1>
                                        <img class="example-option-img rsc-img-big" aria-hidden
                                            src="../../assets/User_Profile_Pic.png" height="25">
                                    </ng-template>
                                </p>
                            </div>
                            <div class="col-md-10">
                                <div class="row">
                                    <div class="col-md-3">
                                        <span class="left-space-label">Name</span><br />
                                        <span class="left-space-text"><b>{{resourceInfo.name}}</b></span><br />
                                    </div>
                                    <div class="col-md-3">
                                        <span class="left-space-label">Code</span><br />
                                        <span class="left-space-text"><b>{{resourceInfo.code}}</b></span><br /><br />
                                    </div>
                                    <div class="col-md-3">
                                        <span class="left-space-label">Email</span><br />
                                        <span class="left-space-text"><b>{{resourceInfo.emailId}}</b></span>
                                    </div>
                                    <div class="col-md-3" *ngIf="resourceInfo.company.length">
                                        <span class="left-space-label">Company</span><br />
                                        <span class="left-space-text"><b>{{resourceInfo.company}}</b></span>
                                    </div>
                                    <div class="col-md-3" *ngIf="resourceInfo.designation">
                                        <span class="left-space-label">Designation</span><br />
                                        <span class="left-space-text"><b>{{resourceInfo.designation}}</b></span>
                                    </div>
                                    <div class="col-md-3" *ngIf="resourceInfo.department">
                                        <span class="left-space-label">Department</span><br />
                                        <span class="left-space-text"><b>{{resourceInfo.department}}</b></span>
                                    </div>
                                    <div class="col-md-3" *ngIf="resourceInfo.department">
                                        <span class="left-space-label">Joining Date</span><br />
                                        <span class="left-space-text"><b>{{resourceInfo.actualJoiningDate | date:
                                                DEFAULT_DATE_FORMAT}}</b></span>
                                    </div>
                                    <div class="col-md-3">
                                        <p>
                                            <span class="left-space-label">Employment Status / Type</span><br />
                                            <b class="emp-status status-{{resourceInfo.employmentStatusKey}}">{{resourceInfo.employmentStatus
                                                === "In-Active" ? "Inactive" : resourceInfo.employmentStatus }}</b>
                                            <img *ngIf="resourceInfo.employmentType.key === 'FTE'" aria-hidden
                                                src="../../assets/fte.png" mat-raised-button
                                                matTooltip="{{resourceInfo.employmentType.value}}"
                                                class="allocation_img" alt="{{resourceInfo.employmentType.key}}" />
                                            <img *ngIf="resourceInfo.employmentType.key === 'SC'" aria-hidden
                                                src="../../assets/sc.png" mat-raised-button
                                                matTooltip="{{resourceInfo.employmentType.value}}"
                                                class="allocation_img" alt="{{resourceInfo.employmentType.key}}" />
                                            <img *ngIf="resourceInfo.employmentType.key === 'DC'" aria-hidden
                                                src="../../assets/dc.png" mat-raised-button
                                                matTooltip="{{resourceInfo.employmentType.value}}"
                                                class="allocation_img" alt="{{resourceInfo.employmentType.key}}" />
                                        </p>
                                    </div>
                                    <div class="col-md-3" *ngIf="resourceInfo.department">
                                        <span class="left-space-label">Utilization</span><br />
                                        <span class="left-space-text"><b>{{resourceInfo.utilization}}%&nbsp;</b></span>
                                        <p>
                                            <img *ngIf="resourceInfo.utilizationType.key === 'OVER_UTILIZED'"
                                                aria-hidden src="../../assets/over_utilized.png" mat-raised-button
                                                matTooltip="{{resourceInfo.utilizationType.value}}"
                                                class="allocation_img" alt="{{resourceInfo.utilizationType.key}}" />
                                            <img *ngIf="resourceInfo.utilizationType.key === 'OPTIMALLY_UTILIZED'"
                                                aria-hidden src="../../assets/optimal_utilized.png" mat-raised-button
                                                matTooltip="{{resourceInfo.utilizationType.value}}"
                                                class="allocation_img" alt="{{resourceInfo.utilizationType.key}}" />
                                            <img *ngIf="resourceInfo.utilizationType.key === 'UNDER_UTILIZED'"
                                                aria-hidden src="../../assets/under_utilized.png" mat-raised-button
                                                matTooltip="{{resourceInfo.utilizationType.value}}"
                                                class="allocation_img" alt="{{resourceInfo.utilizationType.key}}" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="practices-division" *ngIf="resourceInfo && resourceInfo.name.length">Practices :
                            <b>{{resourceInfo.practices}}</b>
                        </p>
                        <p *ngIf="resourceInfo && resourceInfo.name.length">Technologies :
                            <b>{{resourceInfo.technologies}}</b>
                        </p>
                        <div class="row" *ngIf="searchTableData && searchTableData.length">
                            <div class="col-md-12" style="overflow-x: auto">
                                <!-- <div *ngIf="resourceSelected === searchMDCode"> -->
                                <table class="table">
                                    <thead>
                                        <tr *ngIf="!columnOrder">
                                            <!-- <th scope="col">Resource</th> -->
                                            <th scope="col">Client</th>
                                            <th scope="col">Project</th>
                                            <th scope="col">SOW Name</th>
                                            <th scope="col">Occupancy</th>
                                            <!-- <th scope="col">Worked Hours</th> -->
                                            <!-- <th scope="col">Billing</th> -->
                                            <th scope="col">Status</th>
                                            <!-- <th scope="col">Client<br />Time<br />Tracking</th> -->
                                            <!-- <th scope="col">Flexible<br />Weekends</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let allocation of searchTableData; let i = index;">
                                            <!-- <td>{{allocation.resource.name}}</td> -->
                                            <!-- <td>{{resourceInfo.name}}<br />
                                                {{alloc.wbsIdentity.resourceType}}</td> -->
                                            <td class="accountColumn">
                                                <span>{{allocation.account.name}}</span>
                                                <!-- <span *ngIf="!checkduplicates(i)"></span> -->
                                            </td>
                                            <td class="projectColumn">
                                                <span>{{allocation.project.identifier.value}}</span>
                                            </td>
                                            <td>
                                                <div>{{allocation.wbs.sow.value}}</div>
                                                <div>{{allocation.wbs.resourceType}}</div>
                                                <div>
                                                    <img *ngIf="allocation.wbs.allocationType.key === 'BILLABLE'"
                                                        aria-hidden src="../../assets/billable.png" mat-raised-button
                                                        matTooltip="{{allocation.wbs.allocationType.value}}"
                                                        class="allocation_img"
                                                        alt="{{allocation.wbs.allocationType.key}}" />
                                                    <img *ngIf="allocation.wbs.allocationType.key === 'BUFFER'"
                                                        aria-hidden src="../../assets/buffer.png" mat-raised-button
                                                        matTooltip="{{allocation.wbs.allocationType.value}}"
                                                        class="allocation_img"
                                                        alt="{{allocation.wbs.allocationType.key}}" />
                                                    <img *ngIf="allocation.wbs.allocationType.key === 'NON_BILLABLE'"
                                                        aria-hidden src="../../assets/non_billable.png"
                                                        mat-raised-button
                                                        matTooltip="{{allocation.wbs.allocationType.value}}"
                                                        class="allocation_img"
                                                        alt="{{allocation.wbs.allocationType.key}}" />
                                                </div>
                                            </td>
                                            <td>{{allocation.occupancy}}%</td>
                                            <td>
                                                <div *ngIf="allocation.status.value !== 'In-Active'"
                                                    class="status_{{ allocation.status.value |lowercase }}">
                                                    {{allocation.status.value }}</div>
                                                <div *ngIf="allocation.status.value === 'In-Active'"
                                                    class="status_{{ allocation.status.value |lowercase }}">
                                                    Inactive</div>
                                                <div *ngIf="allocation.startDate; else empty">
                                                    {{allocation.startDate|date: DEFAULT_DATE_FORMAT}}</div>
                                                <div *ngIf="allocation.endDate; else empty">
                                                    {{allocation.endDate| date: DEFAULT_DATE_FORMAT}}</div>
                                                <ng-template #empty>
                                                    <div>-</div>
                                                </ng-template>
                                            </td>

                                            <!-- <td>{{alloc.occupancy}}%</td> -->
                                            <!-- <td>{{alloc.workedHours}}</td> -->
                                            <!-- <td>{{alloc.billing.amount | currency:alloc.billing.currency}}</td> -->
                                            <!-- <td>
                                                <div class="status_{{ alloc.status.value |lowercase }}">{{
                            alloc.status.value === "In-Active" ? "Inactive" : alloc.status.value }}</div>

                                                <div *ngIf="alloc.startDate; else empty_dash">
                                                    {{alloc.startDate| date: DEFAULT_DATE_FORMAT}}
                                                </div>
                                                <div *ngIf="alloc.endDate; else empty_dash">
                                                    {{alloc.endDate| date: DEFAULT_DATE_FORMAT}}</div>
                                                <ng-template #empty_dash>
                                                    <div>-</div>
                                                </ng-template> -->
                                            <!--
                          <div>{{alloc.startDate ? alloc.startDate : '-'}}</div>
                          <div>{{alloc.endDate ? alloc.endDate : '-' }}</div> -->
                                            <!-- </td> -->
                                            <!-- <td>{{alloc.clientTimeTracking ? 'Yes' : 'No' }}</td>
                                            <td>{{alloc.flexibleWeekends ? 'Yes' : 'No' }}</td> -->
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- </div> -->
                            </div>
                            <div class="col-md-12 pagination-left" *ngIf="totalItems">
                                <div class="pagination-controls">
                                    <span class="marigin-right">
                                        Items per page
                                        <select (change)="goToPage($event.target.value)" class="selectField">
                                           <option value="5"  [selected]="itemsPerPage==5">5</option>
                                           <option value="10" [selected]="itemsPerPage==10">10</option> 
                                           <option value="15" [selected]="itemsPerPage==15">15</option> 
                                           <option value="25" [selected]="itemsPerPage==25">25</option> 
                                           <option value="50" [selected]="itemsPerPage==50">50</option> 
                                      </select>
                                     </span> 
                                     <span class="marigin-right" >
                                          {{ currentPage === 0 ? 1: currentPage * itemsPerPage + 1 }} - {{  ((currentPage * itemsPerPage) + itemsPerPage) >= totalItems ? totalItems :  (currentPage * itemsPerPage) + itemsPerPage }}  of &nbsp; {{ totalItems }}
                                    </span>
                                    <button   (click)="goToFirstPage()" [disabled]="currentPage === 0" mat-icon-button="" type="button" class="mat-paginator-navigation-first"><span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path></svg></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
                                    <button (click)="goToPreviousPage()" [disabled]="currentPage === 0" mat-icon-button="" type="button" class=" mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-previous mat-icon-button mat-button-base" ng-reflect-message="Previous page" ng-reflect-disabled="false" ng-reflect-position="above" aria-label="Previous page"><span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
                                    <button  (click)="goToNextPage()" [disabled]="currentPage === totalPages-1" mat-icon-button="" type="button" class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-next mat-icon-button mat-button-base" ng-reflect-message="Next page" ng-reflect-disabled="false" ng-reflect-position="above" aria-label="Next page"><span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
                                    <button   (click)="goToLastPage()" [disabled]="currentPage === totalPages-1" mat-icon-button="" type="button" class="mat-paginator-navigation-first"><span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon"><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path></svg></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
                                </div>
                             </div> 
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" (click)="closeCurrentAllocations()">Close</button>
                    </div>
                </ng-template>
            </form>
        </mat-card-content>
    </mat-card>

    <!-- bulk update -->
    <div class="row bulk-allocation-row" *ngIf="bulkAllocationChecked">
        <div class="col-lg-6 col-sm-12 col-md-6">
            <h2>Bulk Allocation</h2>
        </div>
    </div>
    <mat-card *ngIf="bulkAllocationChecked">
        <mat-card-content>
            <p class="error-message error-message-hr-allocation-bulk-allocation" *ngIf="isPendingAllocationBulkError && resultLength == 1">
                <b>{{selectedResourceNameBulkAllocation}}</b> has already been allocated by HR. Please choose from Pending Allocation or Delete Allocation from HR-Allocation and proceed.
            </p>
            <p class="error-message error-message-hr-allocation-bulk-allocation" *ngIf="isPendingAllocationBulkError && resultLength > 1">
                The following resources have already been allocated by HR. Please choose from Pending Allocation or Delete Allocation from HR-Allocation and proceed : <b class="projectName-actorInfo">See Here</b> 
                <span class="project-actor-info">
                    <ul class="mb-0" *ngFor="let names of listOfPBAllocationNames"><li><b>{{names.resourceName}}</b></li></ul>
                </span>
            </p>
            <form class="sow-listing-form" [formGroup]="addbulkForm" novalidate autocomplete="off">
                <div formArrayName="bulkAllocationForm">
                    <div *ngFor="let bulkGroup of bulkAllocationForm.controls; let index = index;let last =last;"
                        [formGroupName]="index">
                        <div class="row">
                            <div class="col-6">
                                <div class="full-width allocated-resource-search">
                                    <mat-form-field class="example-full-width search-full-width">
                                        <span matPrefix>
                                            <mat-icon matSuffix>search</mat-icon>&nbsp;&nbsp;
                                        </span>
                                        <input matInput #resourceSearch formControlName="resourceName"
                                            placeholder="Search Resource (eg. type resource id, resource name, email-id.)"
                                            aria-label="State" [matAutocomplete]="autoBulk"
                                            (keyup)="searchResource($event.target.value)">
                                        <mat-autocomplete autoActiveFirstOption #autoBulk="matAutocomplete"
                                            (optionSelected)="onResourceSelection($event,index)">
                                            <mat-option class="resourceList" *ngFor="let resource of resourcesList"
                                                [value]="resource">
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <span>{{resource.code}} </span>
                                                    </div>
                                                    <div class="col-sm-7 res-name">
                                                        <span>{{resource.name}}</span>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <h6>{{resource.emailId}}</h6>
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error
                                            *ngIf="addbulkForm.get('bulkAllocationForm')['controls'][index].get('resourceName').hasError('required')">
                                            This is a required field.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-6" style="position: relative;right:10px">
                                <p>
                                    <mat-checkbox class="bulk-alloc-flex-we" formControlName="flexibleWeekendsEnabled">
                                        Flexible
                                        Week
                                        Ends</mat-checkbox>
                                </p>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="addAllocationForm.get('sowselectedOption').value !== 'all' && ((sow.availableWBSesAllocationMetaData && sow.availableWBSesAllocationMetaData.length))">
                            <div class="col-lg-4 col-sm-12 col-md-6">
                                <p>
                                    <mat-form-field>
                                        <mat-select formControlName="projectSelectedValue" placeholder="Project"
                                            matTooltip= "{{bulkselectedProject === 'all' ? '' : bulkselectedProject}}"
                                            matTooltipClass="project-tooltip"
                                           
                                            (selectionChange)="bulkProjectSelected($event,index)" required
                                            class="allocationDropPanel" #singleSelect>
                                            <mat-option>
                                                <ngx-mat-select-search
                                                    [formControl]="associatedProjectsSelectSearchFilterCtrl"
                                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-divider></mat-divider>
                                            <mat-option *ngIf="sow.associatedProjects && sow.associatedProjects.length"
                                                value="all">Select
                                                One</mat-option>
                                            <mat-divider></mat-divider>
                                            <mat-option matTooltip= "{{project.value}}"
                                            matTooltipClass="project-tooltip" *ngFor="let project of associatedProjectsListFiltered | async"
                                                [value]="project.key">
                                                {{project.value}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="addbulkForm.get('bulkAllocationForm')['controls'][index].get('projectSelectedValue').hasError('required')">
                                            This is a required field.
                                        </mat-error>
                                    </mat-form-field>
                                </p>
                            </div>
                            <div class="col-lg-4 col-sm-12 col-md-6">
                                <p
                                    [class.disabledInput]="addbulkForm.get('bulkAllocationForm')['controls'][index].get('projectSelectedValue').value === 'all'">
                                    <mat-form-field>
                                        <mat-select formControlName="allocationTypebulk" placeholder="Allocation Type"
                                            required class="allocationDropPanel"
                                            (selectionChange)="bulkAllocationSelected(index)" #singleSelect>
                                            <mat-option>
                                                <ngx-mat-select-search
                                                    [formControl]="allocationsOptionsSelectSearchFilterCtrl"
                                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngIf="sow.associatedProjects && sow.associatedProjects.length"
                                                value="all">Select One</mat-option>
                                            <mat-divider></mat-divider>
                                            <ng-container *ngFor="let account of allocationsOptionsListFiltered | async">
                                                <mat-option *ngIf="account.value != 'Bench'"
                                                [value]="account.key">
                                                {{account.value}}
                                            </mat-option>
                                        </ng-container>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="addbulkForm.get('bulkAllocationForm')['controls'][index].get('allocationTypebulk').hasError('required')">
                                            This is a required field.
                                        </mat-error>
                                    </mat-form-field>
                                </p>
                            </div>
                            <div class="col-lg-4 col-sm-12 col-md-6">
                                <p
                                    [class.disabledInput]="addbulkForm.get('bulkAllocationForm')['controls'][index].get('allocationTypebulk').value === 'all' || addbulkForm.get('bulkAllocationForm')['controls'][index].get('projectSelectedValue').value === 'all'">
                                    <mat-form-field>
                                        <mat-select formControlName="wbsId" placeholder="Resource Type"
                                            (selectionChange)="resourceSelectedFunction($event, index)" required
                                            class="allocationDropPanel" #singleSelect>
                                            <mat-option>
                                                <ngx-mat-select-search
                                                    [formControl]="resourcesOptionsSelectSearchFilterCtrl"
                                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-divider></mat-divider>
                                            <mat-option *ngIf="sow.resourcesOptions && sow.resourcesOptions.length"
                                                value="all">
                                                Select
                                                One</mat-option>
                                            <mat-divider></mat-divider>
                                            <span
                                                *ngIf="addbulkForm.get('bulkAllocationForm')['controls'][index].get('allocationTypebulk').value == 'BENCH'">
                                                <mat-option *ngFor="let resource of benchResourceTypeList"
                                                    [value]="resource.wbsId">
                                                    {{resource.resourceType}}
                                                </mat-option>
                                            </span>
                                            <span
                                                *ngIf="addbulkForm.get('bulkAllocationForm')['controls'][index].get('allocationTypebulk').value == 'BILLABLE'">
                                                <mat-option *ngFor="let resource of BillresourceTypeList"
                                                    [value]="resource.wbsId">
                                                    {{resource.resourceType}}
                                                </mat-option>
                                            </span>
                                            <span
                                                *ngIf="addbulkForm.get('bulkAllocationForm')['controls'][index].get('allocationTypebulk').value == 'NON_BILLABLE'">
                                                <mat-option *ngFor="let resource of resourceTypeList"
                                                    [value]="resource.wbsId">
                                                    {{resource.resourceType}}
                                                </mat-option>
                                            </span>
                                            <span
                                                *ngIf="addbulkForm.get('bulkAllocationForm')['controls'][index].get('allocationTypebulk').value == 'BUFFER'">
                                                <mat-option *ngFor="let resource of bufferResourceTypeList"
                                                    [value]="resource.wbsId">
                                                    {{resource.resourceType}}
                                                </mat-option>
                                            </span>

                                        </mat-select>
                                        <mat-error
                                            *ngIf="addbulkForm.get('bulkAllocationForm')['controls'][index].get('wbsId').hasError('required')">
                                            This
                                            is required field</mat-error>
                                    </mat-form-field>
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-sm-12 col-md-6">
                                <p>
                                    <mat-form-field class="sow-listing-full-width">
                                        <input formControlName="occupancy" min="1" max="100" matInput
                                            placeholder="Occupancy" required>
                                        <mat-error
                                            *ngIf="addbulkForm.get('bulkAllocationForm')['controls'][index].get('occupancy').hasError('required')">
                                            This is a required field.
                                        </mat-error>
                                    </mat-form-field>
                                </p>
                            </div>
                            <div class="col-lg-4 col-sm-12 col-md-6">
                                <p>
                                    <mat-form-field class="example-full-width search-full-width">
                                        <input matInput aria-label="State" placeholder="Delegate Manager"
                                            [matAutocomplete]="deliveryManagerAuto"
                                            (keyup)="quickSearchDelegateResource($event.target.value)"
                                            formControlName="delegateManager">
                                        <mat-autocomplete #deliveryManagerAuto="matAutocomplete"
                                            (optionSelected)="selectedManager($event,index)">
                                            <mat-option matTooltip="{{resource.code}}" class="resourceList"
                                                *ngFor="let resource of delegateManagerList" [value]="resource">
                                                <div class="row">
                                                    <div class="col-sm-12 resource-list-option">
                                                        <span>{{resource.name}}</span>
                                                        <span class="resource-email">{{resource.emailId}}</span>
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="sameResourceSelected">Delegate Manager and resource for
                                            allocation cannot be same.</mat-error>
                                    </mat-form-field>
                                </p>
                            </div>
                            <div class="col-lg-2 col-sm-12 col-md-6" *ngIf="addbulkForm.get('bulkAllocationForm')['controls'][index].get('hourlyRate').value">
                                <p>Hourly Rate : <b>{{formatNumber(addbulkForm.get('bulkAllocationForm')['controls'][index].get('hourlyRate').value.amount, addbulkForm.get('bulkAllocationForm')['controls'][index].get('hourlyRate').value.currency)}}
                                </b></p>
                            </div>
                            <div class="col-lg-2 col-sm-12 col-md-6 bulk-new-row-btn">
                                <button class="add-row-btn" id="{{'add'+index}}" mat-raised-button color="primary"
                                    (click)='addbulk(index)' *ngIf="index !==(sow.avilableWbsCount - 1)">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <button *ngIf="index>0" color="primary" id="{{'delete'+index}}" class="remove-row-icon"
                                    (click)="removeBulkRow(index)" mat-raised-button>
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h5>Allocation Dates</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-sm-12 col-md-6">
                        <p>
                            <mat-form-field class="sow-listing-full-width">
                                <input formControlName="fromDate" [min]="minDate" [max]="maxDate" matInput
                                    [matDatepicker]="startDatePickerbulk" placeholder="Start Date" readonly="true"
                                    required (dateChange)="bulkAllocationStartDateChange($event)">
                                <mat-datepicker-toggle matSuffix [for]="startDatePickerbulk">
                                </mat-datepicker-toggle>
                                <mat-datepicker (selectedChanged)="checkDates($event,'startdp')" #startDatePickerbulk>
                                </mat-datepicker>
                                <mat-error *ngIf="addbulkForm.get('fromDate').hasError('required')">
                                    This is a required field.
                                </mat-error>
                            </mat-form-field>
                        </p>
                    </div>
                    <div class="col-lg-4 col-sm-12 col-md-6">
                        <p>
                            <mat-form-field>
                                <input formControlName="tillDate" [min]="endMinDate" readonly="true" [max]="endMaxDate"
                                    matInput [matDatepicker]="EndDatePickerbulk" placeholder="End Date" required (dateChange)="bulkAllocationEndDateChange($event)">
                                <mat-datepicker-toggle matSuffix [for]="EndDatePickerbulk">
                                </mat-datepicker-toggle>
                                <mat-datepicker (selectedChanged)="checkDates($event,'enddp')" #EndDatePickerbulk>
                                </mat-datepicker>
                                <mat-error *ngIf="addbulkForm.get('tillDate').hasError('required')">
                                    This is a required field.
                                </mat-error>
                            </mat-form-field>
                        </p>
                    </div>
                </div>
                <div class="col-12 allocateBtn">
                    <button mat-raised-button color="primary" [disabled]="addbulkForm.invalid || isPendingAllocationBulkError"
                        (click)='addBulkAllocation()'>
                        <mat-icon>add</mat-icon> Allocate
                    </button>
                    <button id="allocateBtn-btn" mat-raised-button color="primary" (click)='resetAll()'>
                        <mat-icon>refresh</mat-icon> Reset
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
<div class="dark-overlay" *ngIf="load">
    <mat-spinner class="loader" strokeWidth="3" [diameter]="50"></mat-spinner>
</div>