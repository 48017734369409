export const enviornmentAperture = {
  production: false,
  dev: false,
  qa: false,
  local: true,
  pst:false,
  apiUrl: 'https://uat-aperture-api.gspann.com/',
  keycloakURL: 'https://uat-auth-aperture.gspann.com',
  pbURL: 'https://uat-pb-aperture.gspann.com/dashboard'
};

export const enviornment = {
  production: false,
  dev: false,
  qa: true,
  local: false,
  pst:false,
  apiUrl: 'https://uat-referral-api-aperture.gspann.com/referral'
};

export const environmentLMS = {
  production: false,
    dev: false,
    qa: true,
    local: false,
    pst:false,
    apiUrl: 'https://uat-lms-api-aperture.gspann.com/aperture-leave',
};

export const calculatorEndpoint = {
  production: false,
  dev: false,
  qa: true,
  local: false,
  pst:false,
  apiUrl: 'https://uat-calculator-api-aperture.gspann.com/aperture-calculator'
}

export const LMS_ADMIN = {
adminName: "ALMS Admin Test",
adminResourceCode: "IN10018"
}