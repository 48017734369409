import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Itrack2AppModule } from './app/app.module';
import { enviornment } from './environments/environment';

if (enviornment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(Itrack2AppModule)
  .catch(err => console.error(err));
