import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonDataService } from 'app/common-data.service';
import { enviornmentAperture } from 'environments/environment';

@Injectable()
export class ListAccountsService {
  constructor(private http: HttpClient, private commonService: CommonDataService) {}

  getMetrics(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'actuator/metrics');
  }

  getAccountList(params?): Observable<any> {
    if (params && params.length > 0) {
      let construct_uri = '';
      params.forEach((element, i) => {
        let specialChar;
        i === 0 ? (specialChar = '?') : (specialChar = '&');
        construct_uri = construct_uri + specialChar + element.key + '=' + element.value;
      });
      return this.http.get(enviornmentAperture.apiUrl + 'api/v1/accounts' + construct_uri, {});
    } else {
      return this.http.get(enviornmentAperture.apiUrl + 'api/v1/accounts');
    }
  }

  threadDump(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'management/threaddump');
  }

  getInitListAccountData(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/accounts/initAccountMetaData');
  }

  exportAccountListing(accountManager,arOwner, customerEntity, customerName, timeTracking): Observable<any> {
    let construct_uri = '';
    //  construct_uri = 'resourceCode=' + managerCode;
    return this.http.get(enviornmentAperture.apiUrl + `api/v1/accounts/export?accountManager=${this.commonService.encrypt(accountManager)}&arOwner=${this.commonService.encrypt(arOwner)}&customerEntity=${customerEntity}&customerName=${customerName}&timeTrackingSystem=${timeTracking}`, { responseType: 'blob' });
  }

  searchAccounts(accountManager,arOwner, customerEntity, customerName, timeTracking,pageNumber=0,pageSize=5): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + `api/v1/accounts/seachAccounts?accountManager=${accountManager}&arOwner=${this.commonService.encrypt(arOwner)}&customerEntity=${customerEntity}&customerName=${customerName}&timeTrackingSystem=${timeTracking}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }
}
