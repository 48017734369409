<div class="row">
  <div class="col-10">
      <h2 mat-dialog-title class="pl-3 mt-2 ml-0 mb-0 mr-0">Remove Account</h2>
  </div>
  <div class="col-2">
      <button mat-icon-button tabindex="-1" [mat-dialog-close]="true" (click)="onNoClick()">
      <mat-icon matTooltip="Close">close</mat-icon>
  </button>
  </div>
</div>

<div class="modal-body view-mode">
  Are you sure you want to remove this account? This 
    Action can not be undone.
</div>
<div class="modal-footer">
  <button mat-raised-button aria-label="Close" (click)="onNoClick()"
  class="btn btn-light mr-3">Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">Remove</button>
</div>