import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { enviornmentAperture } from 'environments/environment';
import { map } from 'rxjs/operators';
import { CommonDataService } from 'app/common-data.service';

@Injectable()
export class ProjectService {
  constructor(private http: HttpClient, private CommonService: CommonDataService) {}

  initAddProject(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/projects/initAddProject');
  }

  getProjectsList(params?): Observable<any> {
    if (params && params.length > 0) {
      let construct_uri = '';
      params.forEach((element, i) => {
        let specialChar;
        i === 0 ? (specialChar = '?') : (specialChar = '&');
        construct_uri = construct_uri + specialChar + element.key + '=' + element.value;
      });
      return this.http.get(enviornmentAperture.apiUrl + 'api/v1/projects' + construct_uri, {});
    } else {
      return this.http.get(enviornmentAperture.apiUrl + 'api/v1/projects');
    }
  }

  saveProjectDetails(data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/projects/createProject', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  updateProjectDetails(data): Observable<any> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/projects/updateProject', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  getProjectDetails(projectId): Observable<any> {
    const projectCodeDTO = {
      projectCode: projectId
    }
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/projects/getProjectById', projectCodeDTO, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  searchResource(searchValue): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/searchActiveResources?searchParam=' + searchValue);
  }

  quickSearchOnlyActiveResource(searchValue): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/quickSearchActive?searchParam=' + searchValue);
  }
  
  quickSearchResource(searchValue): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/quickSearch?searchParam=' + searchValue);
  }

  getCurrentAllocation(resourceCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/getCurrentAllocation/' + resourceCode);
  }

  allocateResource(data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/allocateResource', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  checkCustomerTracking(projectCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/checkCustomerTracking/' + projectCode);
  }

  deallocateResource(deallocationData) {
    return this.http.get(enviornmentAperture.apiUrl + 'api/deallocateFully/' + deallocationData.projectCode + '/' + deallocationData.resourceCode);
  }

  getCurrentAvailabilty(resourceCode) {
    return this.http.get(enviornmentAperture.apiUrl + 'api/getCurrentAvailabilty/' + resourceCode);
  }
  getCurrentOccupancy(resourceCode) {
    return this.http.get(enviornmentAperture.apiUrl + 'api/getCurrentOccupancy/' + resourceCode);
  }

  getCurrentProjectAllocation(projectCode) {
    return this.http.get(enviornmentAperture.apiUrl + 'api/getCurrentProjectAllocation/' + projectCode);
  }

  getResourceDetails(resourceCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/getResourceById/' + resourceCode);
  }

  getProjectHistory(projectCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/audit/project/' + projectCode);
  }

  getMethodRequest(uri): Observable<any> {
    return this.http.get(uri, { responseType: 'text' as 'json' }).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  exportProjectListing(managerCode): Observable<any> {
    let construct_uri = '';
      construct_uri = 'resourceCode=' + this.CommonService.encrypt(managerCode);
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/projects/export?' + construct_uri, { responseType: 'blob' });
  }

  addDelegateManager(data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/project/delegate', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  getDelegateManager(projectCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/project/delegate/getDelegateManagers/' + projectCode);
  }

  updateDelegateManager(data): Observable<any> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/project/delegate/editDelegateManagers', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  
  deleteDelegateManager(managerCode, projectCode): Observable<any> {
    return this.http.delete(enviornmentAperture.apiUrl + 'api/v1/project/delegate/deleteDelegateManager/' + this.CommonService.encrypt(managerCode) + '/' + projectCode, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
}
